import { store } from "../index";
import Course from "../models/course";
import CustomerRelative from "../models/customer/relative";
import Customer from "../models/customer";
import User from "../models/user";
import Center from "../models/center";

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

/**
 * Get the last id in object collection
 *
 * Search for ther greather value in the `id` property of all objects
 *
 * @param {object[]} items The collection of objects. Each one should have a property `id`
 *
 * @returns {boolean}
 */
export function lastId(items, field = 'id') {
  return items.reduce(
    (currId, item) => (parseInt(item[field]) > currId ? item[field] : currId),
    0
  );
}

/**
 * Check if the user has the requested permission.
 *
 * If no user is specified, try to load it from Redux Store
 *
 * If the requested capability has a modifier (es. mytarget__view--mymod) check
 * also for the root capability (es. mytarget__view)
 *
 * @param {string} capability
 * @param {object?} user
 * @param {string[]} user.permissions
 *
 * @returns {boolean}
 */
export function userCan(capability) {
  const reduxState = store.getState();
  let user = reduxState.auth.user;

  if (!user || !user.permissions) {
    console.warn("No user o no permissions available", user);
    return false;
  }

  const rootTarget = capability.split("--", 1).pop();

  return (
    user.permissions.includes(rootTarget) ||
    user.permissions.includes(capability)
  );
}

/**
 * Replace the passed placeholders in the provided strings and merge togheter
 *
 * Usage:
 * let template = templateString`Hello ${'name'} ${'surname'}`;
 * let result = template({name: 'John', surname: 'Doe'});
 *
 * Result: Hello John Doe.
 *
 * @param {strings[]} strings The template-string chunks
 * @param {...strings} placeholders The placeholders to be replaced
 * @return {string} The complete string with all placeholders replaced
 */
export const templateString = (strings, ...placeholders) => {
  let objectValue = (target, path) => {
    let steps = path.split(".");

    return steps.reduce((value, direction) => {
      return typeof value === "object" && value.hasOwnProperty(direction)
        ? value[direction]
        : "";
    }, target);
  };

  return (...values) => {
    var dict = values[values.length - 1] || {};
    var result = [strings[0]];

    placeholders.forEach((placeholder, i) => {
      var value = Number.isInteger(placeholder)
        ? values[placeholder]
        : objectValue(dict, placeholder);
      result.push(value, strings[i + 1]);
    });

    return result.join("");
  };
};

/**
 * Replace the named placeholders in the provided string
 *
 * Usage:
 * let template = '${name} father is called ${father.name}';
 * let data = { name: 'John', father: { name: 'Mark' } };
 * let result = parseTemplate( template, params, 'N/A' );
 *
 * Result: John father is called Mark
 *
 * @param  {strings[]}  template    The template string
 * @param  {strings}    map         The data to be replaced
 * @param  {strings}    [fallback]  The string to be replaced if the pleceholder is not found in data
 * @return {string} The complete string with all placeholders replaced
 */
export const parseTemplate = (template, map, fallback) => {
  return template.replace(/\$\{[^}]+\}/g, match =>
    match
      .slice(2, -1)
      .trim()
      .split(".")
      .reduce(
        (searchObject, key) => searchObject[key] || fallback || match,
        map
      )
  );
};

export async function sendWhatsappMessage(task, courseRef) {

  let course = await Course.model().getByRef(courseRef);
  let customer = await Customer.model().getByRef(course.customer);
  let manager = await User.model().getByRef(course.manager);
  let center = await Center.model().getByRef(course.center);

  customer.contactPerson = await CustomerRelative.model({ customerId: customer.id }).getByRef(customer.contactPerson);

  const params = {
    customer,
    manager,
    center,
    course,
    task
  };


  [
    "welcome",
    "onboarding",
    "start",
    "payment",
    "coaching",
  ].forEach(
    prop => {
      let propValue = params.course[prop + 'DateTime'];
      if (propValue) {
        try {
          params.course[prop + 'Date'] = new Date(propValue).toLocaleDateString("it-IT");
          params.course[prop + 'Time'] = new Date(propValue).toLocaleTimeString("it-IT");
        } catch (error) {
          console.error("ERROR IN FORMATTING DATES", error);
        }
      }
    }
  );

  try {
    params.course['endDate'] = new Date(params.course['endDate']).toLocaleDateString("it-IT");
  } catch (error) {
    console.error("ERROR IN FORMATTING DATES", error);
  }

  var now = new Date();
  var hours = now.getHours();
  params.dayPeriod = hours > 12 ? "serata" : "giornata";
  params.dayPeriodShort = hours > 12 ? "sera" : "giorno";
  params.dayGreeting = hours > 12 ? "buonasera" : "buongiorno";

  const courseStart = new Date(course.startDateTime);
  var timeDiff = courseStart.getTime() - now.getTime();
  params.course.startDays = Math.floor(timeDiff / (1000 * 3600 * 24));

  let waPhone = "";

  if (params.customer.contactPerson.phone) {
    waPhone = "39" + params.customer.contactPerson.phone.replace(/\D/g, "");
  }

  let content = "";
  if (task.templateSms) {
    content = parseTemplate(task.templateSms, params);
  }

  window.open(
    `https://wa.me/${waPhone}?text=` + encodeURIComponent(content),
    "_blank"
  );

}

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    return new Promise((resolve, reject) => {
      var context = this, args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) resolve(func.apply(context, args));
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) resolve(func.apply(context, args));
    })
  }
};