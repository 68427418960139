import React from "react";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import AccountCircle from "@material-ui/icons/AccountCircle";

import logo from "../../../assets/png/dysway_logo-white.png";
import headerBackground from "../../../assets/png/header-background.png";
import DrawerToggle from "../drawertoggle/drawertoggle";
import { userCan } from "../../../shared/utility";

const HeaderPrivate = props => {

  const UserAvatar = () => {
    if (!props.user) {
      return null;
    }
    
    let picture = props.user.profile.picture;

    if ( picture ) {
      return (
        <ListItemAvatar
          style={{
            cursor: "pointer",
            border: "1.5px solid white",
            background: "white"
          }}
        >
          <Avatar alt="img_profile" src={picture} sizes="small" />
        </ListItemAvatar>
      );
    } else {
      return (
        <ListItemAvatar
          style={{
            cursor: "pointer",
            border: "1.5px solid white",
            background: "white"
          }}
        >
          <AccountCircle />
        </ListItemAvatar>
      );
    }
  }

  return (
    <header
      className="header"
      style={{
        backgroundImage: `url(${headerBackground})`
      }}
    >
      <DrawerToggle clicked={props.drawerToggleClicked} />
      <div className="header__nav">
        <span>
          <img src={logo} alt="dysway-logo" className="header__logo" />
        </span>
        <ul className="header__nav--list">
          { userCan( 'course__list--own-center' ) && ( 
          <li>
            <NavLink to="/" exact>
              Dashboard
            </NavLink>
          </li>
          )}
          { userCan( 'course__list--own-center') && ( 
          <li>
            <NavLink to="/corsi">Corsisti</NavLink>
          </li>
          )}
          { userCan( 'customer__list--own-center') && ( 
          <li>
            <NavLink to="/clienti">Clienti</NavLink>
          </li>
          )}
          { userCan( 'task__update') && ( 
          <li>
            <NavLink to="/tasks">Attività</NavLink>
          </li>
          )}
          { userCan( 'course_template__update') && (          
          <li>
            <NavLink to="/modelli-corso">Modelli Corso</NavLink>
          </li>
          )}
          { userCan( 'center__update') && (
            <li>
              <NavLink to="/centri">Centri</NavLink>
            </li>
          )}
          { userCan( 'users__update') && (
          <li>
            <NavLink to="/utenti">Utenti</NavLink>
          </li>
          )}
          <li className="header__divider" />
          <li className="header__user" >
            <Link to={"/utente/" + props.user.id }><UserAvatar /></Link>
          </li>
          <li className="header__right">
            <span className="header__name">
              {props.user.auth.displayName
                ? props.user.auth.displayName
                : props.user.profile.name}
            </span>
            <span className="header__logout" onClick={props.logout}>
              Logout
            </span>
          </li>
        </ul>
      </div>
    </header>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(HeaderPrivate);
