import { updateObject } from "../../../shared/utility";
import * as actionTypes from "../../types/types";

const initialState = {
  centers: [],
  center: null,
  loading: false,
  error: null
};

export const getCentersStart = (state, action) => {
  return updateObject(state, { loading: true });
};
export const getCentersSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    centers: action.payload.centers
  });
};
export const getCentersFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};
export const getCenterStart = (state, action) => {
  return updateObject(state, { loading: true });
};
export const getCenterSuccess = (state, action) => {
  return updateObject(state, { loading: false, center: action.payload.center });
};
export const getCenterFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};
export const saveCenterStart = (state, action) => {
  return updateObject(state, { loading: true });
};
export const saveCenterSuccess = (state, action) => {
  let modify_centers = { ...state }.centers;
  if (action.payload.center.id) {
    let modiyId = modify_centers
      .map(el => el.id)
      .indexOf(action.payload.center.id);
    modify_centers[modiyId] = action.payload.center;
  } else {
    modify_centers.push(action.payload.center);
  }
  return updateObject(state, {
    loading: false,
    message: "Centro salvato con successo",
    centers: modify_centers,
    center: null
  });
};
export const saveCenterFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore nel salvataggio. Riprovare.",
    error: action.payload.error
  });
};
export const deleteCenterStart = (state, action) => {
  return updateObject(state, { loading: false });
};
export const deleteCenterSuccess = (state, action) => {
  let remove_centers = { ...state }.centers;
  let removeId = remove_centers.map(el => el.id).indexOf(action.payload.id);
  remove_centers.splice(removeId, 1);
  return updateObject(state, {
    loading: false,
    centers: remove_centers,
    message: "Centro eliminato con successo"
  });
};
export const deleteCenterFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};
export const resetCenter = (state, action) => {
  return updateObject(state, { center: null });
};
export const resetCenterMessage = (state, action) => {
  return updateObject(state, { message: null });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CENTERS_START:
      return getCentersStart(state, action);
    case actionTypes.GET_CENTERS_SUCCESS:
      return getCentersSuccess(state, action);
    case actionTypes.GET_CENTERS_FAIL:
      return getCentersFail(state, action);
    case actionTypes.GET_CENTER_START:
      return getCenterStart(state, action);
    case actionTypes.GET_CENTER_SUCCESS:
      return getCenterSuccess(state, action);
    case actionTypes.GET_CENTER_FAIL:
      return getCenterFail(state, action);
    case actionTypes.SAVE_CENTER_START:
      return saveCenterStart(state, action);
    case actionTypes.SAVE_CENTER_SUCCESS:
      return saveCenterSuccess(state, action);
    case actionTypes.SAVE_CENTER_FAIL:
      return saveCenterFail(state, action);
    case actionTypes.DELETE_CENTER_START:
      return deleteCenterStart(state, action);
    case actionTypes.DELETE_CENTER_SUCCESS:
      return deleteCenterSuccess(state, action);
    case actionTypes.DELETE_CENTER_FAIL:
      return deleteCenterFail(state, action);
    case actionTypes.RESET_CENTER:
      return resetCenter(state, action);
    case actionTypes.RESET_CENTER_MESSAGE:
      return resetCenterMessage(state, action);
    default:
      return state;
  }
};

export default reducer;
