import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/core/styles";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  root: {
    maxWidth: "none"
  },
  snackbar: {
    margin: theme.spacing(1)
  },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  },
  closeSuccess: {
    backgroundColor: "#43a047 !important"
  },
  closeError: {
    backgroundColor: `${theme.palette.error.dark} !important`
  }
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  let snackbarActions = [
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      className={
        variant === "error" ? classes.closeError : classes.closeSuccess
      }
      onClick={onClose}
    >
      <CloseIcon className={classes.icon} />
    </IconButton>
  ];

  return (
    <SnackbarContent
      className={classNames(classes[variant], classes.root, classes.snackbar)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={snackbarActions.reverse()}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
  margin: {
    margin: theme.spacing(1)
  }
});

const SnackBar = props => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={2500}
      onClose={props.handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      <MySnackbarContentWrapper
        onClose={props.handleClose}
        variant={!props.error ? "success" : "error"}
        message={props.message}
      />
    </Snackbar>
  );
};

SnackBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles2)(SnackBar);
