import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

import Center from "../../forms/center";
import UserLinker from "./UserLinker";

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

const styles = theme => ({});

const CenterTabs = props => {
  const [value, setValue] = useState(0);

  const handleChange = (event, value) => {
    setValue(value);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const { theme } = props;

  return (
    <div className="tabs">
      <AppBar position="static" color="default" className="tabs__header">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Informazioni" className="tab__button" />
          <Tab label="Contabilità" className="tab__button" />
          <Tab label="Coach" className="tab__button" />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabContainer dir={theme.direction}>
          <Center
            values={props.values}
            users={props.users}
            errors={props.errors}
            touched={props.touched}
          />
        </TabContainer>
        <TabContainer dir={theme.direction}>
          <UserLinker
            key="administrator-manager"
            availableUsers={props.users}
            currentUsers={props.values.accountingUsers}
            onAdd={props.addAccountingUsers}
            onRemove={props.removeAccountingUsers}
            actionTitle={'Aggiungi Nuovo Amministratore'}
          />
        </TabContainer>
        <TabContainer dir={theme.direction}>
          <UserLinker
            key="coaches-manager"
            availableUsers={props.users}
            currentUsers={props.values.coachUsers}
            onAdd={props.addCoachUsers}
            onRemove={props.removeCoachUsers}
            actionTitle={'Aggiungi Nuovo Coach'}
          />          
        </TabContainer>
      </SwipeableViews>
    </div>
  );
};

CenterTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(CenterTabs);
