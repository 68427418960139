export const GET_COURSE_TEMPLATES = "GET_COURSE_TEMPLATES";
export const GET_COURSE_TEMPLATES_START = "GET_COURSE_TEMPLATES_START";
export const GET_COURSE_TEMPLATES_SUCCESS = "GET_COURSE_TEMPLATES_SUCCESS";
export const GET_COURSE_TEMPLATES_FAIL = "GET_COURSE_TEMPLATES_FAIL";

export const GET_COURSE_TEMPLATE = "GET_COURSE_TEMPLATE";
export const GET_COURSE_TEMPLATE_START = "GET_COURSE_TEMPLATE_START";
export const GET_COURSE_TEMPLATE_SUCCESS = "GET_COURSE_TEMPLATE_SUCCESS";
export const GET_COURSE_TEMPLATE_FAIL = "GET_COURSE_TEMPLATE_FAIL";

export const SAVE_COURSE_TEMPLATE = "SAVE_COURSE_TEMPLATE";
export const SAVE_COURSE_TEMPLATE_START = "SAVE_COURSE_TEMPLATE_START";
export const SAVE_COURSE_TEMPLATE_SUCCESS = "SAVE_COURSE_TEMPLATE_SUCCESS";
export const SAVE_COURSE_TEMPLATE_FAIL = "SAVE_COURSE_TEMPLATE_FAIL";

export const RESET_COURSE_TEMPLATE = "RESET_COURSE_TEMPLATE";
export const RESET_COURSE_TEMPLATE_MESSAGE = "RESET_COURSE_TEMPLATE_MESSAGE";

export const DELETE_COURSE_TEMPLATE = "DELETE_COURSE_TEMPLATE";
export const DELETE_COURSE_TEMPLATE_START = "DELETE_COURSE_TEMPLATE_START";
export const DELETE_COURSE_TEMPLATE_SUCCESS = "DELETE_COURSE_TEMPLATE_SUCCESS";
export const DELETE_COURSE_TEMPLATE_FAIL = "DELETE_COURSE_TEMPLATE_FAIL";

export const CLONE_COURSE = "CLONE_COURSE";
export const CLONE_COURSE_START = "CLONE_COURSE_START";
export const CLONE_COURSE_SUCCESS = "CLONE_COURSE_SUCCESS";
export const CLONE_COURSE_FAIL = "CLONE_COURSE_FAIL";
