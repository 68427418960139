import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Form, Field } from "formik";
import { Typography } from "@material-ui/core";

import { firebase } from '../../../firebase/firebase';
import errorHandler from '../../../shared/errorReporting';

import {
  Grid,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  Button
} from "@material-ui/core";

import Select from "../select";
import { userCan } from "../../../shared/utility";

const UserForm = props => {
  const { values, touched, errors, roles, isSelf } = props;

  const [calendars, setCalendars] = useState([]);

  const refreshCalendar = () => {

    if (!isSelf) { 
      return;
    }

    firebase.auth().currentUser.getIdToken(true)
      .then(function (token) {
        return fetch(`/google/calendars?idToken=${token}`);
      })
      .then(response => response.json())
      .then(data => {
        if (data.items) {
          setCalendars(data.items);
        } 
      })
      .catch(function (err) {
        errorHandler.report(err);
      });
  }

  const connectCalendar = () => {

    if (!isSelf) { 
      return;
    }

    firebase.auth().currentUser.getIdToken(true)
      .then(function (token) {
        const oauthPopup = window.open(`/oauth/start?idToken=${token}`);
        oauthPopup.onbeforeunload = refreshCalendar
      })
      .catch(function (err) {
        errorHandler.report(err);
      });
  }

  useEffect(refreshCalendar, [])

  return (
    <Form>
      <Paper className="PaperContainer">
        <FormControl component="fieldset" fullWidth className="fields-group">
          <Grid container spacing={3} className="form-maxWidth">
            <Grid item xs={12} sm={6} md={6}>
              <Field
                name="name"
                value={values.name}
                children={({ field, form }) => {
                  return (
                    <TextField
                      id="name"
                      value={field.value}
                      name="name"
                      onChange={e => {
                        form.setFieldValue(field.name, e.target.value);
                      }}
                      variant="outlined"
                      autoComplete="off"
                      label="Nome"
                      type="text"
                      fullWidth
                      error={touched.name && errors.name}
                      helperText={
                        touched.name && errors.name && `${errors.name}`
                      }
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Field
                name="surname"
                value={values.surname}
                children={({ field, form }) => {
                  return (
                    <TextField
                      id="surname"
                      value={field.value}
                      name="surname"
                      onChange={e => {
                        form.setFieldValue(field.name, e.target.value);
                      }}
                      variant="outlined"
                      autoComplete="off"
                      label="Cognome"
                      type="text"
                      fullWidth
                      error={touched.surname && errors.surname}
                      helperText={
                        touched.surname && errors.surname && `${errors.surname}`
                      }
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Field
                name="email"
                value={values.email}
                children={({ field, form }) => {
                  return (
                    <TextField
                      id="email"
                      value={field.value}
                      name="email"
                      onChange={e => {
                        form.setFieldValue(field.name, e.target.value);
                      }}
                      variant="outlined"
                      autoComplete="off"
                      label="Email"
                      type="text"
                      fullWidth
                      error={touched.email && errors.email}
                      helperText={
                        touched.email && errors.email && `${errors.email}`
                      }
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Field
                name="phone"
                value={values.email}
                children={({ field, form }) => {
                  return (
                    <TextField
                      id="phone"
                      value={field.value}
                      name="phone"
                      onChange={e => {
                        form.setFieldValue(field.name, e.target.value);
                      }}
                      variant="outlined"
                      autoComplete="off"
                      label="Telefono"
                      type="text"
                      fullWidth
                      error={touched.phone && errors.phone}
                      helperText={
                        touched.phone && errors.phone && `${errors.phone}`
                      }
                    />
                  );
                }}
              />
            </Grid>
            {userCan('users__update') && (
            <Grid item xs={12} sm={6} md={6}>
              <Field
                name="role"
                value={values.role}
                children={({ field, form }) => {
                  return (
                    <Select
                      value={field.value}
                      onChange={e => {
                        form.setFieldValue(field.name, e.target.value);
                      }}
                      id="role"
                      label="Ruolo"
                      error={touched.role && errors.role}
                      helperText={
                        touched.role && errors.role && `${errors.role}`
                      }
                      options={roles.map(role => ({
                        label: role.name,
                        value: role.id
                      }))}
                    />
                  );
                }}
              />
              </Grid>
            )}
            {userCan('users__update') && (
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={6}
              className="radio-button-container"
            >
              <Field
                name="active"
                value={values.active}
                children={({ field, form }) => {
                  return (
                    <FormControl component="fieldset" required>
                      <FormLabel component="legend" className="txtLeft">
                        Utente Attivo
                      </FormLabel>
                      <RadioGroup
                        aria-label="Gender"
                        value={field.value ? 1 : 0}
                        className="radioGroup"
                        onChange={e => {
                          form.setFieldValue(field.name, Boolean(e.target.value));
                        }}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="Si"
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  );
                }}
              />
            </Grid>
            )}
            {isSelf && (
              <Grid item xs={12}>
                <Typography component="h3" className="form-maxWidth" variant="subtitle1">
                  Google Calendar
                </Typography>
                {calendars.length > 0 && (<Field
                  name="googleCalendarId"
                  value={values.googleCalendarId}
                  children={({ field, form }) => {
                    return (
                      <Select
                        value={field.value}
                        onChange={e => {
                          form.setFieldValue(field.name, e.target.value);
                        }}
                        id="googleCalendarId"
                        label="Google Calendar"
                        error={touched.googleCalendarId && errors.googleCalendarId}
                        helperText={
                          touched.googleCalendarId && errors.googleCalendarId && `${errors.googleCalendarId}`
                        }
                        options={calendars.map(calendar => ({
                          label: calendar.summary,
                          value: calendar.id
                        }))}
                      />
                    );
                  }}
                />)}
                <Button style={{ marginTop: "20px" }} onClick={connectCalendar} >Connetti un nuovo account Google Calendar</Button>
              </Grid>
            )}
          </Grid>
        </FormControl>
      </Paper>
    </Form>
  );
};

export default withRouter(UserForm);
