import React from "react";
import { CirclePicker } from "react-color";
//Material Import
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
/* import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox"; */

const initialState = {
  name: "",
  color: ""
  /*   default: false,
  final: false */
};
const LabelsForm = props => {
  const [status, setStatus] = React.useState(initialState);
  const [mod, setMod] = React.useState(false);
  const [prevStatus, setPrevStatus] = React.useState(initialState);

  const handleStatus = ({ target }) => {
    setStatus({ ...status, [target.name]: target.value });
  };

  /*   const handleChecked = ({ target }) => {
    setStatus({ ...status, [target.name]: target.checked });
  }; */

  const handleChangeColor = color => {
    setStatus({ ...status, color: color.hex });
  };

  React.useEffect(() => {
    if (props.modifyStatus) {
      setStatus(props.modifyStatus);
      setPrevStatus(props.modifyStatus);
      setMod(true);
    }
  }, [props.modifyStatus]);

  React.useEffect(() => {
    props.validateForm(status, prevStatus);
  }, [status]);

  const addStatus = () => {
    props.addStatus(status);
    setStatus(initialState);
  };

  const modifyStatus = () => {
    props.modify(status);
    setStatus(initialState);
    setMod(false);
  };
  return (
    <>
      <Typography component="h3" variant="subtitle1">
        {!mod ? "Aggiungi Nuova Etichetta" : "Modifica Etichetta"}
      </Typography>
      <TextField
        variant="outlined"
        autoComplete="off"
        label="Nome etichetta"
        type="text"
        name="name"
        onChange={handleStatus}
        value={status.name}
        fullWidth
      />

      <Grid container spacing={3} className="color-choice">
        <Grid item xs={12} sm={12} md={12} className="color-picker">
          <FormLabel component="label" className="tab-form__label">
            Colore etichetta
          </FormLabel>
          <CirclePicker
            onChangeComplete={handleChangeColor}
            color={status.color}
            colors={[
              "#157E91",
              "#9BC900",
              "#CC743E",
              "#C14343",
              "#7D549B",
              "#7BA3AA"
            ]}
          />
        </Grid>
        <Grid className="tab-form__submit">
          {mod ? (
            <Button
              variant="contained"
              component="span"
              onClick={() => modifyStatus(status)}
              className="button button--outlined"
            >
              Modifica etichetta
            </Button>
          ) : (
            <Button
              variant="contained"
              component="span"
              onClick={() => addStatus(status)}
              className="button"
            >
              Crea etichetta
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default LabelsForm;
