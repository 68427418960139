import * as yup from 'yup';
import QueueItem from './index';

export default class QueueEmail extends QueueItem {

    static get QUEUE_TYPE() { return 'email' };

    /**
     * The collection name in the database
     */
    get validationSchema() {
        return yup.object().shape({
            task: yup.string().required("Task obbligatorio"),
            course: yup.string().required("Corso obbligatorio"),
            templateEmail: yup.string().required("Template obbligatorio"),
            type: yup.string().required("Tipo obbligatorio"),
        });
    };

}
