import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../../../store/actions/index";
import Layout from "../../../hoc/Layout";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "./_list";

export const Dashboard = props => {

  React.useEffect(() => {
    props.onGetCoursesRecap();
  }, []);

  const goToEdit = id => {
    props.history.push(`/dettaglio-corso/${id}`);
  };

  return (
    <Layout>
      <div className="box-layout">
        {!props.loading ? (
          <div className="box-layout__table">
            <div className="topSection">
              <h1>Corsi Attivi</h1>
            </div>
            <List
              courses={props.coursesRecap}
              goToEdit={goToEdit}
            />
          </div>
        ) : (
                    <div className="box-layout box-layout__progress">
            <CircularProgress
              size={80}
              thickness={4}
              className="main-progress"
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.course.loading,
    coursesRecap: state.course.coursesRecap
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetCoursesRecap: () => dispatch(actions.getCoursesRecap())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
