import FirestoreModel from '../firestore';
import * as yup from 'yup';

export default class QueueItem extends FirestoreModel {

    /**
     * The collection name in the database
     */
    get collectionName() {
        return 'send_queue';
    };

    /**
     * The collection name in the database
     */
    get validationSchema() {
        return yup.object().shape({
            task: yup.string().required("Task obbligatorio"),
            course: yup.string().required("Corso obbligatorio"),
            type: yup.string().required("Tipo obbligatorio"),
        });
    };

}
