import * as types from "../types/types";

export const authUser = user => ({ type: types.AUTH_USER, payload: { user } });
export const authUserStart = () => ({ type: types.AUTH_USER_START });
export const authUserSuccess = user => ({
  type: types.AUTH_USER_SUCCESS,
  payload: { user }
});
export const authUserFail = (error, errorCode, username) => ({
  type: types.AUTH_USER_FAIL,
  payload: { error, errorCode, username }
});
export const authUserCheckState = user => ({
  type: types.AUTH_USER_CHECK_STATE,
  payload: { user }
});
export const authUserCheckStateSuccess = user => ({
  type: types.AUTH_USER_CHECK_STATE_SUCCESS,
  payload: { user }
});
export const authUserCheckTimeout = expirationTime => ({
  type: types.AUTH_USER_CHECK_TIMEOUT,
  expirationTime
});
export const authUserLogout = () => ({ type: types.AUTH_USER_LOGOUT });
export const authUserLogoutSuccess = () => ({
  type: types.AUTH_USER_LOGOUT_SUCCESS
});
