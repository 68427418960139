import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

import Activity from "../../forms/activity";
import Materials from "./Containers/materials/materials";
import Labels from "./Containers/labels/labels";

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

const styles = theme => ({});

const ActivityTabs = props => {
  const [value, setValue] = useState(0);

  const handleChange = (event, value) => {
    setValue(value);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const { theme } = props;

  return (
    <div className="tabs">
      <AppBar position="static" color="default" className="tabs__header">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Attività" className="tab__button" />
          <Tab label="Stati / Etichette" className="tab__button" />
          <Tab label="Documenti" className="tab__button" />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabContainer dir={theme.direction}>
          <Activity
            handleChange={props.handleChange}
            handleCheck={props.handleCheck}
            handleCanView={props.handleCanView}
            handleQuill={props.handleQuill}
            email_template={props.email_template}
            {...props.activity}
            canView={props.canView}
            date={props.date}
          />
        </TabContainer>
        <TabContainer dir={theme.direction}>
          <Labels
            statuses={props.activity.statuses}
            addStatus={props.addStatus}
            modifyStatus={props.modifyStatus}
            removeStatus={props.removeStatus}
            onSortEnd={props.onSortEnd}
            validateForm={props.validateForm}
          />
        </TabContainer>
        <TabContainer dir={theme.direction}>
          <Materials
            documents={props.activity.documents}
            addDocument={props.addDocument}
            modifyDocument={props.modifyDocument}
            removeDocument={props.removeDocument}
            validateForm={props.validateForm}
          />
        </TabContainer>
      </SwipeableViews>
    </div>
  );
};

ActivityTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(ActivityTabs);
