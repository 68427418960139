import React from 'react';
import AsyncSelect from 'react-select/async';
import NoSsr from '@material-ui/core/NoSsr';
import components from './components';

export default function AsyncSelectInput(props) {
  return (
      <NoSsr>
        <AsyncSelect
          classes={ props.classes || {} }
          className='react-select react-select--async'
          classNamePrefix="react-select"
          components={components}
          isClearable
          {...props}
          onChange={ (valueObject) => props.onChange( 
            {target: {name: props.name, ...valueObject}}, 
            valueObject 
          ) }
          placeholder={ props.placeholder || 'Digita per cercare..' }
        />
      </NoSsr>
  );
}