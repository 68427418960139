import React from "react";
import { Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

//table
import ReactTable from "react-table";
import "react-table/react-table.css";
import { PaginateButtonPrev, PaginateButtonNext } from "../../../../../table/pagination";

export default props => {

  return (
    <div className="react-table">
      <ReactTable
        data={props.documents}
        columns={[
          {
            Header: "Etichetta",
            id: "label",
            maxWidth: 250,
            accessor: 'label',
            filterable: false,


          },
          {
            Header: "File",
            id: "file",
            maxWidth: 250,
            accessor: 'name',
            filterable: false,


          },
          {
            Header: "Azioni",
            filterable: false,
            accessor: "actions",
            Cell: row => (
              <div className="actionTableContainer">
                <Tooltip
                  title="Modifica"
                  enterDelay={300}
                  position="bottom-end"
                >
                  <EditIcon
                    fontSize="small"
                    onClick={() => props.modifyId(row.original.name)}
                  />
                </Tooltip>
                <Tooltip title="Elimina" enterDelay={300}>
                  <DeleteIcon
                    fontSize="small"
                    onClick={() => props.removeDocument(row.original.name)}
                  />
                </Tooltip>
                <Tooltip title="Download" enterDelay={300}>
                  <button onClick={() => props.downloadAttachment(row.original)} >Scarica</button>
                </Tooltip>
              </div>
            )
          }
        ]}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        defaultPageSize={5}
        className="-striped -highlight"
        previousText={<PaginateButtonPrev />}
        nextText={<PaginateButtonNext />}
        pageText="Pag."
        ofText="di"
        rowsText="righe"
        noDataText="Nessun Materiale Trovato"
      />
      <br />
    </div>
  );
};
