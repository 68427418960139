import React from 'react';
import Select from 'react-select';
import NoSsr from '@material-ui/core/NoSsr';
import components from './components';
import { parseTemplate } from '../../../shared/utility';

export default function SelectInput(props) {
  
  let currentValue = props.value;
  
  if ( props.options && props.value && !(props.value.label || props.value.value) ) {
    currentValue = props.options.find( item => item && (item.value === props.value) );
  }

  return (
      <NoSsr>
        <Select
          classes={ props.classes || {} }
          className='react-select'
          classNamePrefix="react-select"
          components={components}
          isClearable
          placeholder={'Digita per cercare..'}
          {...props}
          onChange={ (valueObject) => props.onChange( { target: { name: props.name, ...valueObject } }, valueObject ) }
          value={ currentValue }
        />
      </NoSsr>
  );
}

/**
 * Convert lists into Select's formatted options
 * 
 * @param {object[]|array[]} options The options entries. Possible Formats:
 *                                   * Array pairs [ [ 'value1', 'Label1' ], [ 'value2', 'Label2' ], .. ]
 *                                   * Object arrays [ {..}, {..}, .. ]
 * @param {string?} labelTemplate    The template for the label, use placeholders for objects like '{firstName} {job.description}'. If omitted the first value is used;
 * @param {string?} valueField       The object field used for value;
 * 
 * @returns {object[]} The formatted options [ { label: 'Label1', value: 'value1' }, .. ]
 */
export function makeOptions(options, labelTemplate = null, valueField = null){

  if ( labelTemplate && valueField ) {
     return options.map( option => ({ label: parseTemplate( labelTemplate.replace(/\{/g, '${'), option, option[valueField] ), value: option[valueField] }) );
  }

  return options.map( ([ value, label ]) => ({ value, label: label || value }) );
}
