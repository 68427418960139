import * as yup from 'yup';
import QueueItem from './index';

export default class QueueSms extends QueueItem {

    static get QUEUE_TYPE() { return 'sms' };

    /**
     * The collection name in the database
     */
    get validationSchema() {
        return yup.object().shape({
            templateSms: yup.string().required("Template SMS obbligatorio"),
            task: yup.string().required("Task obbligatorio"),
            course: yup.string().required("Corso obbligatorio"),
            type: yup.string().required("Tipo obbligatorio"),
        });
    };

}
