
import StorageModel from '../storage';
import * as yup from 'yup';
import { MissingParentException } from '../exceptions';

export default class TaskAttachment extends StorageModel {
    
    /**
     * Setup the model
     * 
     * @param {object} props 
     */
    constructor(props) {
        super(props);

        const { taskId } = this.props;

        if ( !taskId ) {
            throw new MissingParentException(taskId);
        }

    }

    get folder() {
        return `task/${this.props.taskId}/documents/`
    }

    /**
     * The collection name in the database
     */
    get validationSchema() {
        return yup.object().shape({
            id: yup.string().required(),
            label: yup.string().required("Etichetta obbligatoria"),
            name: yup.string().label('Nome File'),
            path: yup.string().label('Percorso'),
            type: yup.string(),
            size: yup.number()            
        });
    };

    /**
     * Upload file in the storage and return a promise when completed
     * 
     * @param {object} doc The object containing the `file` to uploaded
     * 
     * @returns {Promise} Promise that resolve when file is uploaded
     */
    async create(doc) {
        return super.create(doc.blob);
    }

    /**
     * Update file in the storage and return a promise when completed
     * 
     * @param {object} doc The object containing the `file` to uploaded
     * 
     * @returns {Promise} Promise that resolve when file is uploaded
     */
    async update(doc) {
        return super.update(doc.blob);
    }    

}
