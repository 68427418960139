import * as actionTypes from "../../types/types";

export const getCourses = () => {
  return {
    type: actionTypes.GET_COURSES
  };
};
export const getCoursesStart = () => {
  return {
    type: actionTypes.GET_COURSES_START
  };
};
export const getCoursesSuccess = courses => {
  return {
    type: actionTypes.GET_COURSES_SUCCESS,
    payload: { courses }
  };
};
export const getCoursesFail = error => {
  return {
    type: actionTypes.GET_COURSES_FAIL,
    payload: { error }
  };
};

export const getCoursesRecap = () => {
  return {
    type: actionTypes.GET_COURSES_RECAP
  };
};
export const getCoursesRecapStart = () => {
  return {
    type: actionTypes.GET_COURSES_RECAP_START
  };
};
export const getCoursesRecapSuccess = coursesRecap => {
  return {
    type: actionTypes.GET_COURSES_RECAP_SUCCESS,
    payload: { coursesRecap }
  };
};
export const getCoursesRecapFail = error => {
  return {
    type: actionTypes.GET_COURSES_RECAP_FAIL,
    payload: { error }
  };
};

export const getCourse = id => {
  return {
    type: actionTypes.GET_COURSE,
    payload: { id }
  };
};
export const getCourseStart = () => {
  return {
    type: actionTypes.GET_COURSE_START
  };
};
export const getCourseSuccess = course => {
  return {
    type: actionTypes.GET_COURSE_SUCCESS,
    payload: { course }
  };
};
export const getCourseFail = error => {
  return {
    type: actionTypes.GET_COURSE_FAIL,
    payload: { error }
  };
};

export const getCourseSpec = id => {
  return {
    type: actionTypes.GET_COURSE_SPEC,
    payload: { id }
  };
};
export const getCourseSpecStart = () => {
  return {
    type: actionTypes.GET_COURSE_SPEC_START
  };
};
export const getCourseSpecSuccess = courseSpec => {
  return {
    type: actionTypes.GET_COURSE_SPEC_SUCCESS,
    payload: { courseSpec }
  };
};
export const getCourseSpecFail = error => {
  return {
    type: actionTypes.GET_COURSE_SPEC_FAIL,
    payload: { error }
  };
};

export const saveCourseSpec = courseSpec => {
  return {
    type: actionTypes.SAVE_COURSE_SPEC,
    payload: { courseSpec }
  };
};
export const saveCourseSpecStart = () => {
  return {
    type: actionTypes.SAVE_COURSE_SPEC_START
  };
};
export const saveCourseSpecSuccess = () => {
  return {
    type: actionTypes.SAVE_COURSE_SPEC_SUCCESS
  };
};
export const saveCourseSpecFail = error => {
  return {
    type: actionTypes.SAVE_COURSE_SPEC_FAIL,
    payload: { error }
  };
};

export const saveCourse = course => {
  return {
    type: actionTypes.SAVE_COURSE,
    payload: { course }
  };
};
export const saveCourseStart = () => {
  return {
    type: actionTypes.SAVE_COURSE_START
  };
};
export const saveCourseSuccess = () => {
  return {
    type: actionTypes.SAVE_COURSE_SUCCESS
  };
};
export const saveCourseFail = error => {
  return {
    type: actionTypes.SAVE_COURSE_FAIL,
    payload: { error }
  };
};
export const deleteCourse = id => {
  return {
    type: actionTypes.DELETE_COURSE,
    payload: { id }
  };
};
export const deleteCourseStart = () => {
  return {
    type: actionTypes.DELETE_COURSE_START
  };
};
export const deleteCourseSuccess = id => {
  return {
    type: actionTypes.DELETE_COURSE_SUCCESS,
    payload: { id }
  };
};
export const deleteCourseFail = error => {
  return {
    type: actionTypes.DELETE_COURSE_FAIL,
    payload: { error }
  };
};
export const resetCourse = () => {
  return {
    type: actionTypes.RESET_COURSE
  };
};
export const resetCourseMessage = () => {
  return {
    type: actionTypes.RESET_COURSE_MESSAGE
  };
};

export const resetCourseSpec = () => {
  return {
    type: actionTypes.RESET_COURSE_SPEC
  };
};

export const sendQueue = queue => {
  return {
    type: actionTypes.SEND_QUEUE,
    payload: { queue }
  };
};
export const sendQueueStart = () => {
  return {
    type: actionTypes.SEND_QUEUE_START
  };
};
export const sendQueueSuccess = queue => {
  return {
    type: actionTypes.SEND_QUEUE_SUCCESS,
    payload: { queue }
  };
};
export const sendQueueFail = error => {
  return {
    type: actionTypes.SEND_QUEUE_FAIL,
    payload: { error }
  };
};

export const courseTaskUpdate = (courseId, taskId, data) => {
  return {
    type: actionTypes.COURSE_TASK_UPDATE,
    payload: { courseId, taskId, data }
  };
};

export const courseTaskUpdateSuccess = (taskId, data) => {
  return {
    type: actionTypes.COURSE_TASK_UPDATE_SUCCESS,
    payload: { taskId, data }
  };
};