import React, { useState, useEffect } from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../../../store/actions/index";

import Layout from "../../../../../hoc/Layout";
import IconSave from "@material-ui/icons/Save";
import IconMail from "@material-ui/icons/Mail";
import IconCheck from "@material-ui/icons/Check";
import IconBack from "@material-ui/icons/ChevronLeft";

import { Button, Grid, Typography, FormControl } from "@material-ui/core";

import { sendWhatsappMessage, lastId } from "../../../../../../shared/utility";
import {
  useDialog,
  unsavedDialog,
  confirmDialog
} from "../../../../../shared/dialogs";
import errorHandler from "../../../../../../shared/errorReporting";
import Course from "../../../../../../models/course";
import CourseTaskAttachment from "../../../../../../models/course/task/attachment";
import Task from "../../../../../../models/task";
import CourseTaskForm from "../../../../../shared/forms/task";
import CourseTaskAttachmentsForm from "../../../../../shared/tabs/taskTabs/Containers/materials/materials";
import CourseTaskNotes from "../../../../../shared/tabs/taskTabs/Containers/Notes/notes";
import CourseTask from "../../../../../../models/course/task";
import QueueEmail from "../../../../../../models/queue/email";

const initialState = {
  attachments: [],
  group: "",
  group_name: "",
  owner: "",
  position: "",
  status: "",
  name: "",
  notes: null,
  type: "",
  description: "",
};

const taskTypeMap = new Map( Task.TASK_TYPES );

const CourseTaskEdit = props => {
  const [task, setTask] = useState(initialState);
  const [isDirty, setDirty] = useState(false);

  const [SendConfirmDialog, openSendConfirmDialog] = useDialog(confirmDialog, {
    id: null
  });
  const [UnsavedFormDialog, openDialogBack] = useDialog(unsavedDialog, {
    id: null
  });

  useEffect(() => {
    props.onGetUsers();
  }, []);

  const courseId = props.match.params.id;
  const courseTaskId = props.match.params.task_id;

  useEffect(() => {

    CourseTask.model({courseId}).get(courseTaskId).then( taskData => {
      
      if ( taskData.owner ) {
        taskData.owner = taskData.owner.id;
      }

      setTask(taskData);

    }).catch(errorHandler.report);

  }, [courseId, courseTaskId]);

  const setNotes = (notes) => {
    setDirty(true);
    setTask({ ...task, notes });
  }

  const setDate = (dueDateTime) => {
    setDirty(true);
    setTask({ ...task, dueDateTime });
  }

  const handleChange = ({ target }) => {
    setDirty(true);
    setTask({ ...task, [target.name]: target.value });
  };

  const addDocument = file => {
    let attachments = task.attachments;
    let d = {
      id: lastId(attachments) + 1,
      ...file
    };
    attachments.push(d);
    setDirty(true);
    setTask({ ...task, attachments });
  };

  const modifyDocument = doc => {
    let attachments = task.attachments;
    let index = _.findIndex(attachments, function(o) {
      return o.id === doc.id;
    });

    if (~index) {
      attachments[index] = doc;
    }
    setDirty(true);
    setTask({ ...task, attachments });
  };

  const removeDocument = name => {
    let attachments = task.attachments;
    
    let courseTaskId = props.match.params.task_id;
    let courseId = props.match.params.id;


    let removeId = attachments.map(el => el.name).indexOf(name);
    let doc = attachments.find(el => el.name === name);

    if ( props.match.params.id && doc.id ) {
      CourseTaskAttachment.model({ courseId, courseTaskId }).delete(doc);
    }    

    attachments.splice(removeId, 1);
    setDirty(true);
    setTask({ ...task, attachments });
  };

  const downloadAttachment = async (attachment) => {

    let courseId = props.match.params.id;
    let courseTaskId = props.match.params.task_id;

    return CourseTaskAttachment.model({ courseId, courseTaskId })
      // Find a better way to reference a file
      .getDownloadUrl(attachment)
      .then( downloadUrl => window.open( downloadUrl ) );
  };

  const sendQueue = () => {
    let data = {
      ...task,
      courseId,
      task_id: props.match.params.task_id,
      type: QueueEmail.QUEUE_TYPE
    };

    props.onSendQueue(data);
  };

  const completeTask = () => {
    switch (task.type) {
      case Task.TYPE_EMAIL:
        sendQueue();
        break;
      case Task.TYPE_MESSAGE:
        sendWhatsappMessage(
          task,
          Course.model().ref(props.match.params.id)
        );
        break;
      case Task.TYPE_MANUAL:
      case Task.TYPE_MEETING:
        setTask({ ...task, status: task.finalStatus });
        let task_spec = {
          ...task,
          status: task.finalStatus,
          course_id: props.match.params.id,
          task_id: props.match.params.task_id
        };
        props.onSaveCourseSpec(task_spec);
        break;
      default:
        errorHandler.report(`Invalid task type: ${task.type}`)
        break;
    }
  };

  const saveTaskSpec = () => {
    let task_spec = {
      ...task,
      course_id: props.match.params.id,
      task_id: props.match.params.task_id
    };
    props.onSaveCourseSpec(task_spec);
    props.location.search
      ? props.history.push("/")
      : props.history.push({
          pathname: `/dettaglio-corso/${props.match.params.id}`,
          data: { reload: true }
        });
  };

  const canGoBack = () => {
    if ( isDirty ) { 
      openDialogBack()
    } else {
      props.history.push(`/dettaglio-corso/${props.match.params.id}`);
    }
  };

  const validateForm = (state, prevState) => {
    if (_.isEqual(state, prevState)) return true;
    return false;
  };

  return (
    <Layout>
      <div className="box-layout">
        <div className="topSection">
          <h1>{props.match.params.id ? "Modifica" : "Aggiungi"} Attività</h1>
          <div className="topButtons">
            <Button
              onClick={canGoBack}
              className="button button--outlined">
              <IconBack/> Indietro
            </Button>
            <Button
              size="large"
              className="button button--confirm"
              onClick={saveTaskSpec}>
              <IconSave />
              Salva
            </Button>
          </div>
        </div>
        <div className="box-layout__box box-layout__box--auto box-layout__box--task">
          { task.name && (
          <div className="task-edit">
            <h2 className="task-edit__title">{task.name} - [{ taskTypeMap.get(task.type) }]</h2>
            <p className="task-edit__title" >{task.description}</p>
          </div>
          )}
          <CourseTaskForm
              {...task}
              setDate={setDate}
              users={props.users}
              handleChange={handleChange}
          />
          <FormControl component="fieldset" fullWidth className="fields-group">
            <Typography component="legend">Documenti Allegati</Typography>
            <CourseTaskAttachmentsForm
              documents={task.attachments}
              addDocument={addDocument}
              modifyDocument={modifyDocument}
              removeDocument={removeDocument}
              validateForm={validateForm}
              downloadAttachment={downloadAttachment}
            />
          </FormControl>
          <FormControl component="fieldset" fullWidth className="fields-group">
            <Typography component="legend" className="form-maxWidth">Note</Typography>
            <CourseTaskNotes
              notes={task.notes} handleQuill={task.notes !== null ? setNotes : () => {} } 
            />          
          </FormControl>
          {task.status !== task.finalStatus && (
            <Grid container className="box-layout__submit">
              <Button 
                onClick={openSendConfirmDialog}
                className="button"  
              >
                {task.type === Task.TYPE_EMAIL && (
                  <span>Invia Email <IconMail /></span>
                )}
                {task.type === Task.TYPE_MESSAGE && (
                  <span>Invia Messaggio <IconMail /></span>
                )}
                {task.type === Task.TYPE_MANUAL && (
                  <span>Segna come fatto <IconCheck /></span>
                )}
                {task.type === Task.TYPE_MEETING && (
                  <span>Segna come completato <IconCheck /></span>
                )}
              </Button>
            </Grid>
          )}
        </div>
      </div>
      <UnsavedFormDialog
        backMessage="Torna alla lista task"
        onConfirm={() =>
          props.history.push(`/dettaglio-corso/${props.match.params.id}`)
        }
      />
      <SendConfirmDialog onConfirm={completeTask} />
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    users: state.user.users,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetUsers: () => dispatch(actions.getUsers()),
    onSaveCourseSpec: courseSpec => dispatch(actions.saveCourseSpec(courseSpec)),
    onSendQueue: queue => dispatch(actions.sendQueue(queue))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseTaskEdit));
