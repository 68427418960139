import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import DeleteIcon from "@material-ui/icons/Delete";

//table
import ReactTable from "react-table";
import "react-table/react-table.css";

import matchSorter from "match-sorter";
import Task from "../../../../../models/task";
import { PaginateButtonNext, PaginateButtonPrev } from "../../../../shared/table/pagination";
import { userCan } from "../../../../../shared/utility";

const taskTypesMap = new Map(Task.TASK_TYPES);

export default props => {
  const findName = id => {
    if (id) {
      let email = props.email_template.find(el => el.id === id);
      if (email !== undefined) return email.name;
    }
  };

  return (
    <div className="react-table">
      <ReactTable
        data={props.activity_group}
        columns={[
          {
            Header: "Nome",
            id: "name",
            width: 300,
            accessor: 'name',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["name"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra per..."
                className="table-filter"
              />
            ),

          },
          {
            Header: "Tipo",
            id: "type",
            width: 150,
            accessor: 'type',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["type"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra per..."
                className="table-filter"
              />
            ),
            Cell: row => taskTypesMap.get(row.value)
          },
          {
            Header: "Template",
            id: "templateEmail",
            width: 300,
            filterable: false,
            accessor: "templateEmail",
            Cell: row => props.email_template && row.value !== "" ? `[${row.value}] - ${findName(row.value)}` : "N/A",
          },
          {
            Header: "Stati",
            id: "statuses",
            width: 350,
            filterable: false,
            accessor: "statuses",
            Cell: row => (
              <div className="status-list" >
                  {row.value.length > 0 && row.value.map((status, index) => (
                    (
                      <span className="status" key={index}>
                        <span className="status__color" style={{ backgroundColor: status.color }} />
                        {status.name}
                      </span>
                    )
                  ))}
              </div>
            )
          },
          {
            Header: "Calendario",
            id: "calendar",
            filterable: false,
            accessor: "calendar",
            width: 120,
            Cell: row => row.original.inCalendar ? <span>Si</span> : <span>No</span>
          },
          {
            Header: "Allegati",
            id: "material",
            filterable: false,
            accessor: "material",
            width: 100,
            Cell: row => <span>{row.original.documents.length > 0 ? `Si (${row.original.documents.length})` : 'No'} </span>
          },
          {
            Header: "Azioni",
            filterable: false,
            accessor: "actions",
            width: 105,
            Cell: row => (
              <div className="actionTableContainer">
                { userCan('task__update') && (
                <Tooltip
                  title="Modifica"
                  enterDelay={300}
                  position="bottom-end"
                >
                  <IconButton
                    className="iconButton colorGreen colorWhite"
                    onClick={() => props.goToEdit(row.original.id)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                )}
                { userCan('task__delete') && (
                <Tooltip title="Elimina" enterDelay={300}>
                  <IconButton
                    className="iconButton colorRed colorWhite"
                    onClick={() => props.openDialog(row.original.id)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                )}
              </div>
            )
          }
        ]}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        defaultPageSize={8}
        previousText={<PaginateButtonPrev />}
        nextText={<PaginateButtonNext />}
        pageText="Pag."
        ofText="di"
        rowsText="per pagina"
        noDataText="Nessuna Attività Trovata"
      />
      <br />
    </div>
  );
};
