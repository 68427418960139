import { put } from "redux-saga/effects";
import * as actions from "../../actions/index";
import AuthRole from "../../../models/role";

export function* getRoles() {
  yield put(actions.getRolesStart());
  try {
    const roles = yield AuthRole.model().list();
    yield put(actions.getRolesSuccess(roles));
  } catch (error) {
    yield put(actions.getRolesFail());
  }
}
