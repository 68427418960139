import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CalendarIcon from "@material-ui/icons/CalendarToday";

//table
import ReactTable from "react-table";
import "react-table/react-table.css";

import matchSorter from "match-sorter";
import { userCan } from "../../../../../shared/utility";
import Center from "../../../../../models/center";
import { PaginateButtonPrev, PaginateButtonNext } from "../../../../shared/table/pagination";

const centerRelationshipsMap = new Map(Center.RELATIONSHIPS);

export default props => {
  return (
    <div className="react-table">
      <ReactTable
        data={props.centers}
        columns={[
          {
            Header: "Nome",
            id: "name",
            width: 150,
            accessor: 'name',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["name"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                className="table-filter"
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra per..."
              />
            ),

          },
          {
            Header: "Email",
            id: "email",
            width: 240,
            accessor: 'email',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["email"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                className="table-filter"
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra per..."
              />
            ),

          },
          {
            Header: "Telefono",
            id: "phone",
            width: 130,
            accessor: "phone",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["phone"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                className="table-filter"
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra per..."
              />
            ),
          },
          {
            Header: "Manager",
            id: "managerName",
            filterable: false,
            width: 200,
            accessor: "_managerName"
          },
          {
            Header: "Fatturato",
            id: "revenue",
            width: 150,
            accessor: 'revenue',
            filterable: false,
            show: userCan('center__view_financials'),
          },
          {
            Header: "Relazione",
            id: "type",
            accessor: "type",
            width: 140,
            Cell: row => centerRelationshipsMap.get(row.value),
            filterMethod: (filter, row) => {
              return filter.value === "all" || row[filter.id] === filter.value;
            },
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="all">Mostra Tutti</option>
                {Center.RELATIONSHIPS.map(
                  ([relValue, relLabel]) => (<option key={relValue} value={relValue}>{relLabel}</option>)
                )}
              </select>
            )
          },
          // {
          //   Header: "Corsi Attivi",
          //   id: "activeCourses",
          //   accessor: "activeCourses",
          //   filterable: false,
          //   width: 120,
          // },
          // {
          //   Header: "Corsi Totali",
          //   id: "totalCourses",
          //   accessor: "totalCourses",
          //   filterable: false,
          //   width: 120,
          // },
          {
            Header: "Coach",
            id: "coach",
            accessor: "coachUsers",
            filterable: false,
            width: 100,
            Cell: row => row.value.length,
          },
          {
            Header: "Azioni",
            filterable: false,
            accessor: "actions",
            width: 140,
            Cell: row => (
              <div className="actionTableContainer">
                { userCan('center__update') && (
                <Tooltip
                  title="Modifica"
                  enterDelay={300}
                  position="bottom-end"
                >
                  <IconButton
                    className="iconButton colorGreen colorWhite"
                    onClick={() => props.goToEdit(row.original.id)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                )}
                { userCan('center__calendar') && (
                <Tooltip title="Calendario" enterDelay={100}>
                  <IconButton
                    className="iconButton colorGreen colorWhite"
                    onClick={() =>
                      props.openDialogCalendar(row.original.googleCalendarUrl)
                    }
                  >
                    <CalendarIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                )}
                { userCan('center__delete') && (
                <Tooltip title="Elimina" enterDelay={300}>
                  <IconButton
                    className="iconButton colorRed colorWhite"
                    onClick={() => props.openDialog(row.original.id)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                )}
              </div>
            )
          }
        ]}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        defaultPageSize={8}
        previousText={<PaginateButtonPrev />}
        nextText={<PaginateButtonNext />}
        pageText="Pagina"
        ofText="di"
        rowsText="per pagina"
        noDataText="Nessun Centro Trovato"
      />
      <br />
    </div>
  );
};
