import React from "react";
import Typography from "@material-ui/core/Typography";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // ES6
import { Field } from "formik";

const Notes = props => {
  return (
    <div className="quill-container">
      <Typography component="span" className="quill-container__note">
        Note del cliente
      </Typography>
        <Field
          name="notes"
          children={({ field, form }) => {
            return (
              <ReactQuill
                modules={Notes.modules}
                name="notes"
                placeholder="inserisci note o descrizione del corsista..."
                value={field.value || ''}
                onChange={value => {
                  form.setFieldValue(field.name, value);
                }}
              />
            );
          }}
        />
    </div>
  );
};

Notes.modules = {
  toolbar: [
    // [{ 'header': '1' }, { 'header': '2' }],
    [{ align: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"]
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
    matchers: [
      [
        Node.ELEMENT_NODE,
        (node, delta) => {
          let ops = [];
          delta.ops.forEach(op => {
            if (op.insert && typeof op.insert === "string") {
              ops.push({
                insert: op.insert
              });
            }
          });
          delta.ops = ops;
          return delta;
        }
      ]
    ]
  }
};

export default Notes;
