import * as actionTypes from "../../types/types";

export const getEmailTemplate = () => {
  return {
    type: actionTypes.GET_EMAIL_TEMPLATE
  };
};
export const getEmailTemplateStart = () => {
  return {
    type: actionTypes.GET_EMAIL_TEMPLATE_START
  };
};
export const getEmailTemplateSuccess = email_template => {
  return {
    type: actionTypes.GET_EMAIL_TEMPLATE_SUCCESS,
    payload: { email_template }
  };
};
export const getEmailTemplateFail = error => {
  return {
    type: actionTypes.GET_EMAIL_TEMPLATE_FAIL,
    payload: { error }
  };
};
