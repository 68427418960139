import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions/index";
import logo from "../../../../../assets/png/dysway-logo-colori.png";
import logoGoogle from "../../../../../assets/png/search.png";

import Footer from "../../../../shared/footer/Footer";

export const LoginPage = props => {
  const login = () => {
    props.onLogin();
    props.history.push("/corsi");
  };
  return (
    <div className="box-layout box-layout--login">
      <div className="login__page-content">
        <div className="box-layout__box">
          <h3 className="box-layout__title">
            <img src={logo} alt="dysway-logo" className="box-layout__logo" />
          </h3>
          <button onClick={login} className="button button--login">
            <img src={logoGoogle} alt="google-logo" className="logoGoogle" />
            Accedi con Google
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    errorMessage: state.auth.error,
    loading: state.auth.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogin: () => dispatch(actions.authUser())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginPage));
