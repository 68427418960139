import React from "react";

import Grid from "@material-ui/core/Grid";
import List from "./List/List";
import MaterialsForm from "./MaterialsForm/MaterialsForm";

const Materials = props => {
  const [modifyDoc, setModifyDoc] = React.useState(null);

  const ModifyId = name => {
    let doc = props.documents.find(el => el.name === name);
    setModifyDoc(doc);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={7}>
        <List
          documents={props.documents}
          removeDocument={props.removeDocument}
          modifyId={ModifyId}
        />
      </Grid>
      <Grid item xs={12} md={1} />
      <Grid item xs={12} md={4}>
        <MaterialsForm
          addDocument={props.addDocument}
          modify={props.modifyDocument}
          modifyDoc={modifyDoc}
          validateForm={props.validateForm}
        />
      </Grid>
    </Grid>
  );
};

export default Materials;
