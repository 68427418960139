import { put } from "redux-saga/effects";
import * as actions from "../../actions/index";
import UserInvite from "../../../models/user/invite";
import User from "../../../models/user";
import errorHandler from "../../../shared/errorReporting";

export function* getUsers() {
  yield put(actions.getUsersStart());

  try {
    const users = yield User.model().list();
    yield put(actions.getUsersSuccess(users));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.getUsersFail(error));
  }
}

export function* getUser(action) {
  yield put(actions.getUserStart());

  try {
    const user = yield User.model().get(action.payload.id);
    yield put(actions.getUserSuccess(user));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.getUserFail(error));
  }
}

export function* saveUser(action) {
  yield put(actions.saveUserStart());

  const user = action.payload.user;

  try {
    if (user.id) {
      yield User.model().update(user.id, user);
      yield put(actions.saveUserSuccess(user));
    } else {
      yield UserInvite.model().create(user);
      yield put(actions.saveUserSuccess(user));
    }
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.saveUserFail(error));
  }
}

export function* deleteUser(action) {
  yield put(actions.deleteUserStart());

  try {
    yield User.model().delete(action.payload.id);
    yield put(actions.deleteUserSuccess(action.payload.id));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.deleteUserFail(error));
  }
}
