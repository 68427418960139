import Model from "./model";
import { storage } from "../firebase/firebase";

export default class StorageModel extends Model {

    static get PAUSED() { return storage.TaskState.PAUSED };
    static get RUNNING() { return storage.TaskState.RUNNING };

    /**
     * Upload file in the storage and return a promise when completed
     * 
     * @param {File} file The file to uploaded
     * 
     * @returns {Promise} Promise that resolve when file is uploaded
     */
    get folder() {
        return ''
    }

    /**
     * Get the full path for the specified file
     * 
     * @param {File?} file The file
     * 
     * @returns {string}
     */
    path(file) {

        let path = [ this.folder ];

        if ( file ) {
            path.push(file.name);
        }

        return path.join('/');
    }

    /**
     * Return the storage reference for the specified file
     * 
     * @param {File?} file The file
     * 
     * @returns {firebase.storage.Reference}
     */
    ref(file) {
        return storage.child(this.path(file))
    }

    /**
     * Upload file in the storage and return the observable
     * 
     * @param {File} file The file to uploaded
     * 
     * @returns {Observable} Observe the file upload progress 
     *                       @see https://firebase.google.com/docs/storage/web/upload-files#monitor_upload_progress
     */
    async upload(file) {
        this.log( 'UPLOAD', {file} );

        return this.ref(file).put(file);
    }


    /**
     * Fetches a long lived download URL for this object.
     * 
     * @param {File} file The file to uploaded
     * 
     * @returns {Promise} A Promise that resolves with the download URL or rejects if the fetch failed, including if the object did not exist.
     */
    async getDownloadUrl(file) {
        this.log( 'GET DOWNLOAD URL', {file} );

        return this.ref(file).getDownloadURL();
    }

    
    /**
     * Upload file in the storage and return a promise when completed
     * 
     * @param {File} file The file to uploaded
     * 
     * @returns {Promise} Promise that resolve when file is uploaded
     */
    async create(file) {
        return this.upload(file).then();
    }

    /**
     * Update file in the storage and return a promise when completed
     * 
     * @param {File} file The file to uploaded
     * 
     * @returns {Promise} Promise that resolve when file is uploaded
     */
    async update(file) {
        return this.upload(file).then();
    }    

    /**
     * List file in the storage
     *  
     * @returns {Promise} Promise that resolve when file is uploaded
     */
    async list() {
        super.list();

        return this.ref(this.folder).listAll();
    }

    /**
     * Delete file from storage 
     *
     * @param {File} file The file to delete
     * 
     * @returns {Promise} Promise that resolve when file is deleted
     */
    async delete(file) {
        super.delete(file);

        return this.ref(file).delete();
    }

}