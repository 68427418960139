export const GET_COURSES = "GET_COURSES";
export const GET_COURSES_START = "GET_COURSES_START";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_FAIL = "GET_COURSES_FAIL";

export const GET_COURSES_RECAP = "GET_COURSES_RECAP";
export const GET_COURSES_RECAP_START = "GET_COURSES_RECAP_START";
export const GET_COURSES_RECAP_SUCCESS = "GET_COURSES_RECAP_SUCCESS";
export const GET_COURSES_RECAP_FAIL = "GET_COURSES_RECAP_FAIL";

export const GET_COURSE = "GET_COURSE";
export const GET_COURSE_START = "GET_COURSE_START";
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS";
export const GET_COURSE_FAIL = "GET_COURSE_FAIL";

export const GET_COURSE_SPEC = "GET_COURSE_SPEC";
export const GET_COURSE_SPEC_START = "GET_COURSE_SPEC_START";
export const GET_COURSE_SPEC_SUCCESS = "GET_COURSE_SPEC_SUCCESS";
export const GET_COURSE_SPEC_FAIL = "GET_COURSE_SPEC_FAIL";

export const SAVE_COURSE_SPEC = "SAVE_COURSE_SPEC";
export const SAVE_COURSE_SPEC_START = "SAVE_COURSE_SPEC_START";
export const SAVE_COURSE_SPEC_SUCCESS = "SAVE_COURSE_SPEC_SUCCESS";
export const SAVE_COURSE_SPEC_FAIL = "SAVE_COURSE_SPEC_FAIL";

export const SAVE_COURSE = "SAVE_COURSE";
export const SAVE_COURSE_START = "SAVE_COURSE_START";
export const SAVE_COURSE_SUCCESS = "SAVE_COURSE_SUCCESS";
export const SAVE_COURSE_FAIL = "SAVE_COURSE_FAIL";

export const RESET_COURSE = "RESET_COURSE";
export const RESET_COURSE_MESSAGE = "RESET_COURSE_MESSAGE";
export const RESET_COURSE_SPEC = "RESET_COURSE_SPEC";

export const DELETE_COURSE = "DELETE_COURSE";
export const DELETE_COURSE_START = "DELETE_COURSE_START";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAIL = "DELETE_COURSE_FAIL";

export const SEND_QUEUE = "SEND_QUEUE";
export const SEND_QUEUE_START = "SEND_QUEUE_START";
export const SEND_QUEUE_SUCCESS = "SEND_QUEUE_SUCCESS";
export const SEND_QUEUE_FAIL = "SEND_QUEUE_FAIL";

export const COURSE_TASK_UPDATE = "COURSE_TASK_UPDATE";
export const COURSE_TASK_UPDATE_SUCCESS = "COURSE_TASK_UPDATE_SUCCESS";
