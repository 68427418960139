import { put } from "redux-saga/effects";
import * as actions from "../../actions/index";
import errorHandler from "../../../shared/errorReporting";
import Customer from "../../../models/customer";
import CustomerRelative from "../../../models/customer/relative";
import Center from "../../../models/center";

export function* getCustomers() {
  yield put(actions.getCustomersStart());
  try {
    const customers = yield Customer.model().list();

    yield put(actions.getCustomersSuccess(customers));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.getCustomersFail(error));
  }
}

export function* getCustomer(action) {
  // Disabled: made a test to get data without saga

  yield put(actions.getCustomerStart());

  const customerId = action.payload.id;

  try {
    let customer = yield Customer.model().get(customerId);

    if (customer.contactPerson && customer.contactPerson.id) {
      customer.contactPerson = customer.contactPerson.id;
    }

    yield put(actions.getCustomerSuccess(customer));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.getCustomerFail(error));
  }
}

export function* saveCustomer(action) {
  yield put(actions.saveCustomerStart());

  let customerId = action.payload.customer.id;
  const customer = action.payload.customer;

  try {

    if ( customer.center ) {
      customer.center = Center.model().ref(customer.center);
    }

    customerId = yield Customer.model().write(customer, customerId);

    let savedRelativeIds = [];

    if (customer.relatives) {
      savedRelativeIds = yield CustomerRelative.model({ customerId }).sync(
        customer.relatives
      );
    }

    // @TODO handle contactPerson rewriting in a safer way
    if (customer.contactPerson) {
      let contactPersonIndex = customer.relatives.findIndex(
        relative =>
          customer.contactPerson === relative.tempId ||
          customer.contactPerson === relative.id
      );

      if (contactPersonIndex !== -1) {
        let contactPersonId = savedRelativeIds[contactPersonIndex];
        let contactPerson = CustomerRelative.model({ customerId }).ref(
          contactPersonId
        );
        yield Customer.model().write({ contactPerson }, customerId);
      }
    }

    yield put(actions.saveCustomerSuccess());
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.saveCustomerFail(error));
  }
}

export function* deleteCustomer(action) {
  yield put(actions.deleteCustomerStart());

  const customerId = action.payload.id;

  try {
    yield Customer.model().delete(customerId);
    yield put(actions.deleteCustomerSuccess(customerId));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.deleteCustomerFail(error));
  }
}
