import {
    ValidateException,
    PermissionException,
    DocumentNotFoundException,
} from './exceptions';

import { pick } from 'lodash';
import ModelCriteria from './criteria';

export default class Model {

    /**
     * Setup the model
     * 
     * @param {object} props 
     */
    constructor(props = {}) {
        this.props = props;
    }

    /**
     * Create a new instance of the model
     * 
     * @param {object} props The props passed to the class constructor
     * @returns {Model}
     */
    static model(props) {
        return new this(props);
    }

    /**
     * The data schema
     * 
     * @return {object}
     */
    get validationSchema() {
        return {};
    }

    /**
     * The data schema
     * 
     * @return {object}
     */
    get schema() {
        if (this.validationSchema) {
            return this.validationSchema.describe().fields;
        };

        return false;
    }

    /**
     * Retrieve the model valid attributes
     * es. `['name', 'surname', ...]`
     * 
     * @TODO extract from validation schema
     * 
     * @return {string[]}
     */
    get attributes() {

        // If is has a validation schema try to extract attributes from it
        if (this.schema) {
            return Object.keys(this.schema);
        }

        return false;
    }

    /**
     * Retrieve the model valid attributes labels
     * es. `{'name': 'Name', 'surname': 'Surname', ...}`
     * 
     * @TODO extract from validation schema
     * 
     * @return {}
     */
    get attributeLabels() {

        // If is has a validation schema try to extract attributes from it
        if (this.schema) {
            return Object.keys(this.schema).reduce((labels, fieldName) => {
                labels[fieldName] = this.schema[fieldName].label;
                return labels;
            }, {});
        }

        return false;
    }

    /**
     * Get the current model context
     * 
     * @returns {string} The current context
     */
    getContext() {
        return this.props.context;
    }

    /**
     * Set the current model context
     *  
     * @param {string} context One of the available contextes
     * 
     * @returns void
     */
    setContext(context) {
        this.props.context = context;
    }

    /**
     * Validate props
     * 
     * @param {object} props The props to validate
     * @returns {Promise} The errors found in validation
     */
    validate(props) {
        return this.validationSchema.isValid(props);
    }

    /**
     * Validate props syncronously
     * 
     * @param {object} props The props to validate
     * @returns {boolean} The errors found in validation
     */    
    validateSync(props){
        return this.validationSchema.isValidSync(props);
    }

    /**
     * Check if the user is allowed to perform the action
     * @param {string} action The action to be checked
     * @returns {bool} If the user is allowed
     */
    allow(action) {
        return true;
    }

    /**
     * Create a new object in database
     * 
     * @param {object}  props   The properties to save
     * @param {string?} docId   The id of the created document
     * 
     * @return {Promise} The created document id
     */
    async create(props, docId = null) {

        this.log( 'CREATE', {props, docId} );

        const errors = this.validate(props);
        if (errors.length > 0) {
            throw new ValidateException(errors);
        }

        if (!this.allow('create')) {
            throw new PermissionException();
        }
        
        return Promise.resolve(docId);
    }

    /**
     * Get a document from database
     * 
     * @param {object}  id  The id of the model to get
     * 
     * @return {Promise} The created document 
     */
    async get(id) {

        this.log( 'GET', {id} );

        if (!this.allow('get')) {
            throw new PermissionException('get');
        }

        return Promise.resolve();
    }

    /**
     * Get a document from database
     * 
     * @param {object}  id  The id of the model to get
     * 
     * @return {Promise} The created document 
     */
    async getBy(field, value) {

        this.log( 'GET-BY', {field, value} );

        const criteria = new ModelCriteria();
        criteria.where( field, '==', value );
        
        return this.list(criteria).then( results => {
            if ( results.length < 1 ) {
                throw new DocumentNotFoundException({field, value});
            }
            return results[0];
        });         
    }    

    /**
     * List documents
     * 
     * @return {Promise} 
     */
    async list(criteria) {

        this.log( 'LIST', {criteria: criteria.serialize()} );

        if (!this.allow('list')) {
            throw new PermissionException('list');
        }

        return Promise.resolve();
    }

    /**
     * Update object in database
     * 
     * @param {object}  id  The id of the model to update
     * @param {object} props The properties to save
     * 
     * @return {Promise}
     */
    async update(id, props) {

        this.log( 'UPDATE', {id, props} );

        const errors = this.validate(props);
        
        if (errors.length > 0) {
            throw new ValidateException(errors);
        }

        if (!this.allow('update')) {
            throw new PermissionException('update');
        }

        return Promise.resolve();        
    }

    /**
     * Create or update the object
     * 
     * @param {object}  props   The document props to write
     * @param {string?} id      The id of the document, if present it will be an update
     * 
     * @return {string} The document id that has been create or updated
     */
    async write(props, id) {

        this.log( 'WRITE', {props, id} );

        // If a docId is present perform an update
        if (id) {
            return this.update(id, props).then(() => id);
        }

        // Otherwise create a new doc
        return this.create(props);
    }

    /**
     * Duplicate the model 
     * 
     * @param {string}      id            The document id to clone
     * @param {string?}     nameAttribute The attributes that contains the name
     * @return {Promise}    The batch.commit response
     */
    async duplicate(id, nameAttribute) {

        this.log( 'DUPLICATE', {id, nameAttribute} );

        return this.get(id)
            .then(doc => {

                // Append `copy` to the name atribute, if present.
                if (nameAttribute && typeof doc[nameAttribute] !== 'undefined') {
                    doc[nameAttribute] += ' - copy';
                }

                return this.create(doc);
            });
    }

    /**
     * Delete object in database
     * 
     * @param {string}  id The object id to delete
     * @return {Promise}
     */
    async delete(id) {

        this.log( 'DELETE', {id} );

        if (!this.allow('delete')) {
            throw new PermissionException('delete');
        }

        return Promise.resolve();
    }

    /**
     * Keeps only validated props (attributes) defined in model 
     * Remove all unlisted or related props like: id, subcollections, indexes etc
     * WARN: If the model has no attributes keep all the props.
     * 
     * @param {object} doc The document object
     * @return {object} The document object with only the approved props
     */
    trim(doc) {

        // If Model has attributes definition, use it
        if (this.attributes !== false) {
            return pick(doc, this.attributes);
        }

        // Otherwise only strip id
        if (doc.id) {
            delete doc.id;
        }

        return doc;
    }
    
    /**
     * Model logger middleware
     * 
     * @param {string}   method  The document object
     * @param {object?}  context The document object
     * @param {string?}  message The document object
     * 
     * @return {void} 
     */
    log(method, context = null, message = '') {
        
        const environment = process.env.NODE_ENV;

        context.instanceProps = this.props;

        if (environment !== 'production') {
            console.log( '%s [%s] %s', this.constructor.name, method, message, context );
        }

    }

}