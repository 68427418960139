import { updateObject } from "../../../shared/utility";
import * as actionTypes from "../../types/types";

const initialState = {
  courses: [],
  coursesRecap: [],
  course: null,
  courseSpec: null,
  loading: false,
  message: null,
  sendMessage: null,
  error: null,
  queue: null
};

export const getCoursesStart = (state, action) => {
  return updateObject(state, { loading: true });
};
export const getCoursesSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    courses: action.payload.courses
  });
};
export const getCoursesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};
export const getCoursesRecapStart = (state, action) => {
  return updateObject(state, { loading: true });
};
export const getCoursesRecapSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    coursesRecap: action.payload.coursesRecap
  });
};
export const getCoursesRecapFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};
export const getCourseStart = (state, action) => {
  return updateObject(state, { loading: true });
};
export const getCourseSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    course: action.payload.course
  });
};
export const getCourseFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};
export const getCourseSpecStart = (state, action) => {
  return updateObject(state, { loading: true });
};
export const getCourseSpecSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    courseSpec: action.payload.courseSpec
  });
};
export const getCourseSpecFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};
export const saveCourseSpecStart = (state, action) => {
  return updateObject(state, { loading: true });
};
export const saveCourseSpecSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Attività del Corso salvata con successo",
    course: null
  });
};
export const saveCourseSpecFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};
export const saveCourseStart = (state, action) => {
  return updateObject(state, { loading: true, message: null });
};
export const saveCourseSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Corso salvato con successo",
    course: null
  });
};
export const saveCourseFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore nel salvataggio. Riprovare.",
    error: action.payload.error
  });
};
export const deleteCourseStart = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: null
  });
};
export const deleteCourseSuccess = (state, action) => {
  let remove_courses = { ...state }.courses;
  let removeId = remove_courses.map(el => el.id).indexOf(action.payload.id);
  remove_courses.splice(removeId, 1);
  return updateObject(state, {
    loading: false,
    message: "Corso eliminato con successo",
    courses: remove_courses
  });
};
export const deleteCourseFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};
export const resetCourse = (state, action) => {
  return updateObject(state, { course: null });
};
export const resetCourseMessage = (state, action) => {
  return updateObject(state, { message: null, sendMessage: null });
};
export const resetCourseSpec = (state, action) => {
  return updateObject(state, { courseSpec: null });
};
export const sendQueueStart = (state, action) => {
  return updateObject(state, { loading: true, message: null });
};
export const sendQueueSuccess = (state, action) => {
  // let courseSpec = { ...state }.courseSpec;
  // let courseSpecTask = courseSpec.tasks.find(
  //   el => el.id === action.payload.queue.task_id
  // );
  // let newStatus = courseSpecTask.statuses.find(
  //   el => el.id === action.payload.queue.finalStatus
  // );
  // courseSpecTask.status_name = newStatus.name;
  // courseSpecTask.status = newStatus.id;
  // for (let task of courseSpec.tasks) {
  //   if (task.id === courseSpecTask.id) {
  //     task = courseSpecTask;
  //   }
  // }
  return updateObject(state, {
    loading: false,
    sendMessage: "Messaggio messo in coda per l'invio",
    //courseSpec,
    queue: null
  });
};
export const sendQueueFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    sendMessage: null,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};

export const courseTaskUpdateSuccess = (state, action) => {

  let taskIndex = state.courseSpec.tasks.findIndex((task) => action.payload.taskId === task.id)

  if (taskIndex) {
    Object.assign(state.courseSpec.tasks[taskIndex], action.payload.data);
  }

  return Object.assign({}, state);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COURSES_START:
      return getCoursesStart(state, action);
    case actionTypes.GET_COURSES_SUCCESS:
      return getCoursesSuccess(state, action);
    case actionTypes.GET_COURSES_FAIL:
      return getCoursesFail(state, action);
    case actionTypes.GET_COURSES_RECAP_START:
      return getCoursesRecapStart(state, action);
    case actionTypes.GET_COURSES_RECAP_SUCCESS:
      return getCoursesRecapSuccess(state, action);
    case actionTypes.GET_COURSES_RECAP_FAIL:
      return getCoursesRecapFail(state, action);
    case actionTypes.GET_COURSE_START:
      return getCourseStart(state, action);
    case actionTypes.GET_COURSE_SUCCESS:
      return getCourseSuccess(state, action);
    case actionTypes.GET_COURSE_FAIL:
      return getCourseFail(state, action);
    case actionTypes.GET_COURSE_SPEC_START:
      return getCourseSpecStart(state, action);
    case actionTypes.GET_COURSE_SPEC_SUCCESS:
      return getCourseSpecSuccess(state, action);
    case actionTypes.GET_COURSE_SPEC_FAIL:
      return getCourseSpecFail(state, action);
    case actionTypes.SAVE_COURSE_SPEC_START:
      return saveCourseSpecStart(state, action);
    case actionTypes.SAVE_COURSE_SPEC_SUCCESS:
      return saveCourseSpecSuccess(state, action);
    case actionTypes.SAVE_COURSE_SPEC_FAIL:
      return saveCourseSpecFail(state, action);
    case actionTypes.SAVE_COURSE_START:
      return saveCourseStart(state, action);
    case actionTypes.SAVE_COURSE_SUCCESS:
      return saveCourseSuccess(state, action);
    case actionTypes.SAVE_COURSE_FAIL:
      return saveCourseFail(state, action);
    case actionTypes.DELETE_COURSE_START:
      return deleteCourseStart(state, action);
    case actionTypes.DELETE_COURSE_SUCCESS:
      return deleteCourseSuccess(state, action);
    case actionTypes.DELETE_COURSE_FAIL:
      return deleteCourseFail(state, action);
    case actionTypes.RESET_COURSE:
      return resetCourse(state, action);
    case actionTypes.RESET_COURSE_MESSAGE:
      return resetCourseMessage(state, action);
    case actionTypes.RESET_COURSE_SPEC:
      return resetCourseSpec(state, action);
    case actionTypes.SEND_QUEUE_START:
      return sendQueueStart(state, action);
    case actionTypes.SEND_QUEUE_SUCCESS:
      return sendQueueSuccess(state, action);
    case actionTypes.SEND_QUEUE_FAIL:
      return sendQueueFail(state, action);
    case actionTypes.COURSE_TASK_UPDATE_SUCCESS:
      return courseTaskUpdateSuccess(state, action);

    default:
      return state;
  }
};

export default reducer;
