import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

//table
import ReactTable from "react-table";
import "react-table/react-table.css";

import matchSorter from "match-sorter";
import moment from "moment";

import { TableColumnFilterText } from '../../../../shared/table';
import Customer from "../../../../../models/customer";
import { PaginateButtonPrev, PaginateButtonNext } from "../../../../shared/table/pagination";
import { UserEditIcon } from "../../../../shared/icons";
import { userCan } from "../../../../../shared/utility";

const customerGendersMap = new Map(Customer.GENDERS);

export default props => {
  return (
    <div className="react-table">
      <ReactTable
        data={props.customers}
        columns={[
          {
            Header: "Corsista",
            id: "fullName",
            width: 200,
            accessor: d => `${d.name} ${d.surname}`,
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["fullName"] }),
            filterAll: true,
            Filter: TableColumnFilterText,

          },
          {
            Header: "Familiare Referente",
            id: "contactPersonName",
            width: 200,
            accessor: '_contactPersonName',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['contactPersonName'] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra..."
                className="table-filter"
              />
            ),

          },
          {
            Header: "Email Referente",
            id: "contactPersonEmail",
            width: 240,
            accessor: '_contactPersonEmail',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["contactPersonEmail"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra..."
                className="table-filter"
              />
            ),

          },
          {
            Header: "Telefono",
            id: "contactPersonPhone",
            width: 130,
            accessor: '_contactPersonPhone',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["contactPersonPhone"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra..."
                className="table-filter"
              />
            ),

          },
          {
            Header: "Sesso",
            id: "gender",
            accessor: "gender",
            width: 130,
            Cell: row => customerGendersMap.get(row.original.gender),
            filterMethod: (filter, row) => {
              if (filter.value === "all") {
                return true;
              }

              return row[filter.id] && row[filter.id] === filter.value;
            },
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
                className="table-filter"
              >
                <option value="all">Mostra Tutti</option>
                {Customer.GENDERS.map(
                  ([genderValue, genderLabel]) => (<option key={genderValue} value={genderValue}>{genderLabel}</option>)
                )}
              </select>
            )
          },
          {
            Header: "Età",
            id: "birthDate",
            width: 60,
            accessor: 'birthDate',
            filterable: false,
            Cell: row => moment().diff(row.value, "years")
          },
          {
            Header: "Azioni",
            filterable: false,
            width: 105,
            accessor: "actions",
            Cell: row => (
              <div className="actionTableContainer">
                { userCan('customer__update--own-center') && (
                <Tooltip
                  title="Modifica"
                  enterDelay={300}
                  position="bottom-end"
                >
                  <IconButton
                    className="iconButton colorGreen colorWhite"
                    onClick={() => props.goToEdit(row.original.id)}
                  >
                  <UserEditIcon />
                  </IconButton>
                </Tooltip>
                )}
                { userCan('customer__delete--own-center') && (
                <Tooltip title="Elimina" enterDelay={300}>
                  <IconButton
                    className="iconButton colorRed colorWhite"
                    onClick={() => props.openDialog(row.original.id)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                )}
              </div>
            )
          }
        ]}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        defaultPageSize={8}
        previousText={<PaginateButtonPrev />}
        nextText={<PaginateButtonNext />}
        pageText="Pagina"
        ofText="di"
        rowsText="per pagina"
        noDataText="Nessun Corsista Trovato"
      />
    </div>
  );
};
