import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";
import Layout from "../../../hoc/Layout";
import List from "./_list";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import IconBack from "@material-ui/icons/ChevronLeft";
import IconPersonAdd from "@material-ui/icons/PersonAdd";
import { userCan } from "../../../../shared/utility";



const Customers = props => {
  const [dialog, setDialog] = useState({
    open: false,
    id: null
  });

  useEffect(() => {
    props.onGetCustomers();
  }, []);

  useEffect(() => {
    if (props.customer) props.onResetCustomer();
  }, [props.customer]);

  const goToEdit = id => {
    props.history.push(`/cliente/${id}`);
  };

  const openDialog = id => {
    setDialog({
      ...dialog,
      open: true,
      id
    });
  };

  const closeDialog = () => {
    setDialog({
      ...dialog,
      open: false,
      id: null
    });
  };

  const removeCustomer = async () => {
    await props.onDeleteCustomer(dialog.id);
    closeDialog();
  };
  return (
    <Layout>
      <div className="box-layout">
        {!props.loading ? (
          <div className="box-layout__table">
            <div className="topSection">
              <h1>Clienti</h1>
              { userCan('customer__create--own-center') && (
              <button
                size="large"
                className="button button--new"
                onClick={() => props.history.push("/cliente")}
              >
                <IconPersonAdd />
                Aggiungi Cliente
              </button>
              )}
            </div>
            <List
              customers={props.customers}
              openDialog={openDialog}
              goToEdit={goToEdit}
            />
          </div>
        ) : (
          <div className="box-layout box-layout__progress">
            <CircularProgress
              size={80}
              thickness={4}
              className="main-progress"
            />
          </div>
        )}
      </div>
      <Dialog
        open={dialog.open}
        
        keepMounted
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title-delete"
        aria-describedby="alert-dialog-slide-description-delete"
      >
        <DialogTitle
          className="dialogTitle"
          id="alert-dialog-slide-title-delete"
        >
          {"Conferma Azione di eliminazione"}
        </DialogTitle>
        <DialogContent className="dialogContent">
          <DialogContentText id="alert-dialog-slide-description-delete">
            Vuoi davvero eliminare questo cliente?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
            variant="contained"
            className="button button--cancel"
          >
            <IconBack /> Indietro
          </Button>
          <Button
            onClick={removeCustomer}
            variant="contained"
            className="button"  
          >
            Elimina
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};
const mapStateToProps = state => {
  return {
    customers: state.customer.customers,
    customer: state.customer.customer,
    loading: state.customer.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetCustomers: () => dispatch(actions.getCustomers()),
    onDeleteCustomer: id => dispatch(actions.deleteCustomer(id)),
    onResetCustomer: () => dispatch(actions.resetCustomer())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Customers));
