import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";
import Layout from "../../../hoc/Layout";
import List from "./_list";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import IconBack from "@material-ui/icons/ChevronLeft";
import IconSchool from "@material-ui/icons/School";
import { userCan } from "../../../../shared/utility";

const CoursesPage = props => {
  const [dialog, setDialog] = useState({
    open: false,
    id: null
  });

  useEffect(() => {
    props.onGetCourses();
    props.onGetCenters();
  }, []);

  useEffect(() => {
    if (props.course) props.onResetCourse();
  }, [props.course]);

  const goToEdit = id => {
    props.history.push(`/dettaglio-corso/${id}`);
  };

  const goToModify = id => {
    props.history.push(`/corso/${id}`);
  };

  const openDialog = id => {
    setDialog({
      ...dialog,
      open: true,
      id
    });
  };

  const closeDialog = () => {
    setDialog({
      ...dialog,
      open: false,
      id: null
    });
  };

  const removeCourse = async () => {
    await props.onDeleteCourse(dialog.id);
    closeDialog();
  };
  return (
    <Layout>
      <div className="box-layout">
        {!props.loading ? (
          <div className="box-layout__table">
            <div className="topSection">
              <h1>Corsisti</h1>
              { userCan('course__create--own-center') && (
              <button
                size="large"
                className="button button--new"
                onClick={() => props.history.push("/corso")}
              >
                <IconSchool />
                Aggiungi Corsista
              </button>
              )}
            </div>
            <List
              courses={props.courses}
              centers={props.centers}
              openDialog={openDialog}
              goToEdit={goToEdit}
              goToModify={goToModify}
            />
          </div>
        ) : (
          <div className="box-layout box-layout__progress">
            <CircularProgress
              size={80}
              thickness={4}
              className="main-progress"
            />
          </div>
        )}
      </div>
      <Dialog
        open={dialog.open}
        keepMounted
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title-delete"
        aria-describedby="alert-dialog-slide-description-delete"
      >
        <DialogTitle
          className="dialogTitle"
          id="alert-dialog-slide-title-delete"
        >
          {"Conferma Azione di eliminazione"}
        </DialogTitle>
        <DialogContent className="dialogContent">
          <DialogContentText id="alert-dialog-slide-description-delete">
            Vuoi davvero eliminare questo corso?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
            variant="contained"
            className="button button--cancel"
          >
            <IconBack /> Indietro
          </Button>
          <Button
            onClick={removeCourse}
            variant="contained"
            className="button"
          >
            Elimina
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};
const mapStateToProps = state => {
  return {
    courses: state.course.courses,
    centers: state.center.centers,
    course: state.course.course,
    loading: state.course.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetCourses: () => dispatch(actions.getCourses()),
    onGetCenters: () => dispatch(actions.getCenters()),
    onDeleteCourse: id => dispatch(actions.deleteCourse(id)),
    onResetCourse: () => dispatch(actions.resetCourse())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CoursesPage));
