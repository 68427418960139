import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";
import { withRouter } from "react-router-dom";
import Layout from "../../../hoc/Layout";
import List from "./_list";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import IconBack from "@material-ui/icons/ChevronLeft";
import IconPersonAdd from "@material-ui/icons/PersonAdd";
import { userCan } from "../../../../shared/utility";

const Users = props => {
  const [dialog, setDialog] = useState({
    open: false,
    id: null
  });

  useEffect(() => {
    props.onGetUsers();
    props.onGetRoles();
  }, []);

  useEffect(() => {
    if (props.user) props.onResetUser();
  }, [props.user]);

  const openDialog = id => {
    setDialog({
      ...dialog,
      open: true,
      id
    });
  };

  const closeDialog = () => {
    setDialog({
      ...dialog,
      open: false,
      id: null
    });
  };

  const goToEdit = id => {
    props.history.push(`/utente/${id}`);
  };

  const removeUser = async () => {
    await props.onDeleteUser(dialog.id);
    closeDialog();
  };

  return (
    <Layout>
      <div className="box-layout">
        {!props.loading ? (
          <div className="box-layout__table">
            <div className="topSection">
              <h1>Utenti</h1>
              { userCan('users__create') && (
              <button
                size="large"
                className="button button--new"
                onClick={() => props.history.push("/utente")}
              >
                <IconPersonAdd />
                Invita Utente
              </button>
              )}
            </div>
            <List
              users={props.users}
              openDialog={openDialog}
              goToEdit={goToEdit}
              roles={props.roles}
            />
          </div>
        ) : (
          <div className="box-layout box-layout__progress">
            <CircularProgress
              size={80}
              thickness={4}
              className="main-progress"
            />
          </div>
        )}
      </div>
      <Dialog
        open={dialog.open}
        
        keepMounted
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title-delete"
        aria-describedby="alert-dialog-slide-description-delete"
      >
        <DialogTitle
          className="dialogTitle"
          id="alert-dialog-slide-title-delete"
        >
          {"Conferma Azione di eliminazione"}
        </DialogTitle>
        <DialogContent className="dialogContent">
          <DialogContentText id="alert-dialog-slide-description-delete">
            Vuoi davvero eliminare questo utente?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
            variant="contained"
            className="button button--cancel"
          >
            <IconBack /> Indietro
          </Button>
          <Button
            onClick={removeUser}
            variant="contained"
            className="button"
          >
            Elimina
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    users: state.user.users,
    user: state.user.user,
    loading: state.user.loading,
    roles: state.roles.roles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetUsers: () => dispatch(actions.getUsers()),
    onResetUser: () => dispatch(actions.resetUser()),
    onDeleteUser: id => dispatch(actions.deleteUser(id)),
    onGetRoles: () => dispatch(actions.getRoles())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Users));
