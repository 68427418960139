import React from "react";

export const PlusIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path fill="#008cb6" d="M15 11h-4v4H9v-4H5V9h4V5h2v4h4m-5-9a10 10 0 1010 10A10 10 0 0010 0z"/>
    </svg>
);

export const BuildingIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M5 3v18h6v-3.5h2V21h6V3H5m2 2h2v2H7V5m4 0h2v2h-2V5m4 0h2v2h-2V5M7 9h2v2H7V9m4 0h2v2h-2V9m4 0h2v2h-2V9m-8 4h2v2H7v-2m4 0h2v2h-2v-2m4 0h2v2h-2v-2m-8 4h2v2H7v-2m8 0h2v2h-2v-2z"/>
    </svg>
)

export const ColumnSortIcon = (direction) => (
    <div className="svg-container">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5">
            <path d="M0 5l5-5 5 5z" fill="#cbd6e2" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5">
            <path d="M10 0L5 5 0 0z" fill="#cbd6e2" />
        </svg>
    </div>
)

export const UserEditIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.9"
        height="17"
    >
        <path
            fill="#fff"
            d="M17.7 9.3l-1 1-2-2 1-1a.5.5 0 0 1 .7 0l1.3 1.3a.5.5 0 0 1 0 .8M8 14.8l6-6 2.1 2-6 6.1H8v-2m0-5c-4.4 0-8 1.8-8 4v2h6v-1.9l4-4a16.6 16.6 0 0 0-2-.1M8 0a4 4 0 1 0 4 4 4 4 0 0 0-4-4z"
        />
    </svg>
)

export const ProfileIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
    >
        <path
            className="a"
            d="M15,14c-2.67,0-8,1.33-8,4v2H23V18c0-2.67-5.33-4-8-4m0-2a4,4,0,1,0-4-4,4,4,0,0,0,4,4Z"
            transform="translate(-7 -4)"
        />
    </svg>
)