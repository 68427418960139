
import database, { firebase } from "../firebase/firebase";
import Model from './model';

import {
    InvalidCollectionException,
    DocumentNotFoundException
} from './exceptions';

import { uniqBy } from 'lodash';
import ModelCriteria from "./criteria";

export default class FirestoreModel extends Model {

    // Set wich date fields should be converted in-out form database
    dateFields = null;

    /**
      * The collection name in the database
      * 
      * @return {string}
      */
    get collectionName() {
        return '';
    };

    /**
     * Setup the model
     * 
     * @param {object} props 
     */
    constructor(props = {}) {

        super(props);

        if (this.collectionName) {
            this.collection = database.collection(this.collectionName);
        } else {
            throw new InvalidCollectionException();
        }

    }

    /**
     * Check if the user is allowed to perform the action
     * @param {string} action The action to be checked
     * @returns {bool} If the user is allowed
     */
    // allow(action) {
    //     let permission = this.collectionName + '__' + action;
    //     return super.allow( action ) && userCan(permission);
    // }

    /**
     * Create a document reference
     * 
     * @param {string?} docId The document ID to be referenced
     * @returns {DocumentReference} The DB reference for the document
     */
    ref(docId) {
        return docId ? this.collection.doc(docId) : this.collection.doc();
    }

    /**
     * Create a new object in database
     * 
     * @param {object}  props   The properties to save
     * @param {string?} docId   The id of the created document
     * 
     * @return {Promise} The created document id
     */
    async create(props, docId = null) {
        await super.create(props, docId);

        props = this.toDb(this.trim(props));

        if ( docId ) {
            return this.ref(docId)
                .set(props)
                .then(() => docId);
        }

        return this.collection
            .add(props)
            .then((docSnap) => docSnap.id)
    }

    /**
     * Get a document from database
     * 
     * @param {object}  id  The id of the model to get
     * 
     * @return {Promise}    The requested document 
     */
    async get(id) {

        // Check for permissions, etc
        await super.get(id);

        return this.collection
            .doc(id)
            .get()
            .then(
                (docSnap) => {
                    if (!docSnap.exists) {
                        throw new DocumentNotFoundException(id, this.collectionName);
                    }
                    return this.fromDb(docSnap);
                }
            );
    }

    /**
     * Get a document from database by reference
     * 
     * @param {DocuementReference}  ref  The ref of the model to get
     * 
     * @return {Promise}  The requested document 
     */
    async getByRef(ref) {
        return this.get(ref.id);
    }

    /**
     * Create a document query
     * 
     * @param {ModelCriteria} criteria The query criteria
     * @return {firebase.firestore.Query} The created document 
     */
    query(criteria) {

        const { where, orderBy, limit } = criteria.serialize();
        
        let collectionQuery = this.collection;

        collectionQuery = where.reduce(
            (query, condition) => query.where(...condition),
            collectionQuery
        );

        collectionQuery = orderBy.reduce(
            (query, order) => query.orderBy(...order),
            collectionQuery
        );

        if (limit !== null) {
            collectionQuery = collectionQuery.limit(limit);
        };

        return collectionQuery;
    }

    /**
     * List documents
     * 
     * @param {object?} criteria The query object
     * @return {Promise} The created document 
     */
    async list(criteria = {}) {

        if ( !(criteria instanceof ModelCriteria) ) {
            criteria = new ModelCriteria(criteria);
        }

        // Check for permissions, etc
        await super.list(criteria);

        let collectionQuery = this.query(criteria);

        return collectionQuery
            .get()
            .then(
                (docSnaps) =>
                    docSnaps.docs.map(
                        (docSnap) => this.fromDb(docSnap)
                    )
            );
    }

    /**
     * Make multiple DB queries, one for each criteria specified and merge all the results togheter
     * 
     * @param {ModelCriteria[]} criterias The query object
     * @return {Promise} The merged queries in a single result 
     */
    async listTogheter(criterias) {
        return Promise.all( criterias.map( this.list.bind(this) ))
        // Flatten the arrays in a single one
        .then((subQueries) => subQueries.flat());
    }    

    /**
     * Update object in database
     * 
     * @param {object}  id  The id of the model to update
     * @param {object} props The properties to save
     * @return {Promise}
     */
    async update(id, props) {

        await super.update(id, props);

        let doc = this.trim(props);
        doc = this.toDb(doc);

        return this.collection
            .doc(id)
            .update(doc);
    }

    /**
     * Write a list of objects in database
     * 
     * @param {object[]} documents The documents to update 
     * @param {firebase.firestore.SetOptions} options The update options
     * @return {Promise} The batch.commit response
     */
    async writeBatch(documents, options) {

        this.log( 'WRITE BATCH', {documents} );

        const batch = this.createBatch();

        documents.forEach(doc => {
            batch.set( this.ref(doc.id), this.toDb(this.trim(doc)), options );
        });

        return batch.commit();
    }

    /**
     * Delete object in database
     * 
     * @param {string} id The object id to delete
     * @return {Promise}
     */
    async delete(id) {

        await super.delete(id);

        return this.collection
            .doc(id)
            .delete();
    }

    /**
     * Perform a pseudo-fulltext search on specified attributes
     * 
     * @param {string}      search      The text to search against
     * @param {string[]}    attributes  The attributes to search in
     * @return {Promise}    A promise resolving to the results
     */
    async search(search, attributes, criteria = {}) {

        let queries = [];

        if ( !(criteria instanceof ModelCriteria) ) {
            criteria = new ModelCriteria(criteria);
        }        

        attributes.forEach(attribute => {

            let lowerCase = criteria.clone();
            lowerCase.where(attribute, ">=", search.toLowerCase());
            lowerCase.where(attribute, "<=", search.toLowerCase() + "\uf8ff");
            
            queries.push(lowerCase);

            let upperCase = criteria.clone();
            upperCase.where(attribute, ">=", search.charAt(0).toUpperCase() + search.slice(1));
            upperCase.where(attribute, "<=", search.charAt(0).toUpperCase() + search.slice(1) + "\uf8ff");

            queries.push(upperCase);

        })

        return Promise
            .all(queries.map(query => this.list(query)))
            .then(itemGroups => uniqBy(itemGroups.flat(1), item => item.id))
    }


    /**
     * Create or update the object
     * 
     * @param {object}  props   The document props to write
     * @param {string?} id      The id of the document, if present it will be an update
     * 
     * @return {string} The document id that has been create or updated
     */
    async sync(syncedDocs) {

        this.log( 'SYNC', {syncedDocs} );

        syncedDocs = syncedDocs || [];

        let currentDocs = await this.list();
        let syncedDocsIds = syncedDocs.map( doc => doc.id );
        
        let deletedDocs = currentDocs.filter( doc => !syncedDocsIds.includes(doc.id) );

        await Promise.all( deletedDocs.map( deletedDoc => this.delete(deletedDoc.id) ) );

        return Promise.all( syncedDocs.map( syncedDoc => this.write(syncedDoc, syncedDoc.id) ) );
    }  

    /**
     * Creates a database batch instance
     * @see https://firebase.google.com/docs/reference/js/firebase.firestore.WriteBatch
     * 
     * @return {WriteBatch} A WriteBatch instance. Use the `commit()` method to commit the batch
     */
    createBatch() {
        return database.batch();
    }

    /**
     * Decode a database result in plain JS object
     * 
     * @param {DocumentSnapshot} docSnap The db document snapshot
     * @return {object} The normalized object
     */
    fromDb(docSnap) {

        let result = Object.assign(docSnap.data(), { id: docSnap.id });
        result = this.datesDecode(result);

        return result;
    }

    /**
     * Prepare a document for write in DB
     * 
     * @param {object} doc The document to write
     * 
     * @return {object} The fromDbd objetc
     */
    toDb(doc) {
        let result = this.datesEncode(doc);

        return result;
    }

    /** 
    * Encode date props to Firestore Timestamp class
    * 
    * @param  {object}  document    The template string
    * 
    * @return {object} The object with encoded dates
    */
    datesEncode(document) {

        let dateFields = this.dateFields;

        if (!dateFields) {
            dateFields = Object.keys(document);
        }

        dateFields.forEach(prop => {
            (document[prop] && typeof document[prop].toDate === 'function') && (document[prop] = document[prop].toDate());
        });

        return document;
    }

    /** 
    * Decode date props from Firestore to Date object
    * 
    * @param  {object}  document    The template string
    * 
    * @return {object} The object with decoded dates
    */
    datesDecode(document) {

        let dateFields = this.dateFields;

        if (!dateFields) {
            dateFields = Object.keys(document);
        }

        dateFields.forEach(prop => {
            (document[prop] && document[prop] instanceof firebase.firestore.Timestamp) && (document[prop] = document[prop].toDate());
        });

        return document;
    }


    /**
     * Model logger middleware
     * 
     * @param {string}   method  The document object
     * @param {object?}  context The document object
     * @param {string?}  message The document object
     * 
     * @return {void} 
     */
    log(method, context = null, message = '') {

        context.collectionPath = this.collection.path;

        super.log( method, context, message );
    }

}