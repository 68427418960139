import axios from "axios";
import { put } from "redux-saga/effects";

import * as actions from "../../actions/index";

export function* getEmailTemplate() {
  yield put(actions.getEmailTemplateStart());
  let response;
  try {
    yield axios({
      method: "GET",
      url: "https://api.sendinblue.com/v3/smtp/templates",
      headers: {
        "api-key":
          "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",
        "Content-Type": "application/json"
      }
    }).then(data => (response = data));
    yield put(actions.getEmailTemplateSuccess(response.data.templates));
  } catch {
    yield put(actions.getEmailTemplateFail());
  }
}
