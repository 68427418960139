import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions/index";
import logo from "../../../../../assets/png/dysway-logo-colori.png";
import { firebase, googleAuthProvider } from "../../../../../firebase/firebase";

import Footer from "../../../../shared/footer/Footer";
import User from "../../../../../models/user";
import errorHandler from "../../../../../shared/errorReporting";

function VerifyAccount (props) {

  const token = props.match.params.token;
  const email = props.match.params.email;

  const [validToken, setValidToken] = useState(true);

  const onActivate = async () => {

    const response = await firebase.auth().signInWithPopup(googleAuthProvider);

    User.model().get( token )
      .then((userData) => User.model().create( Object.assign(userData, {token, status: 'activated'}), response.user.id ) )
      .then((newUserId) => User.model().delete(token) )
      .then(() => {
        setValidToken(false);
        window.location.href = "/corsi";
      })
      .catch((error) => { 
        setValidToken(false);
        errorHandler.report(error);
      });
  };

  return (
    <div className="box-layout box-layout--login">
      <div className="login__page-content">
        <div className="box-layout__box">
          <h3 className="box-layout__title">
            <img src={logo} alt="dysway-logo" className="box-layout__logo" />
          </h3>
          <p>
            Clicca sul pulsante qui sotto e seleziona l'account Google o G
            Suite corrispondente alla mail <b>{email}</b> per attivare
            il tuo account DysWay.
          </p>
          <button onClick={onActivate} className="colorWhite">Attiva il tuo Account</button>
          { !validToken && (<p>Il codice non è valido oppure è scaduto</p>) }
        </div>
      </div>
      <Footer />
    </div>
  );
  
}
const mapStateToProps = state => {
  return {
    errorMessage: state.auth.error,
    loading: state.auth.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogin: () => dispatch(actions.authUser())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VerifyAccount));
