import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
//table
import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from "moment";

import matchSorter from "match-sorter";
import BallsContainer from "./balls-container";
import { PaginateButtonNext, PaginateButtonPrev } from "../../../../shared/table/pagination";
import { userCan } from "../../../../../shared/utility";

export default props => {
  return (
    <div className="react-table">
      <ReactTable
        data={props.courses}
        columns={[
          {
            Header: "Inizio",
            id: "startDateTime",
            filterable: false,
            accessor: 'startDateTime',
            width: 145,
            Cell: row => row.value ? moment(row.value).format("DD-MM-YYYY") : ''
          },
          {
            Header: "Fine",
            id: "endDate",
            filterable: false,
            accessor: 'endDate',
            width: 145,
            Cell: row => row.value ? moment(row.value).format("DD-MM-YYYY") : ''
          },
          {
            Header: "Familiare Referente",
            id: "contactPersonName",
            width: 200,
            accessor: '_contactPersonName',
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["contactPersonName"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra per..."
                className="table-filter"
              />
            )
          },
          {
            Header: "Corsista",
            id: "customerName",
            width: 200,
            accessor: '_customerName',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["customerName"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra per..."
                className="table-filter"
              />
            ),
          },
          {
            Header: "Corso",
            id: "courseName",
            width: 180,
            accessor: '_courseName',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: ["courseName"]
              }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra per..."
                className="table-filter"
              />
            ),
          },
          {
            Header: "Fasi",
            id: "groups",
            filterable: false,
            width: 950,
            accessor: 'groups',
            Cell: row => {
              return (
                <BallsContainer
                  groups={row.value}
                  courseId={row.original.id}
                />
              );
            }
          },
          {
            Header: "Azioni",
            filterable: false,
            accessor: "actions",
            width: 145,
            Cell: row => (
              <div className="actionTableContainer">
                { ( userCan('course__get--own-center') ) && (
                <Tooltip title="Apri" enterDelay={300} position="bottom-end">
                  <IconButton
                    className="iconButton colorGreen colorWhite"
                    onClick={() => props.goToEdit(row.original.id)}
                    size="small"
                  >
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                )}
              </div>
            )
          }
        ]}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        defaultPageSize={8}
        // className="-striped -highlight"
        previousText={<PaginateButtonPrev />}
        nextText={<PaginateButtonNext />}
        pageText="Pagina"
        ofText="di"
        rowsText="per pagina"
        noDataText="Nessun Corso Trovato"
      />
      <br />
    </div>
  );
};
