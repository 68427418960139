import React, { useState } from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
//import CommentIcon from "@material-ui/icons/Comment";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from "@material-ui/icons/Email";
import EditIcon from '@material-ui/icons/Edit';

import { sendWhatsappMessage, userCan } from "../../../../../../shared/utility";
import "react-table/react-table.css";

import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import "moment/locale/it";

import Select from "../../../../../shared/select";

import Course from "../../../../../../models/course";
import Task from "../../../../../../models/task";
import User from "../../../../../../models/user";

const taskTypesMap = new Map(Task.TASK_TYPES);

const TaskOwner = (props) => {

    const { task, users, onUpdate } = props;

    const [isEditing, setIsEditing] = useState(false);

    let user = task.owner ? users.find(user => user.id === task.owner.id) : null;

    const edit = () => {
        setIsEditing(true);
    }

    const close = () => {
        setIsEditing(false);
    }

    const save = (event) => {
        onUpdate(event.target.value);
        setIsEditing(false);
    }

    return (
        <div className="task__owner task__field task__field--editable" onBlur={close} >
            <span className="task__label">Responsabile:</span>
            {isEditing && (
                <Select
                    value={task.owner && task.owner.id}
                    onChange={save}
                    onMenuClose={close}
                    onBlur={close}
                    TextFieldProps={
                        {
                            variant: 'standard',
                            size: 'small',
                            margin: 'dense'
                        }
                    }

                    options={users.map(user => ({ label: `${user.name} ${user.surname}`, value: user.id }))}
                />
            )}
            {!isEditing && (
                <span className="task__value" >{user ? `${user.name} ${user.surname}` : ''} <EditIcon fontSize="small" color="action"  onClick={edit}/></span>
            )}
        </div>
    );
}

const TaskStatus = (props) => {

    const { task, onUpdate } = props;

    const [isEditing, setIsEditing] = useState(false);

    let status = task.statuses.find(status => status.id === task.status);

    if (!status) {
        return 'N/A';
    }

    const edit = () => {
        setIsEditing(true);
    }

    const close = () => {
        setIsEditing(false);
    }

    const save = (event) => {
        onUpdate(event.target.value);
        setIsEditing(false);
    }

    return (
        <div className="task__status">
            <div className="status task__field task__field--editable">
                <span className="status__color" style={{ backgroundColor: status.color }} />
                {isEditing && (
                    <Select
                        value={task.status}
                        onChange={save}
                        isClearable={false}
                        isSearchable={false}
                        onMenuClose={close}
                        TextFieldProps={
                            {
                                variant: 'standard',
                                size: 'small',
                                margin: 'dense'
                            }
                        }

                        options={task.statuses ? task.statuses.map((status) => ({ label: status.name, value: status.id })) : []}
                    />
                )}
                {!isEditing && (
                    <span className="status__name" >{status ? status.name : 'N/A'} <EditIcon fontSize="small" color="action" onClick={edit}/></span>
                )}
            </div>
        </div>
    );
}

const TaskDueDate = (props) => {

    const { task, onUpdate } = props;

    const [isEditing, setIsEditing] = useState(false);

    const edit = () => {
        setIsEditing(true);
    }

    const close = () => {
        setIsEditing(false);
    }

    const save = (dueDateTime) => {
        onUpdate(dueDateTime);
        setIsEditing(false);
    }

    return (
        <div className="task__due-date">
            <span className="task__label">Scadenza:</span>
            <span className="task__value">
                {isEditing && (
                    <DateTimePicker
                        value={task.dueDateTime ? task.dueDateTime : null}
                        onChange={save}
                        onClose={close}
                        format="DD/MM/YYYY HH:mm"
                        emptyLabel="Clicca per inserire..."
                        ampm={false}
                        clearable
                    />
                )}
                {!isEditing && (
                    <span className="datetime">
                        {task.dueDateTime ? moment(task.dueDateTime).format( "DD-MM-YYYY HH:mm") : ''}
                        <EditIcon fontSize="small" color="action" onClick={edit} />
                    </span>
                )}

            </span>
        </div>
    );
}


const createDescription = function (value) {
    return { __html: value };
};

const CourseTaskPreview = React.memo((props) => {

    const { task, users, onUpdateTask, goToDetails, openDialog, course } = props;

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale="it">
            <div className="task" >
            <div className="task__actions">
                    {userCan('course__task__get--own-center') && (
                        <Tooltip title="Apri" enterDelay={300} position="bottom-end">
                            <IconButton
                                className="iconButton colorGreen colorWhite"
                                onClick={() => goToDetails(task.id)}
                            >
                                <VisibilityIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                    {userCan('course__task__update--own-center') && (task.type === Task.TYPE_MESSAGE) && (task.status !== task.finalStatus) && (
                        <Tooltip
                            title="Invia Whatsapp"
                            enterDelay={300}
                            position="bottom-end"
                        >
                            <IconButton
                                className="iconButton colorGreen colorWhite"
                                onClick={() => sendWhatsappMessage(
                                    task,
                                    Course.model().ref(course.id)
                                )
                                }
                            >
                                <WhatsAppIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                    {userCan('course__task__update--own-center') && (task.type === Task.TYPE_EMAIL) && (task.status !== task.finalStatus) && (
                        <Tooltip
                            title="Invia Email"
                            enterDelay={300}
                            position="bottom-end"
                        >
                            <IconButton
                                className="iconButton colorGreen colorWhite"
                                onClick={() => openDialog(task.id)}
                            >
                                <EmailIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>                
                <div className="task__name">
                    <span className="task__label task__label--hidden">Attività:</span>
                    <span className="task__value">{task.name}</span>
                </div>
                <div className="task__type">
                    <span className="task__label">Tipo:</span>
                    <span className="task__value">{taskTypesMap.get(task.type)}</span>
                </div>
                <TaskDueDate task={task} onUpdate={(dueDateTime) => onUpdateTask(task.id, { dueDateTime })} />
                <TaskStatus task={task} onUpdate={(status) => onUpdateTask(task.id, { status })} />
                <TaskOwner task={task} users={users} onUpdate={(owner) => onUpdateTask(task.id, { owner: owner ? User.model().ref(owner) : '' })} />
                <div className="task__notes" dangerouslySetInnerHTML={createDescription(task.notes)} ></div>
                
            </div>
        </MuiPickersUtilsProvider>
    );
});


export default CourseTaskPreview;