import FirestoreModel from '../../firestore';
import * as yup from 'yup';
import { MissingParentException } from '../../exceptions';
// import Course from '.';
// import User from '../user';
// import Center from '../center';
// import Customer from '../customer';
// import { parseTemplate } from '../../shared/utility'

export default class CourseTask extends FirestoreModel {

    dateFields = [
        "dueDateTime"
    ];

    /**
     * Setup the model
     * 
     * @param {object} props 
     */
    constructor(props) {
        super(props);

        const { courseId } = this.props;

        if ( !courseId ) {
            throw new MissingParentException(courseId);
        }

    }

    /**
     * The collection name in the database
     */
    get collectionName() {
        return `course/${this.props.courseId}/tasks`;
    };

    /**
     * The collection name in the database
     */
    get validationSchema() {
        return yup.object().shape({
            status: yup.number().label('Stato'),
            dueDateTime: yup.date().typeError('Inserire una data').label('Data Scadenza'),
            attachments: yup.array().label('Allegati'),
            notes: yup.string().label('Note'),
            owner: yup.string().label('Assegnatario'),
            googleCalendarEvent: yup.string().label('Evento Google Calendar')
        });
    };

    // async do(taskId) {

    //     const task = await this.get(taskId);

    //     let result;

    //     switch (task.type) {
    //         case 'email':
    //             result = this.sendEmail(task);
    //             break;
    //         case 'message':
    //             result = this.sendWhatsApp(task);
    //             break;
    //         case 'meeting':
    //         case 'manual':
    //         default:
    //             result = this.complete(task);
    //             break;
    //     }

    //     return result;
    // }

    // prepareSendParams(task) {

    //     return Course.model()
    //         .get(this.courseId)
    //         .then((course) => {
    //             return Promise.all([
    //                 Promise.resolve(course),
    //                 Customer(course.customer.id).get(),
    //                 User(course.manager.id).get(),
    //                 Center(course.center.id).get(),
    //             ]);
    //         })
    //         .then(snapshots => {

    //             const [
    //                 course,
    //                 customer,
    //                 manager,
    //                 center
    //             ] = snapshots.map(snap => snap.data());

    //             const params = {
    //                 customer,
    //                 manager,
    //                 center,
    //                 course,
    //                 task
    //             };

    //             try {
    //                 [
    //                     'welcomeDate',
    //                     'onboardingDate',
    //                     'startDate',
    //                     'paymentDate',
    //                     'coachingDate',
    //                     'endDate'
    //                 ].forEach((prop) => params.course[prop] = (new Date(params.course[prop])).toLocaleDateString('it-IT'));
    //             } catch (error) {
    //                 console.error('ERROR IN FORMATTING DATES', error);
    //             }

    //             var now = new Date();
    //             var hours = now.getHours();
    //             params.dayPeriod = hours > 12 ? 'serata' : 'giornata';
    //             params.dayPeriodShort = hours > 12 ? 'sera' : 'giorno';

    //             const courseStart = new Date(course.startDate);
    //             var timeDiff = courseStart.getTime() - now.getTime();
    //             params.course.startDays = Math.floor(timeDiff / (1000 * 3600 * 24));

    //             return params;
    //         })

    // }

    // sendWhatsApp(task) {

    //     this.prepareSendParams(task).then((params) => {

    //         let waPhone = '';

    //         if (params.customer.contactPerson.phone) {
    //             waPhone = '39' + params.customer.contactPerson.phone.replace(/\D/g, '');
    //         }

    //         const content = parseTemplate(task.templateSms, params);

    //         window.open((`https://wa.me/${waPhone}?text=`) + encodeURIComponent(content), "_blank");

    //     })

    // }

    // async complete(taskId) {

    //     return this
    //         .get(taskId)
    //         .then(
    //             task => this.update(taskId, { status: task.finalStatus })
    //         )
    // }


}
