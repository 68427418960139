import React from "react";

//Material Import
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconUpload from "@material-ui/icons/CloudUpload";
import IconEdit from "@material-ui/icons/Edit";

const initialState = {
  label: "",
  file: null,
  name: ''  
};

const MaterialsForm = props => {
  const [doc, setDoc] = React.useState(initialState);
  const [mod, setMod] = React.useState(false);
  const [prevDoc, setPrevDoc] = React.useState(initialState);

  const handleDoc = ({ target }) => {
    setDoc({ ...doc, [target.name]: target.value });
  };

  const handleFile = ({ target }) => {

    // Object type FileList
    const file = target.files[0];

    if (file) {
      setDoc({
        ...doc,
        name: file.name,
        size: file.size,
        type: file.type,
        blob: file,        
      });
    }
  };

  React.useEffect(() => {
    if (props.modifyDoc) {
      setDoc(props.modifyDoc);
      setPrevDoc(props.modifyDoc);
      setMod(true);
    }
  }, [props.modifyDoc]);

  React.useEffect(() => {
    props.validateForm(doc, prevDoc);
  }, [doc]);

  const addDocument = () => {
    props.addDocument(doc);
    setDoc(initialState);
  };

  const modifyDocument = () => {
    props.modify(doc);
    setDoc(initialState);
    setMod(false);
  };

  return (
    <>
      <Typography component="h3" variant="subtitle1">
        {!mod ? "Aggiungi Nuovo Materiale" : "Modifica Materiale"}
      </Typography>
      <TextField
        variant="outlined"
        autoComplete="off"
        label="Titolo del documento"
        type="text"
        name="label"
        onChange={handleDoc}
        value={doc.label}
        fullWidth
      />
      <br />
      <br />
      <TextField
        variant="outlined"
        autoComplete="off"
        label="Nome del file"
        type="text"
        name="name"
        onChange={handleDoc}
        value={doc.name}
        fullWidth
        disabled
      />
      <div className="buttonsActivityContainer">
        <input
          id="material-button-file"
          type="file"
          name="doc"
          className="none"
          onChange={handleFile}
        />
        <Grid container spacing={3} className="tab-form__submit">
          <Grid item>
            <Button
              variant="contained"
              className="button button--outlined"
              style={{marginTop: "0px"}}
            >
              <label htmlFor="material-button-file">
                <IconUpload /> Scegli il file
              </label>                              
            </Button>
          </Grid>
          <Grid item>
            {mod === true ? (
              <Button
                variant="contained"
                onClick={() => modifyDocument(doc)}
                className="button"
                style={{marginTop: "0px"}}
              >
                <IconEdit /> Modifica
              </Button>
            ) : (
              <Button
                variant="contained"
                component="span"
                onClick={() => addDocument(doc)}
                className="button"
              >
                Aggiungi file
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default MaterialsForm;
