import React, { useState, useEffect } from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions/index";
import arrayMove from "array-move";

import Layout from "../../../../hoc/Layout";
import ActivityTabs from "../../../../shared/tabs/activityTabs/activityTabs";

import { Button } from "@material-ui/core";
import IconSave from "@material-ui/icons/Save";
import IconBack from "@material-ui/icons/ChevronLeft";
import IconAssignment from "@material-ui/icons/Assignment";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { lastId } from "../../../../../shared/utility";
import TaskAttachment from "../../../../../models/task/attachment";

const initialState = {
  name: "",
  documents: [],
  statuses: [
    {
      name: "Da fare",
      id: 1,
      color: "#7BA3AA"
    },
    {
      name: "Fatto",
      id: 2,
      color: "#8DC100"
    }
  ],
  type: "",
  inCalendar: false,
  duration: "",
  description: "",
  templateEmail: "",
  templateSms: ""
};



const Activity = props => {
  const [activity, setActivity] = useState(initialState);
  const [prevActivity, setPrevActivity] = useState(initialState);
  const [canView, setCanView] = useState(undefined);
  const [prevCanView, setPrevCanView] = useState(initialState);

  const [dialog, setDialog] = useState({
    open: false
  });

  useEffect(() => {
    props.onGetEmailTemplate();
    props.onGetRoles();
  }, []);

  useEffect(() => {
    if (!canView && props.roles.length > 0) {
      let c = {};
      props.roles.forEach(element => {
        c[element.id] = false;
      });
      setCanView(c);
    }
  }, [props.roles]);

  useEffect(() => {
    return function cleanup() {
      props.onResetActivity();
      setActivity(initialState);
    };
  }, []);

  useEffect(() => {
    if (props.saveActivitySuccess) props.history.push("/tasks");
  }, [props.saveActivitySuccess]);

  useEffect(() => {
    if (props.match.params.id && !props.activity)
      props.onGetActivity(props.match.params.id);
    if (props.activity) {
      setActivity({ ...props.activity });
      setCanView({ ...props.activity.canView });
      setPrevActivity({ ...props.activity });
      setPrevCanView({ ...props.activity.canView });
    }
  }, [props.activity]);

  const handleChange = ({ target }) => {
    setActivity({
      ...activity,
      [target.name]: target.value
    });
  };

  const handleCheck = ({ target }) => {
    setActivity({
      ...activity,
      [target.name]: target.checked
    });
  };

  const handleCanView = ({ target }) => {
    setCanView({
      ...canView,
      [target.name]: target.checked
    });
  };

  const handleQuill = value => {
    setActivity({
      ...activity,
      description: value
    });
  };

  const addDocument = file => {
    let documents = activity.documents;
    let doc = {
      id: lastId(documents) + 1,
      ...file
    };
    documents.push(doc);
    setActivity({
      ...activity,
      documents
    });
  };

  const modifyDocument = doc => {
    let documents = activity.documents;
    let index = _.findIndex(documents, function (o) {
      return o.id === doc.id;
    });

    if (~index) {
      documents[index] = doc;
    }
    setActivity({ ...activity, documents });
  };

  const removeDocument = name => {
    
    let documents = activity.documents;
    let removeId = documents.map(el => el.name).indexOf(name);
    let doc = documents.find(el => el.name === name);
    
    if ( props.match.params.id && doc.id ) {
      TaskAttachment.model({ taskId: props.match.params.id }).delete(doc);
    }    

    documents.splice(removeId, 1);
    setActivity({ ...activity, documents });
  };

  const addStatus = status => {
    let statuses = activity.statuses;

    statuses.push({
      id: lastId(statuses) + 1,
      ...status
    });

    setActivity({
      ...activity,
      statuses
    });
  };

  const modifyStatus = status => {
    let array = activity.statuses;
    let index = _.findIndex(array, function (o) {
      return o.id === status.id;
    });

    if (~index) {
      array[index] = status;
    }
    setActivity({ ...activity, statuses: array });
  };

  const removeStatus = (index, id) => {
    // Return the array without the removed status
    let statuses = activity.statuses.filter(status => status.id !== id);

    setActivity({ ...activity, statuses });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let items = activity.statuses;
    setActivity(() => ({
      ...activity,
      statuses: arrayMove(items, oldIndex, newIndex)
    }));
  };

  const saveActivity = async () => {
    await props.onSaveActivity({ ...activity, canView });
    let documents = [];
    let statuses = [];
    setActivity({
      ...initialState,
      documents: documents,
      statuses: statuses
    });
  };

  const canGoBack = () => {
    if (
      !_.isEqual(activity, prevActivity) ||
      !_.isEqual(canView, prevCanView) ||
      validateForm()
    )
      openDialog();
    else props.history.push("/tasks");
  };

  const openDialog = () => {
    setDialog({
      ...dialog,
      open: true
    });
  };

  const closeDialog = () => {
    setDialog({
      ...dialog,
      open: false
    });
  };

  const validateForm = (state, prevState) => {
    if (_.isEqual(state, prevState)) return true;
    return false;
  };

  return (
    <Layout>
      <div className="box-layout">
        <div className="topSection">
          <h1>
            <IconAssignment />
            {props.match.params.id ? "Modifica" : "Aggiungi"} Attività
          </h1>
          <div className="topButtons">
            <Button className="button button--outlined" onClick={canGoBack}>
              <IconBack/> Indietro
            </Button>
            <Button
              className="button button--confirm"
              onClick={saveActivity}>
              <IconSave />
              Salva
            </Button>
          </div>
        </div>
        <div className="box-layout__box">
          <ActivityTabs
            activity={activity}
            email_template={props.email_template}
            handleChange={handleChange}
            handleCheck={handleCheck}
            handleCanView={handleCanView}
            handleQuill={handleQuill}
            onSortEnd={onSortEnd}
            addDocument={addDocument}
            modifyDocument={modifyDocument}
            removeDocument={removeDocument}
            addStatus={addStatus}
            modifyStatus={modifyStatus}
            removeStatus={removeStatus}
            validateForm={validateForm}
            canView={canView}
          />
        </div>
      </div>
      <Dialog
        open={dialog.open}
        
        keepMounted
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title-delete"
        aria-describedby="alert-dialog-slide-description-delete"
      >
        <DialogTitle
          className="dialogTitle"
          id="alert-dialog-slide-title-delete"
        >
          {"Sei sicuro di voler uscire?"}
        </DialogTitle>
        <DialogContent className="dialogContent">
          <DialogContentText id="alert-dialog-slide-description-delete">
            Attenzione! Stai lasciando il form con alcuni elementi non ancora
            salvati! Desideri davvero uscire?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => props.history.push("/tasks")}
            color="primary"
            variant="contained"
            className="button button--cancel"
          >
            Esci
          </Button>
          <Button
            onClick={closeDialog}
            color="primary"
            variant="contained"
            className="button"
          >
            Rimani
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    activity: state.activity.activity,
    email_template: state.utility.email_template,
    activityMessage: state.activity.message,
    saveActivitySuccess: state.activity.save_activity_success,
    roles: state.roles.roles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetActivity: id => dispatch(actions.getActivity(id)),
    onGetEmailTemplate: () => dispatch(actions.getEmailTemplate()),
    onSaveActivity: activity => dispatch(actions.saveActivity(activity)),
    onResetActivity: () => dispatch(actions.resetActivity()),
    onGetRoles: () => dispatch(actions.getRoles())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Activity));
