import React from "react";
import { ColumnSortIcon } from '../icons';

export const TableColumnHead = (props) => (
  <div className="table-header">
    <span>{ props.label }</span>
    <ColumnSortIcon />
  </div>
)

export const TableColumnFilterText = ({ filter, onChange }) => (
    <input
        onChange={event => onChange(event.target.value)}
        value={filter && filter.value}
        placeholder="Filtra..."
        className="table-filter"
    />
)