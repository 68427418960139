import React, { useState } from "react";

import IconBack from "@material-ui/icons/ChevronLeft";

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from "@material-ui/core";

export function confirmDialog(props) {

    const {
        message = 'Confermi di voler eseguire l\'operazione',
        title = 'Conferma Azione',
        confirmButton = 'Conferma',
        cancelButton = 'Annulla'
    } = props;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-confirm-title"
            aria-describedby="alert-dialog-confirm-message"
        >
            <DialogTitle
                className="dialogTitle"
                id="alert-dialog-confirm-title"
            >
                {title}
            </DialogTitle>
            <DialogContent className="dialogContent">
                <DialogContentText id="alert-dialog-confirm-message">
                    {message}
                    {props.children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained" className="button button--cancel">
                    <IconBack /> {cancelButton}
                </Button>
                <Button 
                    onClick={() => { 
                        props.onClose();
                        return props.onConfirm(props.state) 
                    }} 
                    color="primary" 
                    variant="contained"
                    className="button"
                >
                    {confirmButton}
                </Button>
            </DialogActions>
    </Dialog>
    );
}

export function deleteConfirmDialog(props) {

    const {
        message = 'Attenzione! Non hai salvato dopo aver effettuato le modifiche! Desideri davvero uscire?',
        title = 'Conferma Azione di eliminazione',
    } = props;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title-delete"
            aria-describedby="alert-dialog-slide-description-delete"
        >
            <DialogTitle
                className="dialogTitle"
                id="alert-dialog-slide-title-delete"
            >
                {title}
            </DialogTitle>
            <DialogContent className="dialogContent">
                <DialogContentText id="alert-dialog-slide-description-delete">
                    {message}
                    {props.children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="contained" className="button button--cancel">
                    <IconBack /> Indietro
                </Button>
                <Button 
                    onClick={() => { 
                        props.onClose();
                        return props.onConfirm(props.state) 
                    }} 
                    variant="contained"
                    className="button"
                >
                    Elimina
                </Button>
            </DialogActions>
    </Dialog>
    );
}

export function unsavedDialog(props) {

    const {
        message = 'Attenzione! Non hai ancora salvato le tue modifiche! Desideri davvero uscire?',
        title = 'Sei sicuro di voler uscire?',
        backMessage = 'Esci',
    } = props;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title-delete"
            aria-describedby="alert-dialog-slide-description-delete"
        >
            <DialogTitle
                className="dialogTitle"
                id="alert-dialog-slide-title-delete"
            >
                {title}
            </DialogTitle>
            <DialogContent className="dialogContent">
                <DialogContentText id="alert-dialog-slide-description-delete">
                {message} 
                {props.children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        props.onClose();
                        return props.onConfirm(props.state)
                    }}
                    className="button button--cancel"
                    >
                    {backMessage}
                </Button>
                <Button onClick={props.onClose} className="button">
                    Rimani
                </Button>
            </DialogActions>
        </Dialog>
    )
}

//This is where it start to became fancy
export function useDialog(DialogComponent, dialogInitialState) {

    const [dialogState, setDialogState] = useState({
        isOpen: false,
        ...dialogInitialState
    });
  
    const openDialog = state => {
        setDialogState({
            ...dialogState,
            ...state,
            isOpen: true,
        });
    };
  
    const closeDialog = () => {
        setDialogState({
            ...dialogInitialState,
            isOpen: false,
        });
    };
  
    const component = (props) => (
        <DialogComponent
            isOpen={dialogState.isOpen}
            onClose={closeDialog}
            state={dialogState}
            {...props}
        />
    ) 
  
    return [component, openDialog, closeDialog, dialogState];
  }