// errorHandlerUtility.js

import StackdriverErrorReporter from 'stackdriver-errors-js';

const environment = process.env.NODE_ENV;

let errorHandler;

if (environment === 'production') {

  errorHandler = new StackdriverErrorReporter();
  errorHandler.start({
    key: 'AIzaSyCYrrHtEY0IsFHzHc7wT_NNCUDfl8lvCG4',
    projectId: 'dysway-ar',
    service: 'gestionale',              // (optional)
    version: '1.0'       // (optional)
  });

} else {
  errorHandler = {report: (error, ...other) => console.error(error, error.code, ...other) };
}

export default errorHandler;