import React, { useState, useEffect } from "react";

import {
  Grid,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
  Typography
} from "@material-ui/core";
import { Formik, Form, Field, FieldArray } from "formik";

import Select, { makeOptions } from "../select";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/it";

import Notes from "./notes";

import Customer from "../../../models/customer";
import Center from "../../../models/center";
import errorHandler from "../../../shared/errorReporting";
import { userCan } from "../../../shared/utility";

const disorders = Customer.DISORDERS;
const genders = makeOptions(Customer.GENDERS);

const CustomerForm = props => {

  const [centers, setCenters] = useState([]);
  const { bindSubmit } = props;

  //Load values for some selects
  useEffect(() => {
    Center.model().list().then(setCenters).catch(errorHandler.report);
  }, []);

  return (
    <Formik
      enableReinitialize
      validationSchema={Customer.model().validationSchema}
      {...props}
    >{({ values, errors, touched, submitForm }) => {

      if (bindSubmit) {
        // bind the submission handler remotely
        bindSubmit(submitForm);
      }

      return (
        <Form>
          <FormControl component="fieldset" fullWidth className="fields-group">
            <Typography component="legend" className="form-maxWidth">Dati anagrafici</Typography>
            <Grid container spacing={3} className="form-maxWidth">
              <Grid item xs={12} sm={6} md={6}>
                <Field
                  name="name"
                  children={({ field, form }) => {
                    return (
                      <TextField
                        id="name"
                        value={field.value}
                        name="name"
                        onChange={e => {
                          form.setFieldValue(field.name, e.target.value);
                        }}
                        variant="outlined"
                        autoComplete="off"
                        label="Nome"
                        type="text"
                        fullWidth
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name && `${errors.name}`}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Field
                  name="surname"
                  children={({ field, form }) => {
                    return (
                      <TextField
                        id="surname"
                        value={field.value}
                        name="surname"
                        onChange={e => {
                          form.setFieldValue(field.name, e.target.value);
                        }}
                        variant="outlined"
                        autoComplete="off"
                        label="Cognome"
                        type="text"
                        fullWidth
                        error={Boolean(touched.surname && errors.surname)}
                        helperText={
                          touched.surname && errors.surname && `${errors.surname}`
                        }
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Field
                  name="gender"
                  children={({ field, form }) => {
                    return (
                      <Select
                        value={field.value}
                        onChange={e => {
                          form.setFieldValue(field.name, e.target.value);
                        }}
                        id="gender"
                        label="Sesso"
                        error={Boolean(touched.gender && errors.gender)}
                        helperText={
                          touched.gender && errors.gender && `${errors.gender}`
                        }
                        options={genders}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid className="datePicker-container" item xs={12} sm={12} md={6}>
                <Field
                  name="birthDate"
                  children={({ field, form }) => {
                    return (
                      <MuiPickersUtilsProvider utils={MomentUtils} locale="it">
                        <FormControl variant="outlined" fullWidth required>
                          <KeyboardDatePicker
                            placeholder="gg/mm/aaaa"
                            name="birthDate"
                            id="birthDate"
                            onChange={date => {
                              form.setFieldValue(field.name, date ? date.toDate() : null);
                            }}
                            value={field.value ? field.value : null}
                            format="DD/MM/YYYY"
                            emptyLabel="Clicca per inserire una data.."
                            inputVariant="outlined"
                            disableFuture={true}
                            disablePast={false}
                            label="Data di nascita"
                            error={Boolean(touched.birthDate && errors.birthDate)}
                            helperText={
                              touched.birthDate && errors.birthDate && `${errors.birthDate}`
                            }
                            required
                            clearable
                          />
                        </FormControl>
                      </MuiPickersUtilsProvider>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Field
                  name="fiscalCode"
                  children={({ field, form }) => {
                    return (
                      <TextField
                        id="fiscalCode"
                        value={field.value}
                        name="fiscalCode"
                        onChange={e => {
                          form.setFieldValue(field.name, e.target.value);
                        }}
                        variant="outlined"
                        autoComplete="off"
                        label="Codice Fiscale"
                        type="text"
                        fullWidth
                        error={Boolean(touched.fiscalCode && errors.fiscalCode)}
                        helperText={
                          touched.fiscalCode &&
                          errors.fiscalCode &&
                          `${errors.fiscalCode}`
                        }
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Field
                  name="schoolClass"
                  children={({ field, form }) => {
                    return (
                      <Select
                        value={field.value}
                        onChange={e => {
                          form.setFieldValue(field.name, e.target.value);
                        }}
                        id="schoolClass"
                        label="Classe Frequentata"
                        error={Boolean(touched.schoolClass && errors.schoolClass)}
                        helperText={
                          touched.schoolClass &&
                          errors.schoolClass &&
                          `${errors.schoolClass}`
                        }
                        options={makeOptions(Customer.SCHOOL_CLASSES)}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </FormControl>
          <FormControl component="fieldset" fullWidth className="fields-group">
            <Typography component="legend" className="form-maxWidth">Scheda cliente</Typography>
            <Grid container spacing={3} className="form-maxWidth">
              <Grid item xs={12} sm={6} md={6}>
                <Field
                  name="center"
                  children={({ field, form }) => {
                    return (
                      <FormControl variant="outlined" fullWidth required>
                        <Select
                          label="Centro *"
                          name="center"
                          id="center"
                          value={field.value}
                          onChange={e => {
                            form.setFieldValue(field.name, e.target.value);
                          }}
                          options={centers.map(doc => ({
                            label: doc.name,
                            value: doc.id
                          }))}
                          error={Boolean(touched.center && errors.center)}
                          helperText={
                            touched.center && errors.center && `${errors.center}`
                          }
                          isDisabled={ !userCan('customer__update') }
                        />
                      </FormControl>
                    );
                  }}
                />
              </Grid>    
            </Grid>        
            <Grid container spacing={3} className="form-maxWidth">
              <Grid item xs={12} sm={12} md={12}>
                <Field
                  name="dsaCertificate"
                  children={({ field, form }) => {
                    return (
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="txtLeft">
                          Ha la certificazione?
                      </FormLabel>
                        <RadioGroup
                          aria-label="Gender"
                          name="dsaCertificate"
                          value={field.value}
                          className="radioGroup"
                          onChange={e => {
                            form.setFieldValue(field.name, e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Si"
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    );
                  }}
                />
              </Grid>
              <Grid className="datePicker-container" item xs={12} sm={6} md={6}>
                <Field
                  name="dsaDate"
                  children={({ field, form }) => {
                    return (
                      <MuiPickersUtilsProvider utils={MomentUtils} locale="it">
                        <FormControl variant="outlined" fullWidth>
                          <KeyboardDatePicker
                            placeholder="gg/mm/aaaa"
                            name="dsaDate"
                            id="dsaDate"
                            onChange={date => {
                              form.setFieldValue(field.name, date ? date.toDate() : null);
                            }}
                            value={field.value ? field.value : null}
                            format="DD/MM/YYYY"
                            emptyLabel="Clicca per inserire una data.."
                            inputVariant="outlined"
                            disableFuture={true}
                            disablePast={false}
                            label="Data di certificazione"
                            error={Boolean(touched.dsaDate && errors.dsaDate)}
                            helperText={touched.dsaDate && errors.dsaDate && `${errors.dsaDate}`}
                            required
                            clearable
                          />
                        </FormControl>
                      </MuiPickersUtilsProvider>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Field
                  name="pro"
                  children={({ field, form }) => {
                    return (
                      <Select
                        value={field.value}
                        onChange={e => {
                          form.setFieldValue(field.name, e.target.value);
                        }}
                        id="pro"
                        label="Da chi è seguito?"
                        error={Boolean(touched.pro && errors.pro)}
                        helperText={touched.pro && errors.pro && `${errors.pro}`}
                        options={makeOptions(Customer.SPECIALISTS)}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormLabel component="h3" style={{ marginBottom: "15px" }}>
                  Quale difficoltà ha?
                </FormLabel>
                <FormGroup row >
                  <ul className="disorders-list">
                  <FieldArray name="disorders" children={
                    ({ insert, remove }) => disorders.map(
                      ([disorderId, disorderName], dIndex) => (
                        <li className="disorder-list__checkbox" key={dIndex} style={ {listStyle: 'none'} }>
                        <Field
                          name={`disorders.${disorderId}`}
                          key={disorderId}
                        >
                          {({ field }) => {

                            // is -1 if not found
                            let itemIndex = values.disorders.findIndex(dis => dis === disorderId);

                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    key={disorderId + field.value}
                                    checked={itemIndex >= 0}
                                    color="primary"
                                    onChange={e => {
                                      itemIndex >= 0 ? remove(itemIndex) : insert(dIndex, disorderId)
                                    }}
                                    name={field.name}
                                  />
                                }
                                label={disorderName}
                              />
                            )
                          }
                          }
                        </Field>
                        </li>
                      )
                    )
                  }
                  />
                  </ul>
                  {errors.disorders && (<p className="errors" style={{ color: 'red' }}>{errors.disorders}</p>)}
                </FormGroup>
              </Grid>
            </Grid>
          </FormControl>
          <FormControl component="fieldset" fullWidth className="fields-group">
            <Typography component="legend" className="form-maxWidth">Note</Typography>
            <Grid container spacing={3} className="form-maxWidth" >
              <Grid item xs={12} sm={12} md={12}>
                <Notes />
              </Grid>
            </Grid>
          </FormControl>
        </Form>
      )
    }}
    </Formik>
  );
};

export default CustomerForm;
