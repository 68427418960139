import { takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../types/types";

import { authUser, authUserCheckState, authUserlogout } from "./auth";
import { getUsers, getUser, saveUser, deleteUser } from "./user";

import { getCenters, getCenter, saveCenter, deleteCenter } from "./center";
import {
  getActivityGroup,
  getActivity,
  saveActivity,
  deleteActivity
} from "./activity";
import {
  getCustomers,
  getCustomer,
  saveCustomer,
  deleteCustomer
} from "./customer";

import {
  getCourseTemplates,
  getCourseTemplate,
  saveCourseTemplate,
  deleteCourseTemplate,
  cloneCourse
} from "./course_template";
import {
  getCourses,
  getCoursesRecap,
  getCourse,
  saveCourse,
  deleteCourse,
  getCourseSpec,
  saveCourseSpec,
  sendQueue,
  saveCourseTaskUpdate
} from "./course";
import { getEmailTemplate } from "./utility";
import { getRoles } from "./roles";

export function* watchPublicAuth() {
  yield all([
    takeEvery(actionTypes.AUTH_USER, authUser),
    takeEvery(actionTypes.AUTH_USER_CHECK_STATE, authUserCheckState),
    takeEvery(actionTypes.AUTH_USER_LOGOUT, authUserlogout)
  ]);
}

export function* watchUser() {
  yield all([
    takeEvery(actionTypes.GET_USERS, getUsers),
    takeEvery(actionTypes.GET_USER, getUser),
    takeEvery(actionTypes.SAVE_USER, saveUser),
    takeEvery(actionTypes.DELETE_USER, deleteUser)
  ]);
}

export function* watchCenter() {
  yield all([
    takeEvery(actionTypes.GET_CENTERS, getCenters),
    takeEvery(actionTypes.GET_CENTER, getCenter),
    takeEvery(actionTypes.SAVE_CENTER, saveCenter),
    takeEvery(actionTypes.DELETE_CENTER, deleteCenter)
  ]);
}

export function* watchActivity() {
  yield all([
    takeEvery(actionTypes.GET_ACTIVITY_GROUP, getActivityGroup),
    takeEvery(actionTypes.GET_ACTIVITY, getActivity),
    takeEvery(actionTypes.SAVE_ACTIVITY, saveActivity),
    takeEvery(actionTypes.DELETE_ACTIVITY, deleteActivity)
  ]);
}

export function* watchUtility() {
  yield all([takeEvery(actionTypes.GET_EMAIL_TEMPLATE, getEmailTemplate)]);
}

export function* watchCustomer() {
  yield all([
    takeEvery(actionTypes.GET_CUSTOMERS, getCustomers),
    takeEvery(actionTypes.GET_CUSTOMER, getCustomer),
    takeEvery(actionTypes.SAVE_CUSTOMER, saveCustomer),
    takeEvery(actionTypes.DELETE_CUSTOMER, deleteCustomer)
  ]);
}

export function* watchCourseTemplate() {
  yield all([
    takeEvery(actionTypes.GET_COURSE_TEMPLATES, getCourseTemplates),
    takeEvery(actionTypes.GET_COURSE_TEMPLATE, getCourseTemplate),
    takeEvery(actionTypes.SAVE_COURSE_TEMPLATE, saveCourseTemplate),
    takeEvery(actionTypes.DELETE_COURSE_TEMPLATE, deleteCourseTemplate)
  ]);
}

export function* watchCourse() {
  yield all([
    takeEvery(actionTypes.GET_COURSES, getCourses),
    takeEvery(actionTypes.GET_COURSES_RECAP, getCoursesRecap),
    takeEvery(actionTypes.GET_COURSE, getCourse),
    takeEvery(actionTypes.GET_COURSE_SPEC, getCourseSpec),
    takeEvery(actionTypes.SAVE_COURSE_SPEC, saveCourseSpec),
    takeEvery(actionTypes.SEND_QUEUE, sendQueue),
    takeEvery(actionTypes.SAVE_COURSE, saveCourse),
    takeEvery(actionTypes.DELETE_COURSE, deleteCourse),
    takeEvery(actionTypes.CLONE_COURSE, cloneCourse),
    takeEvery(actionTypes.COURSE_TASK_UPDATE, saveCourseTaskUpdate)
  ]);
}

export function* watchRoles() {
  yield all([takeEvery(actionTypes.GET_ROLES, getRoles)]);
}
