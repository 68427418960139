import { put } from "redux-saga/effects";
import * as actions from "../../actions/index";
import Task from "../../../models/task";
import errorHandler from "../../../shared/errorReporting";
import TaskAttachment from "../../../models/task/attachment";
import { omit } from "lodash";

export function* getActivityGroup() {
  yield put(actions.getActivityGroupStart());
  try {
    const tasks = yield Task.model().list({ orderBy: [["name", "asc"]] });

    yield put(actions.getActivityGroupSuccess(tasks));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.getActivityGroupFail(error));
  }
}

export function* getActivity(action) {
  yield put(actions.getActivityStart());

  let taskId = action.payload.id;

  try {
    let task = yield Task.model().get(taskId);
    yield put(actions.getActivitySuccess(task));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.getActivityFail(error));
  }
}

export function* saveActivity(action) {
  yield put(actions.saveActivityStart());

  const task = action.payload.activity;

  if (task.type !== Task.TYPE_EMAIL) {
    task.templateEmail = "";
  }

  if (task.type !== Task.TYPE_MESSAGE) {
    task.templateSms = "";
  }

  // Calculate default and final status
  let statuses = task.statuses.slice(0);
  task.status = task.defaultStatus = statuses.shift().id;
  task.finalStatus = statuses.pop().id;

  // Create the Task ID
  try {
    let taskId = yield Task.model().write(omit(task, "documents"), task.id);

    // Upload new documents
    let uploadPromises = [];

    let documents = task.documents.map(attachment => {
      let TaskAttachmentModel = TaskAttachment.model({ taskId });

      // @TODO find a better way to detect new file attachment
      if (attachment.blob) {
        uploadPromises.push(TaskAttachmentModel.create(attachment));
      }

      attachment.path = TaskAttachmentModel.path(attachment);

      // Strip all unwanted props (file, id, etc)
      return TaskAttachmentModel.trim(attachment);
    });

    // Wait for all uploads
    yield Promise.all(uploadPromises);

    // Create/Update
    yield Task.model().update(taskId, { documents });

    yield put(actions.saveActivitySuccess(task));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.saveActivityFail(error));
  }
}

export function* deleteActivity(action) {
  yield put(actions.deleteActivityStart());

  let taskId = action.payload.id;

  try {
    yield Task.model().delete(taskId);
    yield put(actions.deleteActivitySuccess(taskId));
  } catch (error) {
    yield put(actions.deleteActivityFail(error));
  }
}
