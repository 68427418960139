import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";

import PrivateHeader from "../shared/headers/HeaderPrivate";
import SideDrawer from "../shared/sidedrawer/sidedrawer";
import SnackBar from "../shared/SnackBar/SnackBar";
import Footer from "../shared/footer/Footer";

const Layout = props => {
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const [open, setOpen] = useState(false);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    if (props.userMessage) props.onResetUserMessage();
    if (props.centerMessage) props.onResetCenterMessage();
    if (props.activityMessage) props.onResetActivityMessage();
    if (props.templateMessage) props.onResetCourseTemplateMessage();
    if (props.customerMessage) props.onResetCustomerMessage();
    if (props.courseMessage) props.onResetCourseMessage();
    if (props.sendMessage) props.onResetCourseMessage();
    setOpen(false);
  }

  const sideDrawerClosedHandler = () => {
    setShowSideDrawer(false);
  };

  const sideDrawerToggleHandler = () => {
    setShowSideDrawer(!showSideDrawer);
  };

  const logout = () => {
    props.onLogout();
  };

  useEffect(() => {
    if (props.userMessage && open === false) setOpen(true);
  }, [props.userMessage]);

  useEffect(() => {
    if (props.centerMessage && open === false) setOpen(true);
  }, [props.centerMessage]);

  useEffect(() => {
    if (props.activityMessage && open === false) setOpen(true);
  }, [props.activityMessage]);

  useEffect(() => {
    if (props.templateMessage && open === false) setOpen(true);
  }, [props.templateMessage]);

  useEffect(() => {
    if (props.customerMessage && open === false) setOpen(true);
  }, [props.customerMessage]);

  useEffect(() => {
    if (props.courseMessage && open === false) setOpen(true);
  }, [props.courseMessage]);

  useEffect(() => {
    if (props.sendMessage && open === false) setOpen(true);
  }, [props.sendMessage]);

  if (props.user)
    return (
      <>
        <PrivateHeader
          logout={logout}
          drawerToggleClicked={sideDrawerToggleHandler}
        />
        <SideDrawer
          open={showSideDrawer}
          closed={sideDrawerClosedHandler}
          logout={logout}
          user={props.user}
        />
        {props.children}
        {props.userMessage && (
          <SnackBar
            handleClose={handleClose}
            open={open}
            message={props.userMessage}
            error={props.errorUser}
          />
        )}
        {props.centerMessage && (
          <SnackBar
            handleClose={handleClose}
            open={open}
            message={props.centerMessage}
            error={props.errorCenter}
          />
        )}
        {props.activityMessage && (
          <SnackBar
            handleClose={handleClose}
            open={open}
            message={props.activityMessage}
            error={props.errorActivity}
          />
        )}
        {props.templateMessage && (
          <SnackBar
            handleClose={handleClose}
            open={open}
            message={props.templateMessage}
            error={props.errorTemplate}
          />
        )}
        {props.customerMessage && (
          <SnackBar
            handleClose={handleClose}
            open={open}
            message={props.customerMessage}
            error={props.errorCustomer}
          />
        )}
        {props.courseMessage && (
          <SnackBar
            handleClose={handleClose}
            open={open}
            message={props.courseMessage}
            error={props.errorCourse}
          />
        )}
        {props.sendMessage && (
          <SnackBar
            handleClose={handleClose}
            open={open}
            message={props.sendMessage}
            error={props.errorCourse}
          />
        )}
        <Footer />
      </>
    );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  userMessage: state.user.message,
  centerMessage: state.center.message,
  activityMessage: state.activity.message,
  templateMessage: state.course_template.message,
  customerMessage: state.customer.message,
  courseMessage: state.course.message,
  sendMessage: state.course.sendMessage,
  errorUser: state.user.error,
  errorActivity: state.activity.error,
  errorCenter: state.activity.error,
  errorCourse: state.course.error,
  errorTemplate: state.course_template.error,
  errorCustomer: state.customer.error
});
const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(actions.authUserLogout()),
    onResetUserMessage: () => dispatch(actions.resetUserMessage()),
    onResetCenterMessage: () => dispatch(actions.resetCenterMessage()),
    onResetActivityMessage: () => dispatch(actions.resetActivityMessage()),
    onResetCourseTemplateMessage: () =>
      dispatch(actions.resetCourseTemplateMessage()),
    onResetCustomerMessage: () => dispatch(actions.resetCustomerMessage()),
    onResetCourseMessage: () => dispatch(actions.resetCourseMessage())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Layout));
