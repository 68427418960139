import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

var config = {
  apiKey: "AIzaSyCYrrHtEY0IsFHzHc7wT_NNCUDfl8lvCG4",
  authDomain: "dysway-ar.firebaseapp.com",
  databaseURL: "https://dysway-ar.firebaseio.com",
  projectId: "dysway-ar",
  storageBucket: "dysway-ar.appspot.com",
  messagingSenderId: "634781539702",
  clientId: "634781539702-pgg2oh5n406fsfpq3nglbbosh2sskdij.apps.googleusercontent.com",
  scopes: [
           "email",
           "profile",
           "https://www.googleapis.com/auth/calendar"
  ],
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
};

firebase.initializeApp(config);

const database = firebase.firestore();
database.enablePersistence();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
googleAuthProvider.addScope('https://www.googleapis.com/auth/calendar');

const storage = firebase.storage().ref();

export { 
  config,
  firebase, 
  googleAuthProvider, 
  database as default, 
  storage
 };
