import './shared/errorReporting';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import Routing from "./routing/AppRouter";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";


/* Public Reducers */
import publicAuthReducer from "./store/reducers/auth";
import userReducer from "./store/reducers/user";
import centerReducer from "./store/reducers/center";
import activityReducer from "./store/reducers/activity";
import utilityReducer from "./store/reducers/utility";
import customerReducer from "./store/reducers/customer";
import courseTemplateReducer from "./store/reducers/course_template";
import courseReducer from "./store/reducers/course";
import rolesReducer from "./store/reducers/roles";

import {
  watchPublicAuth,
  watchUser,
  watchCenter,
  watchActivity,
  watchUtility,
  watchCustomer,
  watchCourseTemplate,
  watchCourse,
  watchRoles
} from "./store/sagas";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

//reducers
const rootReducer = combineReducers({
  auth: publicAuthReducer,
  user: userReducer,
  center: centerReducer,
  activity: activityReducer,
  utility: utilityReducer,
  customer: customerReducer,
  course_template: courseTemplateReducer,
  course: courseReducer,
  roles: rolesReducer
});

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, sagaMiddleware))
);

sagaMiddleware.run(watchPublicAuth);
sagaMiddleware.run(watchUser);
sagaMiddleware.run(watchCenter);
sagaMiddleware.run(watchActivity);
sagaMiddleware.run(watchUtility);
sagaMiddleware.run(watchCustomer);
sagaMiddleware.run(watchCourseTemplate);
sagaMiddleware.run(watchCourse);
sagaMiddleware.run(watchRoles);

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Noto Sans"'].join(","),
    //htmlFontSize: 13,
    subtitle2: {
      color: "#fff",
      "margin-right": "1rem"
    }
  },
  palette: {
    primary: {
      main: "#0081A8"
    },
    secondary: {
      main: "#005468"
    }
  }
});

const app = (
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </Provider>
  </MuiThemeProvider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
