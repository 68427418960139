import { put } from "redux-saga/effects";
import * as actions from "../../actions/index";
import Center from "../../../models/center";
import errorHandler from "../../../shared/errorReporting";
import User from "../../../models/user";

export function* getCenters() {
  yield put(actions.getCentersStart());

  try {
    const centers = yield Center.model().list();
    yield put(actions.getCentersSuccess(centers));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.getCentersFail(error));
  }
}

export function* getCenter(action) {
  yield put(actions.getCenterStart());

  try {
    const center = yield Center.model().get(action.payload.id);

    center.coachUsers = center.coachUsers.map(userRef => userRef.id);
    center.accountingUsers = center.accountingUsers.map(userRef => userRef.id);

    yield put(actions.getCenterSuccess(center));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.getCenterFail(error));
  }
}

export function* saveCenter(action) {
  yield put(actions.saveCenterStart());

  let center = { ...action.payload.center };
  let centerId = center.id;

  if (center.manager) {
    center.manager = User.model().ref(center.manager);
  }

  center.coachUsers = center.coachUsers.map(userId => User.model().ref(userId));
  center.accountingUsers = center.accountingUsers.map(userId =>
    User.model().ref(userId)
  );

  try {
    if (centerId) {
      yield Center.model().update(centerId, center);
    } else {
      centerId = yield Center.model().create(center);
    }

    yield put(actions.saveCenterSuccess(center));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.saveCenterFail(error));
  }
}

export function* deleteCenter(action) {
  yield put(actions.deleteCenterStart());

  try {
    yield Center.model().delete(action.payload.id);
    yield put(actions.deleteCenterSuccess(action.payload.id));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.deleteCenterFail(error));
  }
}
