import * as actionTypes from "../../types/types";

export const getCustomers = () => {
  return {
    type: actionTypes.GET_CUSTOMERS
  };
};
export const getCustomersStart = () => {
  return {
    type: actionTypes.GET_CUSTOMERS_START
  };
};
export const getCustomersSuccess = customers => {
  return {
    type: actionTypes.GET_CUSTOMERS_SUCCESS,
    payload: { customers }
  };
};
export const getCustomersFail = error => {
  return {
    type: actionTypes.GET_CUSTOMERS_FAIL,
    payload: { error }
  };
};

export const getCustomer = id => {
  return {
    type: actionTypes.GET_CUSTOMER,
    payload: { id }
  };
};
export const getCustomerStart = () => {
  return {
    type: actionTypes.GET_CUSTOMER_START
  };
};
export const getCustomerSuccess = customer => {
  return {
    type: actionTypes.GET_CUSTOMER_SUCCESS,
    payload: { customer }
  };
};
export const getCustomerFail = error => {
  return {
    type: actionTypes.GET_CUSTOMER_FAIL,
    payload: { error }
  };
};
export const saveCustomer = customer => {
  return {
    type: actionTypes.SAVE_CUSTOMER,
    payload: { customer }
  };
};
export const saveCustomerStart = () => {
  return {
    type: actionTypes.SAVE_CUSTOMER_START
  };
};
export const saveCustomerSuccess = () => {
  return {
    type: actionTypes.SAVE_CUSTOMER_SUCCESS
  };
};
export const saveCustomerFail = error => {
  return {
    type: actionTypes.SAVE_CUSTOMER_FAIL,
    payload: { error }
  };
};
export const deleteCustomer = id => {
  return {
    type: actionTypes.DELETE_CUSTOMER,
    payload: { id }
  };
};
export const deleteCustomerStart = () => {
  return {
    type: actionTypes.DELETE_CUSTOMER_START
  };
};
export const deleteCustomerSuccess = id => {
  return {
    type: actionTypes.DELETE_CUSTOMER_SUCCESS,
    payload: { id }
  };
};
export const deleteCustomerFail = error => {
  return {
    type: actionTypes.DELETE_CUSTOMER_FAIL,
    payload: { error }
  };
};
export const resetCustomer = () => {
  return {
    type: actionTypes.RESET_CUSTOMER
  };
};
export const resetCustomerMessage = () => {
  return {
    type: actionTypes.RESET_CUSTOMER_MESSAGE
  };
};
