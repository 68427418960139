import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Labels from "./Items/Labels";

const SortableItem = SortableElement(
  ({ value, removeStatus, modifyId, index }) => (
    <Labels
      label={value}
      removeStatus={removeStatus}
      modifyId={modifyId}
      index={index}
    />
  )
);

const SortableList = SortableContainer(({ items, removeStatus, modifyId }) => {
  return (
    <ul>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          value={value}
          removeStatus={removeStatus}
          modifyId={modifyId}
        />
      ))}
    </ul>
  );
});

const SortableBasic = props => {
  return (
    <SortableList
      items={props.data}
      onSortEnd={props.onSortEnd}
      removeStatus={props.removeStatus}
      modifyId={props.modifyId}
      useDragHandle
    />
  );
};

export default SortableBasic;
