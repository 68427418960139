import React from 'react';


export const PaginateButtonPrev = () =>
    <div className="button_pagination">
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12">
            <path d="M7 10.59L2.673 6 7 1.41 5.668 0 0 6l5.668 6z" fill="#008cb6" />
        </svg>
        <span className="span-left">Precedente</span>
    </div>

export const PaginateButtonNext = () =>
    <div className="button_pagination">
        <span className="span-right">Successivo</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12">
            <path d="M0 1.41L4.327 6 0 10.59 1.332 12 7 6 1.332 0z" fill="#008cb6" />
        </svg>
    </div>




