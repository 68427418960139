import { updateObject } from "../../../shared/utility";
import * as actionTypes from "../../types/types";

const initialState = {
  course_templates: [],
  course_template: null,
  clone_course: null,
  save_course_template_success: false,
  loading: false,
  message: null,
  error: null
};

export const getCourseTemplatesStart = (state, action) => {
  return updateObject(state, { loading: true });
};
export const getCourseTemplatesSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    course_templates: action.payload.course_templates
  });
};
export const getCourseTemplatesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};
export const getCourseTemplateStart = (state, action) => {
  return updateObject(state, { loading: true });
};
export const getCourseTemplateSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    course_template: action.payload.course_template
  });
};
export const getCourseTemplateFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};
export const saveCourseTemplateStart = (state, action) => {
  return updateObject(state, { loading: true, message: null });
};
export const saveCourseTemplateSuccess = (state, action) => {
  let modify_course_templates = { ...state }.course_templates;
  if (action.payload.course_template.id) {
    let modiyId = modify_course_templates
      .map(el => el.id)
      .indexOf(action.payload.course_template.id);
    modify_course_templates[modiyId] = action.payload.course_template;
  } else {
    modify_course_templates.push(action.payload.course_template);
  }
  return updateObject(state, {
    loading: false,
    message: "Modello Corso salvato con successo",
    course_templates: modify_course_templates,
    save_course_template_success: true,
    course_template: null
  });
};
export const saveCourseTemplateFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore nel salvataggio. Riprovare.",
    error: action.payload.error
  });
};
export const deleteCourseTemplateStart = (state, action) => {
  return updateObject(state, { loading: false, message: null });
};
export const deleteCourseTemplateSuccess = (state, action) => {
  let remove_course_templates = { ...state }.course_templates;
  let removeId = remove_course_templates
    .map(el => el.id)
    .indexOf(action.payload.id);
  remove_course_templates.splice(removeId, 1);
  return updateObject(state, {
    loading: false,
    message: "Modello Corso eliminato con successo",
    course_templates: remove_course_templates
  });
};
export const deleteCourseTemplateFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};
export const resetCourseTemplate = (state, action) => {
  return updateObject(state, {
    course_template: null,
    save_course_template_success: false
  });
};
export const resetCourseTemplateMessage = (state, action) => {
  return updateObject(state, { message: null });
};
export const cloneCourseStart = (state, action) => {
  return updateObject(state, { loading: false, message: null });
};
export const cloneCourseSuccess = (state, action) => {
  let clone_courses = { ...state }.course_templates;
  clone_courses.push(action.payload.clone_course);
  return updateObject(state, {
    loading: false,
    message: "Corso clonato con successo",
    course_templates: clone_courses
  });
};
export const cloneCourseFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COURSE_TEMPLATES_START:
      return getCourseTemplatesStart(state, action);
    case actionTypes.GET_COURSE_TEMPLATES_SUCCESS:
      return getCourseTemplatesSuccess(state, action);
    case actionTypes.GET_COURSE_TEMPLATES_FAIL:
      return getCourseTemplatesFail(state, action);
    case actionTypes.GET_COURSE_TEMPLATE_START:
      return getCourseTemplateStart(state, action);
    case actionTypes.GET_COURSE_TEMPLATE_SUCCESS:
      return getCourseTemplateSuccess(state, action);
    case actionTypes.GET_COURSE_TEMPLATE_FAIL:
      return getCourseTemplateFail(state, action);
    case actionTypes.SAVE_COURSE_TEMPLATE_START:
      return saveCourseTemplateStart(state, action);
    case actionTypes.SAVE_COURSE_TEMPLATE_SUCCESS:
      return saveCourseTemplateSuccess(state, action);
    case actionTypes.SAVE_COURSE_TEMPLATE_FAIL:
      return saveCourseTemplateFail(state, action);
    case actionTypes.DELETE_COURSE_TEMPLATE_START:
      return deleteCourseTemplateStart(state, action);
    case actionTypes.DELETE_COURSE_TEMPLATE_SUCCESS:
      return deleteCourseTemplateSuccess(state, action);
    case actionTypes.DELETE_COURSE_TEMPLATE_FAIL:
      return deleteCourseTemplateFail(state, action);
    case actionTypes.RESET_COURSE_TEMPLATE:
      return resetCourseTemplate(state, action);
    case actionTypes.RESET_COURSE_TEMPLATE_MESSAGE:
      return resetCourseTemplateMessage(state, action);
    case actionTypes.CLONE_COURSE_START:
      return cloneCourseStart(state, action);
    case actionTypes.CLONE_COURSE_SUCCESS:
      return cloneCourseSuccess(state, action);
    case actionTypes.CLONE_COURSE_FAIL:
      return cloneCourseFail(state, action);
    default:
      return state;
  }
};

export default reducer;
