import * as yup from 'yup';
import { userCan } from '../../shared/utility';
import ModelCriteria from '../criteria';
import CurrentUser from '../user/current';
import FirestorePersistentModel, { STATUS_ACTIVE, STATUS_DELETED } from '../firestore-persistent';

class Course extends FirestorePersistentModel {

    // Set wich date fields should be converted from database
    dateFields = [
        'welcomeDateTime', 
        'coachingDateTime',
        'startDateTime',
        'endDate',
        'onboardingDateTime',
        'purchaseDate'
    ];

    static get defaults(){
        return {
            customer: "",
            center: "",
            status: "",
            statusPayment: "",
            template: "",
            manager: "",
            folderURL: "",
            startDateTime: null,
            endDate: null,
            purchaseDate: new Date(),
            welcomeDateTime: null,
            onboardingDateTime: null,
            coachingDateTime: null
        };
    }

    /**
     * The collection name in the database
     */
    get collectionName() {
        return 'course';
    };

    /**
     * The collection name in the database
     */
    get validationSchema() {
        return yup.object().shape({
            customer:       yup.string().label('Corsista').required("Corsista obbligatorio"),
            welcomeDateTime:yup.date().nullable().label('Data e orario primo incontro').typeError('Inserire una data'),
            manager:        yup.string().label('Responsabile').required("Responsabile obbligatorio"),
            center:         yup.string().label('Centro').required("Centro obbligatorio"),
            template:       yup.string().label('Tipologia').required("Tipologia obbligatoria"),
            coachingDateTime:yup.date().nullable().typeError('Inserire una data').label('Data e ora Coaching'),
            startDateTime:  yup.date().nullable().typeError('Inserire una data').label("Data e ora inizio corso"),
            endDate:        yup.date().typeError('Inserire una data').label('Data e ora fine corso'),
            folderURL:      yup.string().label('URL Cartella Google Drive'),
            onboardingDateTime: yup.date().nullable().typeError('Inserire una data').label('Data e ora Spiegazione AR'),
            purchaseDate:   yup.date().required().typeError('Inserire una data').label('Data di Acquisto'),
            statusPayment:  yup.string().label('Stato Pagamento'),
            status:         yup.string().required().label('Stato')
        });
    };

    /**
     * List documents
     * 
     * @param {object?} criterie The query object
     * @return {Promise} The created document 
     */
    async list(criteria = {}) {
        
        if ( !(criteria instanceof ModelCriteria) ) {
            criteria = new ModelCriteria(criteria);
        }

        // @TODO find another way to prevent recursion
        if ( !this.disableRbac && !userCan('course__list' ) && userCan('course__list--own-center') ) {

            // Make the query filterd for each center assigned to the current user
            return CurrentUser.model()
                    .centers()
                    .then( myCenters => {
                        this.disableRbac = true;
                        return myCenters.map(
                            (center) => 
                                criteria.clone().where('center', '==', center)
                        )
                    })                    
                    .then(this.listTogheter.bind(this))
                    .then((results) => {
                        this.disableRbac = false;
                        return results;
                    })

        } 
        
        return super.list(criteria);
    }

}

Course.STATUS_ONGOING = STATUS_ACTIVE;
Course.STATUS_PENDING = 'pending';
Course.STATUS_COMPLETED = 'completed';
Course.STATUS_DELETED = STATUS_DELETED;

Course.COURSE_STATUSES = [
    [Course.STATUS_ONGOING, 'In corso'],
    [Course.STATUS_PENDING, 'In Attesa'],
    [Course.STATUS_COMPLETED, 'Completato'],
    [Course.STATUS_DELETED, 'Eliminato']
];

Course.PAYMENT_PENDING = 'pending';
Course.PAYMENT_ONGOING = 'onGoing';
Course.PAYMENT_COMPLETED = 'completed';

Course.PAYMENT_STATUSES = [
    [ Course.PAYMENT_PENDING, 'In Attesa' ],
    [ Course.PAYMENT_ONGOING, 'In Corso' ],
    [ Course.PAYMENT_COMPLETED, 'Completato' ]
];

export default Course;