import * as actionTypes from "../../types/types";

export const getActivityGroup = () => {
  return {
    type: actionTypes.GET_ACTIVITY_GROUP
  };
};
export const getActivityGroupStart = () => {
  return {
    type: actionTypes.GET_ACTIVITY_GROUP_START
  };
};
export const getActivityGroupSuccess = activity_group => {
  return {
    type: actionTypes.GET_ACTIVITY_GROUP_SUCCESS,
    payload: { activity_group }
  };
};
export const getActivityGroupFail = error => {
  return {
    type: actionTypes.GET_ACTIVITY_GROUP_FAIL,
    payload: { error }
  };
};

export const getActivity = id => {
  return {
    type: actionTypes.GET_ACTIVITY,
    payload: { id }
  };
};
export const getActivityStart = () => {
  return {
    type: actionTypes.GET_ACTIVITY_START
  };
};
export const getActivitySuccess = activity => {
  return {
    type: actionTypes.GET_ACTIVITY_SUCCESS,
    payload: { activity }
  };
};
export const getActivityFail = error => {
  return {
    type: actionTypes.GET_ACTIVITY_FAIL,
    payload: { error }
  };
};
export const saveActivity = activity => {
  return {
    type: actionTypes.SAVE_ACTIVITY,
    payload: { activity }
  };
};
export const saveActivityStart = () => {
  return {
    type: actionTypes.SAVE_ACTIVITY_START
  };
};
export const saveActivitySuccess = activity => {
  return {
    type: actionTypes.SAVE_ACTIVITY_SUCCESS,
    payload: { activity }
  };
};
export const saveActivityFail = error => {
  return {
    type: actionTypes.SAVE_ACTIVITY_FAIL,
    payload: { error }
  };
};
export const deleteActivity = id => {
  return {
    type: actionTypes.DELETE_ACTIVITY,
    payload: { id }
  };
};
export const deleteActivityStart = () => {
  return {
    type: actionTypes.DELETE_ACTIVITY_START
  };
};
export const deleteActivitySuccess = id => {
  return {
    type: actionTypes.DELETE_ACTIVITY_SUCCESS,
    payload: { id }
  };
};
export const deleteActivityFail = error => {
  return {
    type: actionTypes.DELETE_ACTIVITY_FAIL,
    payload: { error }
  };
};
export const resetActivity = () => {
  return {
    type: actionTypes.RESET_ACTIVITY
  };
};

export const resetActivityMessage = () => {
  return {
    type: actionTypes.RESET_ACTIVITY_MESSAGE
  };
};
