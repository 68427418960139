import React from "react";

import Grid from "@material-ui/core/Grid";
import LabelsForm from "./LabelsForm/LabelsForm";
import Sortable from "../../../../Sortable/SortableBasic";

const Labels = props => {
  const [modifyStatus, setModifyStatus] = React.useState(null);

  const ModifyId = id => {
    let status = props.statuses.find(el => el.id === id);
    setModifyStatus(status);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={7}>
        <Sortable
          data={props.statuses}
          onSortEnd={props.onSortEnd}
          removeStatus={props.removeStatus}
          modifyId={ModifyId}
        />
        <p className="hint">
          Ricorda che la prima etichetta viene assegnata di default all'attività inserita. L'ultima etichetta viene invece assegnata quando l'attività è conclusa.</p>
      </Grid>
      <Grid item xs={12} md={1} />
      <Grid item xs={12} md={4}>
        <LabelsForm
          addStatus={props.addStatus}
          modifyStatus={modifyStatus}
          modify={props.modifyStatus}
          validateForm={props.validateForm}
        />
      </Grid>
    </Grid>
  );
};

export default Labels;
