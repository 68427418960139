/**
 * Extends FirestoreModel but instead of deleting the docs sets thais status to 'deleted'
 */

import ModelCriteria from "./criteria";
import FirestoreModel from "./firestore";

export const STATUS_ACTIVE = 'active';
export const STATUS_DELETED = 'deleted';

class FirestorePersistentModel extends FirestoreModel {

    /**
     * Create a new object in database
     * 
     * @param {object}  props   The properties to save
     * @param {string?} docId   The id of the created document
     * 
     * @return {Promise} The created document id
     */
    async create(props, docId = null) {
        return super.create(Object.assign({ status: STATUS_ACTIVE }, props), docId);
    }

    /**
     * List documents
     * 
     * @param {object?} criteria The query object
     * @return {Promise} The created document 
     */
    async list(criteria = {}) {

        if (!(criteria instanceof ModelCriteria)) {
            criteria = new ModelCriteria(criteria);
        }

        if( !criteria.getWhere().find( ([field]) => field === 'status') ){
            criteria.where('status', '==', STATUS_ACTIVE);
        }

        // Check for permissions, etc
        return super.list(criteria);
    }


    /**
     * Delete object in database
     * 
     * @param {string} id The object id to delete
     * @return {Promise}
     */
    async delete(id) {
        return this.update(id, { status: STATUS_DELETED });
    }

    /**
     * Remove the object from database
     * 
     * @param {string} id The object id to delete
     * @return {Promise}
     */
    async destroy(id) {
        return super.delete(id);
    }

}

FirestorePersistentModel.STATUS_ACTIVE = STATUS_ACTIVE;
FirestorePersistentModel.STATUS_DELETED = STATUS_DELETED;

export default FirestorePersistentModel;