import { firebase, googleAuthProvider } from "../../firebase/firebase";

import { put } from "redux-saga/effects";
import * as actions from "../actions";
import User from "../../models/user";
import errorHandler from "../../shared/errorReporting";
import AuthRole from "../../models/role";

export function* authUser() {
  try {
    yield put(actions.authUserStart());
  
    const response = yield firebase.auth().signInWithPopup(googleAuthProvider);

    let userProfile = yield User.model().get(response.user.uid);
    let userRole = yield AuthRole.model().get(userProfile.role);

    let authUser = {
      // @TODO use the role object directly
      id: response.user.uid,
      permissions: userRole.permissions,
      role: userRole,
      profile: userProfile,
      auth: response.user,
      //authProfile: response.additionalUserInfo.profile
    };

    yield put(actions.authUserSuccess(authUser));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.authUserFail("errore"));
  }
}

export function* authUserCheckState() {

  try {
    const currentAuthUser = firebase.auth().currentUser;

    // User is not logged in
    if (!currentAuthUser) {
      return;
    }

    let currentUser = yield User.model().get(currentAuthUser.uid);
    let currentUserRole = yield AuthRole.model().get(currentUser.role);
    let profile = currentUser;

    console.log('CURRENT AUTH USER', currentAuthUser);

    profile.picture = currentAuthUser.photoURL

    // @TODO cleanup this mess
    let authUser = {
      id: currentAuthUser.uid,
      permissions: currentUserRole.permissions,
      role: currentUser.role,
      auth: currentAuthUser,
      profile
    };

    yield put(actions.authUserCheckStateSuccess(authUser));

  } catch (error) {
    errorHandler.report(error);
    yield put(actions.authUserFail("errore"));
  }

}

export function* authUserlogout() {
  try {
    yield firebase.auth().signOut();
    yield put(actions.authUserLogoutSuccess());
  } catch (error) {
    errorHandler.report(error);
  }
}
