import * as actionTypes from "../../types/types";

export const getRoles = () => {
  return {
    type: actionTypes.GET_ROLES
  };
};
export const getRolesStart = () => {
  return {
    type: actionTypes.GET_ROLES_START
  };
};
export const getRolesSuccess = roles => {
  return {
    type: actionTypes.GET_ROLES_SUCCESS,
    payload: { roles }
  };
};
export const getRolesFail = error => {
  return {
    type: actionTypes.GET_ROLES_FAIL,
    payload: { error }
  };
};
