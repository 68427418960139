import FirestoreModel from '../../firestore';
import * as yup from 'yup';

export default class CourseTemplate extends FirestoreModel {

    /**
     * The collection name in the database
     */
    get collectionName() {
        return 'course_template';
    };

    /**
     * The collection name in the database
     */
    get validationSchema() {
        return yup.object().shape({
            name: yup.string().required("Nome obbligatorio").label('Nome'),
            duration: yup.string().required("Durata obbligatoria").label('Durata'),
            price: yup.string().required("Prezzo obbligatorio").label('Prezzo'),
            available: yup.boolean().required("Disponibilità obbligatoria").label('Disponibile'),
            groups: yup.array()
        })
    };

}
