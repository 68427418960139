import * as actionTypes from "../../types/types";

export const getCourseTemplates = () => {
  return {
    type: actionTypes.GET_COURSE_TEMPLATES
  };
};
export const getCourseTemplatesStart = () => {
  return {
    type: actionTypes.GET_COURSE_TEMPLATES_START
  };
};
export const getCourseTemplatesSuccess = course_templates => {
  return {
    type: actionTypes.GET_COURSE_TEMPLATES_SUCCESS,
    payload: { course_templates }
  };
};
export const getCourseTemplatesFail = error => {
  return {
    type: actionTypes.GET_COURSE_TEMPLATES_FAIL,
    payload: { error }
  };
};

export const getCourseTemplate = id => {
  return {
    type: actionTypes.GET_COURSE_TEMPLATE,
    payload: { id }
  };
};
export const getCourseTemplateStart = () => {
  return {
    type: actionTypes.GET_COURSE_TEMPLATE_START
  };
};
export const getCourseTemplateSuccess = course_template => {
  return {
    type: actionTypes.GET_COURSE_TEMPLATE_SUCCESS,
    payload: { course_template }
  };
};
export const getCourseTemplateFail = error => {
  return {
    type: actionTypes.GET_COURSE_TEMPLATE_FAIL,
    payload: { error }
  };
};
export const saveCourseTemplate = course_template => {
  return {
    type: actionTypes.SAVE_COURSE_TEMPLATE,
    payload: { course_template }
  };
};
export const saveCourseTemplateStart = () => {
  return {
    type: actionTypes.SAVE_COURSE_TEMPLATE_START
  };
};
export const saveCourseTemplateSuccess = course_template => {
  return {
    type: actionTypes.SAVE_COURSE_TEMPLATE_SUCCESS,
    payload: { course_template }
  };
};
export const saveCourseTemplateFail = error => {
  return {
    type: actionTypes.SAVE_COURSE_TEMPLATE_FAIL,
    payload: { error }
  };
};
export const deleteCourseTemplate = id => {
  return {
    type: actionTypes.DELETE_COURSE_TEMPLATE,
    payload: { id }
  };
};
export const deleteCourseTemplateStart = () => {
  return {
    type: actionTypes.DELETE_COURSE_TEMPLATE_START
  };
};
export const deleteCourseTemplateSuccess = id => {
  return {
    type: actionTypes.DELETE_COURSE_TEMPLATE_SUCCESS,
    payload: { id }
  };
};
export const deleteCourseTemplateFail = error => {
  return {
    type: actionTypes.DELETE_COURSE_TEMPLATE_FAIL,
    payload: { error }
  };
};
export const resetCourseTemplate = () => {
  return {
    type: actionTypes.RESET_COURSE_TEMPLATE
  };
};
export const resetCourseTemplateMessage = () => {
  return {
    type: actionTypes.RESET_COURSE_TEMPLATE_MESSAGE
  };
};

export const cloneCourse = id => {
  return {
    type: actionTypes.CLONE_COURSE,
    payload: { id }
  };
};
export const cloneCourseStart = () => {
  return {
    type: actionTypes.CLONE_COURSE_START
  };
};
export const cloneCourseSuccess = clone_course => {
  return {
    type: actionTypes.CLONE_COURSE_SUCCESS,
    payload: { clone_course }
  };
};
export const cloneCourseFail = error => {
  return {
    type: actionTypes.CLONE_COURSE_FAIL,
    payload: { error }
  };
};
