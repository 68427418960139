import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";
import Layout from "../../../hoc/Layout";
import List from "./_list";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconBack from "@material-ui/icons/ChevronLeft";
import IconAssignment from "@material-ui/icons/Assignment";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { userCan } from "../../../../shared/utility";



const Activity = props => {
  const [dialog, setDialog] = useState({
    open: false,
    id: null
  });

  useEffect(() => {
    props.onGetActivityGroup();
    props.onGetEmailTemplate();
  }, []);

  useEffect(() => {
    if (props.activity) props.onResetActivity();
  }, [props.activity]);

  const goToEdit = id => {
    props.history.push(`/task/${id}`);
  };

  const openDialog = id => {
    setDialog({
      ...dialog,
      open: true,
      id
    });
  };

  const closeDialog = () => {
    setDialog({
      ...dialog,
      open: false,
      id: null
    });
  };

  const removeActivity = async () => {
    await props.onDeleteActivity(dialog.id);
    closeDialog();
  };
  return (
    <Layout>
      <div className="box-layout">
        {!props.loading ? (
          <div className="box-layout__table">
            <div className="topSection">
              <h1>Attività</h1>
              { userCan('task__create') && (
                <button
                  size="large"
                  className="button button--new"
                  onClick={() => props.history.push("/task")}
                >
                  <IconAssignment />
                  Aggiungi Attività
                </button>
              ) }
            </div>
            <List
              activity_group={props.activity_group}
              openDialog={openDialog}
              goToEdit={goToEdit}
              email_template={props.email_template}
            />
          </div>
        ) : (
          <div className="box-layout box-layout__progress">
            <CircularProgress
              size={80}
              thickness={4}
              className="main-progress"
            />
          </div>
        )}
      </div>
      <Dialog
        open={dialog.open}
        
        keepMounted
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title-delete"
        aria-describedby="alert-dialog-slide-description-delete"
      >
        <DialogTitle
          className="dialogTitle"
          id="alert-dialog-slide-title-delete"
        >
          {"Conferma Azione di eliminazione"}
        </DialogTitle>
        <DialogContent className="dialogContent">
          <DialogContentText id="alert-dialog-slide-description-delete">
            Vuoi davvero eliminare questa attività?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="button button--cancel"
            onClick={closeDialog}
            variant="contained"
          >
            <IconBack /> Indietro
          </Button>
          <Button
            onClick={removeActivity}
            variant="contained"
            className="button"
          >
            Elimina
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};
const mapStateToProps = state => {
  return {
    activity_group: state.activity.activity_group,
    activity: state.activity.activity,
    email_template: state.utility.email_template,
    loading: state.activity.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetActivityGroup: () => dispatch(actions.getActivityGroup()),
    onGetEmailTemplate: () => dispatch(actions.getEmailTemplate()),
    onDeleteActivity: id => dispatch(actions.deleteActivity(id)),
    onResetActivity: () => dispatch(actions.resetActivity())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Activity));
