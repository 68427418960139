import FirestoreModel from '../../firestore';
import * as yup from 'yup';
import { MissingParentException } from '../../exceptions';

export default class CourseTemplateTask extends FirestoreModel {

    /**
     * Setup the model
     * 
     * @param {object} props 
     */
    constructor(props) {
        super(props);

        const { courseTemplateId } = this.props;

        if ( !courseTemplateId ) {
            throw new MissingParentException(courseTemplateId);
        }

    }

    /**
     * The collection name in the database
     */
    get collectionName() {
        return `course_template/${this.props.courseTemplateId}/tasks`;
    };

    /**
     * The collection name in the database
     */
    get validationSchema() {
        return yup.object().shape({
            group: yup.string().required("Gruppo obbligatorio").label('Gruppo'),
            task: yup.string().required("Task obbligatorio").label('Task Collegato'),
            position: yup.string().label('Position'),
        });
    };
  

}
