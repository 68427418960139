import FirestorePersistentModel from '../firestore-persistent';
import * as yup from 'yup';
import { userCan } from '../../shared/utility';
import CurrentUser from '../user/current';
import ModelCriteria from '../criteria';

class Customer extends FirestorePersistentModel {

    // Set wich date fields should be converted from database
    dateFields = ['birthDate', 'dsaDate'];

    /**
     * The collection name in the database
     */
    get collectionName() {
        return 'customer';
    };

    /**
     * The collection name in the database
     */
    get validationSchema() {
        return yup.object().shape({
            name: yup.string().required("Nome obbligatorio"),
            surname: yup.string().required("Cognome obbligatorio"),
            gender: yup.string().required("Sesso obbligatorio"),
            birthDate: yup.date('Data non valida').typeError('Inserire una data').required("Data di nascita obbligatoria").label('Data di nascita'),
            disorders: yup.array(),
            dsaDate: yup.date().nullable().typeError('Inserire una data').label('Data Certificazione DSA'),
            dsaCertificate: yup.boolean().label('Certificato DSA'),
            fiscalCode: yup.string().min(16).label('Codice fiscale'),
            schoolClass: yup.string().label('Classe Frequentata'),
            notes: yup.string().label('Note'),
            pro: yup.string().label('Professionista'),
            status: yup.string().nullable().label('Stato'),
            contactPerson: yup.mixed().typeError('Per favore selezionare uno dei familiari inseriti').label('Contatto Principale'),
            center: yup.string().label('Centro').required("Centro obbligatorio"),
        });
    };

    static get defaults() {
        return {
            name: "",
            surname: "",
            gender: "",
            fiscalCode: "",
            disorders: [],
            dsaCertificate: false,
            notes: "",
            pro: "",
            center: null,
            schoolClass: "",
            birthDate: null,
            dsaDate: null,
            contactPerson: null
        };
    }

    static get DISORDERS() {
        return [
            ['dislessia', 'Dislessia'],
            ['disgrafia', 'Disgrafia'],
            ['disortografia', 'Disortografia'],
            ['discalculia', 'Discalculia'],
            ['bes', 'BES'],
            ['adhd', 'ADHD'],
            ['disprassia', 'Disprassia'],
            ['apprendimento', 'Apprendimento'],
            ['autismo', 'Autismo'],
            ['ritardo_cognitivo', 'Ritardo Cognitivo'],
            ['linguaggio', 'Linguaggio'],
        ];
    };

    static get GENDERS() {
        return [
            ['m', 'Maschio'],
            ['f', 'Femmina'],
        ];
    };


    static get SCHOOL_CLASSES() {
        return [
            ['prima-elementare', 'Prima Elementare'],
            ['seconda-elementare', 'Seconda Elementare'],
            ['terza-elementare', 'Terza Elementare'],
            ['quarta-elemetare', 'Quarta Elementare'],
            ['quinta-elementare', 'Quinta Elementare'],
            ['prima-media', 'Prima Media'],
            ['seconda-media', 'Seconda Media'],
            ['terza-media', 'Terza Media'],
            ['quarta-media', 'Prima Liceo'],
            ['seconda-liceo', 'Seconda Liceo'],
            ['terza-liceo', 'Terza Liceo'],
            ['quarta-liceo', 'Quarta Liceo'],
            ['quinta-liceo', 'Quinta Liceo'],
            ['universita', 'Università'],
            ['none', 'Nessuna']
        ];
    }

    static get SPECIALISTS() {
        return [
            ['logopedista', 'Logopedista'],
            ['tutor', 'Tutor'],
            ['psicologo', 'Psicologo']
        ];
    }

    /**
     * List documents
     * 
     * @param {object?} criterie The query object
     * @return {Promise} The created document 
     */
    async list(criteria = {}) {

        if ( !(criteria instanceof ModelCriteria) ) {
            criteria = new ModelCriteria(criteria);
        }

        // @TODO find another way to prevent recursion
        if ( !this.disableRbac && !userCan('customer__list') && userCan('customer__list--own-center')) {
            
            // Make the query filterd for each center assigned to the current user
            return CurrentUser.model()
                .centers()
                .then( myCenters => {
                    this.disableRbac = true;
                    return myCenters.map(
                        (center) => 
                            criteria.clone().where('center', '==', center)
                    )
                })                    
                .then(this.listTogheter.bind(this))
                .then((results) => {
                    this.disableRbac = false;
                    return results;
                })

        }

        return super.list(criteria);
    }

    async create(props, docId = null){
        
        if ( !userCan('customer__create') && userCan('customer__create--own-center') ) {
            props.center = await CurrentUser.model().centers().then( centers => centers.pop() )
        }

        return super.create( props, docId );
    }

    async update(id, props){
        
        if ( !userCan('customer__update') && userCan('customer__update--own-center') ) {
            props.center = await CurrentUser.model().centers().then( centers => centers.pop() )
        }

        return super.update( id, props );
    }    

}

Customer.GENDERS_MALE = 'm';
Customer.GENDERS_FEMALE = 'f';

export default Customer;