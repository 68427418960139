
import { firebase } from "../../firebase/firebase";

import User from '.';

export default class CurrentUser extends User {

    /**
     * Get the current logged in user 
     * 
     * @returns {Promise} A promise returning an object containing the user data
     */
    async get() {

        if (!firebase.auth().currentUser) {
            return Promise.resolve(false);
        }

        const loggedUserId = firebase.auth().currentUser.uid;

        return super.get(loggedUserId);
    }

    async centers(){
        return this.get()
            .then((me) => {
                // User has no centers, bail out.
                if ( !me._currentCenters) {
                    return [];
                }

                return me._currentCenters;
            });
    }

}
