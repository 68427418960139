import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";
import { withRouter } from "react-router-dom";
import Layout from "../../../hoc/Layout";
import List from "./_list";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import IconBack from "@material-ui/icons/ChevronLeft";
import IconSchool from "@material-ui/icons/School";
import { userCan } from "../../../../shared/utility";



const CourseTemplates = props => {
  const [dialog, setDialog] = useState({
    open: false,
    id: null,
    clone: null
  });

  useEffect(() => {
    props.onGetCourseTemplates();
  }, []);

  useEffect(() => {
    if (props.course_template) props.onResetCourseTemplate();
  }, [props.course_template]);

  const openDialog = (id, clone) => {
    setDialog({
      ...dialog,
      open: true,
      id,
      clone
    });
  };

  const closeDialog = () => {
    setDialog({
      ...dialog,
      open: false,
      id: null,
      clone: null
    });
  };

  const goToEdit = id => {
    props.history.push(`/modello-corso/${id}`);
  };

  const removeCourseTemplate = async () => {
    await props.onDeleteCourseTemplate(dialog.id);
    closeDialog();
  };

  const cloneCourse = async () => {
    await props.onCloneCourse(dialog.id);
    closeDialog();
  };

  return (
    <Layout>
      <div className="box-layout">
        {!props.loading ? (
          <div className="box-layout__table">
            <div className="topSection">
              <h1>Modelli Corso</h1>
              { userCan('course_template__create') && (
              <button
                size="large"
                className="button button--new"
                onClick={() => props.history.push("/modello-corso")}
              >
                <IconSchool />
                Aggiungi Modello
              </button>
              )}
            </div>
            <List
              course_templates={props.course_templates}
              openDialog={openDialog}
              goToEdit={goToEdit}
            />
          </div>
        ) : (
          <div className="box-layout box-layout__progress">
            <CircularProgress
              size={80}
              thickness={4}
              className="main-progress"
            />
          </div>
        )}
      </div>
      <Dialog
        open={dialog.open}
        
        keepMounted
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title-delete"
        aria-describedby="alert-dialog-slide-description-delete"
      >
        <DialogTitle
          className="dialogTitle"
          id="alert-dialog-slide-title-delete"
        >
          {`Conferma Azione di ${dialog.clone ? "copia" : "eliminazione"}`}
        </DialogTitle>
        <DialogContent className="dialogContent">
          <DialogContentText id="alert-dialog-slide-description-delete">
            Vuoi davvero {dialog.clone ? "clonare" : "eliminare"} questo modello
            di corso?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
            variant="contained"
            className="button button--cancel"
          >
            <IconBack /> Indietro
          </Button>
          <Button
            onClick={dialog.clone ? cloneCourse : removeCourseTemplate}
            variant="contained"
            className="button"
          >
            {dialog.clone ? "Clona" : "Elimina"}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    course_templates: state.course_template.course_templates,
    course_template: state.course_template.course_template,
    loading: state.course_template.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetCourseTemplates: () => dispatch(actions.getCourseTemplates()),
    onResetCourseTemplate: () => dispatch(actions.resetCourseTemplate()),
    onDeleteCourseTemplate: id => dispatch(actions.deleteCourseTemplate(id)),
    onCloneCourse: id => dispatch(actions.cloneCourse(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseTemplates));
