import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <span>
        © 2019 DysWay SRL | Powered by <b>Silverback Studio</b>
      </span>
    </div>
  );
};

export default Footer;
