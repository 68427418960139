import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";
import Layout from "../../../hoc/Layout";
import List from "./_list";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  withMobileDialog
} from "@material-ui/core";
import IconBack from "@material-ui/icons/ChevronLeft";
import { BuildingIcon } from "../../../shared/icons";
import { userCan } from "../../../../shared/utility";



const Centers = props => {
  const [dialog, setDialog] = useState({
    open: false,
    id: null
  });

  const [dialogCalendar, setDialogCalendar] = useState({
    openCalendar: false,
    idCalendar: null
  });
  const { fullScreen } = props;

  useEffect(() => {
    props.onGetCenters();
  }, []);

  useEffect(() => {
    if (props.center) props.onResetCenter();
  }, [props.center]);

  const goToEdit = id => {
    props.history.push(`/centro/${id}`);
  };

  const openDialog = id => {
    setDialog({
      ...dialog,
      open: true,
      id
    });
  };

  const closeDialog = () => {
    setDialog({
      ...dialog,
      open: false,
      id: null
    });
  };

  const openDialogCalendar = url => {
    setDialogCalendar({
      ...dialogCalendar,
      openCalendar: true,
      idCalendar: url
    });
  };

  const closeDialogCalendar = () => {
    setDialogCalendar({
      ...dialogCalendar,
      openCalendar: false,
      idCalendar: null
    });
  };

  const removeCenter = async () => {
    await props.onDeleteCenter(dialog.id);
    closeDialog();
  };

  return (
    <Layout>
      <div className="box-layout">
        {!props.loading ? (
          <div className="box-layout__table">
            <div className="topSection">
              <h1>Centri</h1>
              { userCan('center__create') && (
              <button
                size="large"
                className="button button--new"
                onClick={() => props.history.push("/centro")}
              >
                <BuildingIcon />
                Aggiungi Centro
              </button>
              )}
            </div>
            <List
              centers={props.centers}
              openDialog={openDialog}
              goToEdit={goToEdit}
              openDialogCalendar={openDialogCalendar}
              user={props.user}
            />
          </div>
        ) : (
                    <div className="box-layout box-layout__progress">
            <CircularProgress
              size={80}
              thickness={4}
              className="main-progress"
            />
          </div>
        )}
      </div>
      <Dialog
        open={dialog.open}
        
        keepMounted
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title-delete"
        aria-describedby="alert-dialog-slide-description-delete"
      >
        <DialogTitle
          className="dialogTitle"
          id="alert-dialog-slide-title-delete"
        >
          {"Conferma Azione di eliminazione"}
        </DialogTitle>
        <DialogContent className="dialogContent">
          <DialogContentText id="alert-dialog-slide-description-delete">
            Vuoi davvero eliminare questo centro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
            variant="contained"
            className="button button--cancel"
          >
            <IconBack /> Indietro
          </Button>
          <Button
              onClick={removeCenter} 
              variant="contained"
              className="button"
            >
              Elimina
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogCalendar.openCalendar}
        
        keepMounted
        onClose={closeDialogCalendar}
        aria-labelledby="alert-dialog-slide-title-delete"
        aria-describedby="alert-dialog-slide-description-delete"
        fullScreen={fullScreen}
        maxWidth={false}
      >
        <iframe
          src={dialogCalendar.idCalendar}
          title="googleCalendar"
          style={{ width: "100vw", height: "100vh" }}
        />
      </Dialog>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    centers: state.center.centers,
    center: state.center.center,
    loading: state.center.loading,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetCenters: () => dispatch(actions.getCenters()),
    onResetCenter: () => dispatch(actions.resetCenter()),
    onDeleteCenter: id => dispatch(actions.deleteCenter(id))
  };
};

Centers.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withMobileDialog({ breakpoint: "md" })(Centers)));
