
import StorageModel from '../../storage';
import * as yup from 'yup';
import { MissingParentException } from '../../exceptions';

export default class CourseTaskAttachment extends StorageModel {
    
    /**
     * Setup the model
     * 
     * @param {object} props 
     */
    constructor(props) {
        super(props);

        const { courseTaskId, courseId } = this.props;

        if ( !courseTaskId ) {
            throw new MissingParentException(courseTaskId);
        }

        if ( !courseId ) {
            throw new MissingParentException(courseId);
        }        

    }

    get folder() {
        return `course/${this.props.courseId}/task/${this.props.courseTaskId}/attachments/`
    }

    /**
     * The collection name in the database
     */
    get validationSchema() {
        return yup.object().shape({
            id: yup.string().required(),
            label: yup.string().required("Etichetta obbligatorio").label('Etichetta file'),
            name: yup.string().label('Nome File'),
            path: yup.string(),
            type: yup.string(),
            size: yup.number()
        });
    };

    /**
     * Upload file in the storage and return a promise when completed
     * 
     * @param {object} doc The object containing the `file` to uploaded
     * 
     * @returns {Promise} Promise that resolve when file is uploaded
     */
    async create(doc) {
        return super.create(doc.blob);
    }

    /**
     * Update file in the storage and return a promise when completed
     * 
     * @param {object} doc The object containing the `file` to uploaded
     * 
     * @returns {Promise} Promise that resolve when file is uploaded
     */
    async update(doc) {
        return super.update(doc.blob);
    }    

}
