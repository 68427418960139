import React from "react";

import {
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  FormControl,
} from "@material-ui/core";

export default function CourseTemplateForm(props) {

    return (
        <FormControl component="fieldset" fullWidth className="fields-group">
            <Grid container spacing={3} className="form-maxWidth">
                <Grid item xs={12} sm={12} md={6}>
                    <TextField
                        variant="outlined"
                        autoComplete="off"
                        label="Nome"
                        type="text"
                        name="name"
                        onChange={props.handleChange}
                        value={props.template.name}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <TextField
                        variant="outlined"
                        autoComplete="off"
                        label="Durata (giorni)"
                        type="text"
                        name="duration"
                        onChange={props.handleChange}
                        value={props.template.duration}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <TextField
                        variant="outlined"
                        autoComplete="off"
                        label="Prezzo"
                        type="text"
                        name="price"
                        onChange={props.handleChange}
                        value={props.template.price}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.template.available}
                                onChange={props.handleCheck}
                                value={props.template.available.toString()}
                                name="available"
                                color="primary"
                            />
                        }
                        label="Disponibile"
                    />
                </Grid>
            </Grid>
        </FormControl>
    );

}