import React, { Component } from "react";
import { Router, Switch, Route, withRouter } from "react-router-dom";
import { firebase } from "../firebase/firebase";

import history from "./history";
import routes from "./routes";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import { userCan } from "../shared/utility";

class AppRouter extends Component {
  
  componentDidMount() {
    
    firebase.auth().onAuthStateChanged(user => {
      
      if (user) {
        this.props.isUserAuthenticated(user);
      } else {
        this.props.history.push("/");
        this.props.logoutApp();
      }

    });
  }

  render() {
    window.scrollTo(0, 0);
    const user = this.props.user;

    const routesFilter = (r) => {
      return (
        // Se la rotta è impostata come sempre visibile (=== true)
        (r.visibility === true)
        ||
        // Se l'utente è loggato e ha il permesso richiesto
        (user && userCan(r.visibility))
      );
    };

    let availableRoutes = routes.filter(routesFilter);

    return (
      <Router history={history}>
        <Switch>{availableRoutes.map(r => {
          let DynamicComponent = r.component;
          return (
            <Route
              key={r.name}
              path={r.routePath}
              render={props => <DynamicComponent {...props} key={r.name} />}
            />
          );
        })}</Switch>
      </Router>
    );
  }
}

const mapStateToProps = state => ({ user: state.auth.user });
const mapDispatchToProps = dispatch => ({
  isUserAuthenticated: user => dispatch(actions.authUserCheckState(user)),
  logoutApp: () => dispatch(actions.authUserLogoutSuccess())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(withRouter(AppRouter));
