import React, { useState } from "react";
import IconBack from "@material-ui/icons/ChevronLeft";

import {
    Button,
    TextField,
    FormControl,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from "@material-ui/core";

import SearchSelect from '../../../../shared/select';

const initialStateGroup = {
    name: ""
};

const initialStateTask = { group: "", task: {} };

export function addGroupDialog(props) {

    const [newGroup, setNewGroup] = useState(initialStateGroup);

    const handleChangeNewGroup = ({ target }) => {
        setNewGroup({
            ...newGroup,
            [target.name]: target.value
        });
    };

    return (
        <Dialog
            open={props.isOpen}
            
            keepMounted
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title-delete"
            aria-describedby="alert-dialog-slide-description-delete"
        >
            <DialogTitle
                className="dialogTitle"
                id="alert-dialog-slide-title-delete"
            >
                {"Aggiungi Gruppo"}
            </DialogTitle>
            <DialogContent className="dialogContent">
                <TextField
                    variant="outlined"
                    autoComplete="off"
                    label="Nome"
                    type="text"
                    name="name"
                    onChange={handleChangeNewGroup}
                    value={newGroup.name}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button
                    className="button button--cancel"
                    onClick={props.onClose}
                >
                    <IconBack /> Indietro
                </Button>
                <Button
                    className="button"
                    onClick={() => {
                    setNewGroup(initialStateGroup);
                    props.onClose();
                    return props.onAdd(newGroup, props.state);
                }}>
                    Aggiungi
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export function addTaskDialog(props) {

    const [newTask, setNewTask] = useState(initialStateTask);

    const handleChangeNewTask = ({ target }) => {
        setNewTask(props.tasks.find(task => task.id === target.value));
    };

    return (
        <Dialog
            open={props.isOpen}
            
            keepMounted
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title-delete"
            aria-describedby="alert-dialog-slide-description-delete"
            className="dialog--visible-overflow"
        >
            <DialogTitle
                className="dialogTitle"
                id="alert-dialog-slide-title-delete"
            >
                {"Aggiungi Attività"}
            </DialogTitle>
            <DialogContent className="dialogContent">
                <FormControl fullWidth variant="outlined">
                    <SearchSelect
                        label="Attività"
                        name="task"
                        id="task"
                        value={{ value: newTask.id, label: newTask.name }}
                        onChange={handleChangeNewTask}
                        options={props.tasks.map(task => ({ value: task.id, label: task.name }))
                        }
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} className="button button--cancel">
                    <IconBack /> Indietro
      </Button>
                <Button
                    className="button"
                    onClick={() => {
                        setNewTask(initialStateTask);
                        props.onClose();
                        return props.onAdd(newTask, props.state);
                    }}
                >
                    Aggiungi</Button>
            </DialogActions>
        </Dialog>
    )
}


export function unsavedFormDialog(props) {

    return (
        <Dialog
            open={props.isOpen}
            
            keepMounted
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title-delete"
            aria-describedby="alert-dialog-slide-description-delete"
        >
            <DialogTitle
                className="dialogTitle"
                id="alert-dialog-slide-title-delete"
            >
                {"Sei sicuro di voler uscire?"}
            </DialogTitle>
            <DialogContent className="dialogContent">
                <DialogContentText id="alert-dialog-slide-description-delete">
                    Attenzione! Stai lasciando il form con alcuni elementi non ancora
                    salvati! Desideri davvero uscire?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button
                    onClick={() => {
                        props.onClose();
                        return props.onConfirm(props.state)
                    }}
                    variant="contained"
                    className="button button--cancel"
                >
                    Esci
                </Button>
                <Button
                    onClick={props.onClose}
                    variant="contained"
                    className="button"
                >
                        Rimani
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export function deleteGroupDialog(props) {

    return (
        <Dialog
            open={props.isOpen}
            
            keepMounted
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title-delete"
            aria-describedby="alert-dialog-slide-description-delete"
        >
            <DialogTitle
                className="dialogTitle"
                id="alert-dialog-slide-title-delete"
            >
                {"Conferma Azione di eliminazione"}
            </DialogTitle>
            <DialogContent className="dialogContent">
                <DialogContentText id="alert-dialog-slide-description-delete">
                    Attenzione! Eliminando questo gruppo verranno eliminate anche tutte
                    le attività ad esso correlate. Vuoi davvero eliminare questo gruppo?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary" variant="contained" className="button button--cancel">
                    <IconBack /> Indietro
                </Button>
                <Button 
                    onClick={() => { 
                        props.onClose();
                        return props.onConfirm(props.state) 
                    }} 
                    color="primary" 
                    variant="contained"
                    className="button"
                >
                    Elimina
                </Button>
            </DialogActions>
    </Dialog>
    );
}

export function deleteTaskDialog(props) {

    return (
        <Dialog
            open={props.isOpen}
            
            keepMounted
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title-delete"
            aria-describedby="alert-dialog-slide-description-delete"
        >
            <DialogTitle
                className="dialogTitle"
                id="alert-dialog-slide-title-delete"
            >
                {"Conferma Azione di eliminazione"}
            </DialogTitle>
            <DialogContent className="dialogContent">
                <DialogContentText id="alert-dialog-slide-description-delete">
                    Attenzione! Stai per eliminare questa attività IMMEDIATAMENTE e DEFINITIVAMENTE, sei sicuro di continuare?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary" variant="contained">
                    <IconBack /> Indietro
                </Button>
                <Button 
                    onClick={() => { 
                        props.onClose();
                        return props.onConfirm(props.state) 
                    }} 
                    color="primary" 
                    variant="contained"
                >
                    Elimina
                </Button>
            </DialogActions>
    </Dialog>
    );
}