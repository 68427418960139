import { put } from "redux-saga/effects";
import * as actions from "../../actions/index";
import Course from "../../../models/course";
import CourseTask from "../../../models/course/task";
import Customer from "../../../models/customer";
import errorHandler from "../../../shared/errorReporting";
import CourseTaskAttachment from "../../../models/course/task/attachment";
import Center from "../../../models/center";
import User from "../../../models/user";
import CourseTemplate from "../../../models/course/template";
import QueueEmail from "../../../models/queue/email";
import QueueSms from "../../../models/queue/sms";
import { ValidateException } from "../../../models/exceptions";
import CustomerRelative from "../../../models/customer/relative";

export function* getCourses() {
  yield put(actions.getCoursesStart());
  try {
    const courses = yield Course.model().list({ where: [ [ 'status', '>', '' ] ] });

    yield put(actions.getCoursesSuccess(courses));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.getCoursesFail(error));
  }
}

export function* getCourse(action) {
  yield put(actions.getCourseStart());
  try {
    const courseId = action.payload.id;
    let course = yield Course.model().get(courseId);

    // Replace docRefs with docIds
    course.customer = course.customer.id;
    course.center = course.center.id;
    course.template = course.template.id;
    course.manager = course.manager.id;

    yield put(actions.getCourseSuccess(course));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.getCourseFail(error));
  }
}

export function* getCourseSpec(action) {
  yield put(actions.getCourseSpecStart());

  let courseId = action.payload.id;
  try {
    let course = yield Course.model().get(courseId);

    // Query Course tasks
    let tasks = yield CourseTask.model({ courseId }).list({ orderBy: [ 'position' ] });

    // Set helpers props in the task statuses
    course.tasks = tasks.map(task => {
      // Set the group_name prop
      if (task.group) {
        let groupObj = course.groups.find(group => group.id === task.group);
        task.group_name = groupObj ? groupObj.name : "";
      }

      // Set the status_name and status_color props
      if (task.statuses) {
        let statusObj = task.statuses.find(status => status.id === task.status);
        task.status_name = statusObj ? statusObj.name : "N/A";
        task.status_color = statusObj ? statusObj.color : "";
      }

      return task;
    });

    // Resolve course `customer`
    course.customer = yield Customer.model().getByRef(course.customer);
    if (course.customer.contactPerson) {
      course.customer.contactPerson = yield CustomerRelative.model({
        customerId: course.customer.id
      }).getByRef(course.customer.contactPerson);
    }

    yield put(actions.getCourseSpecSuccess(course));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.getCourseSpecFail(error));
  }
}

export function* saveCourseSpec(action) {
  yield put(actions.saveCourseSpecStart());

  const courseId = action.payload.courseSpec.course_id;
  const courseTaskId = action.payload.courseSpec.task_id;

  try {
    let attachments = action.payload.courseSpec.attachments;

    if (attachments) {
      // Upload new documents
      let uploadPromises = [];

      attachments = attachments.map(attachment => {
        let CourseTaskAttachmentModel = CourseTaskAttachment.model({
          courseId,
          courseTaskId
        });

        // @TODO find a better way to detect new file attachment
        if (attachment.blob) {
          uploadPromises.push(CourseTaskAttachmentModel.create(attachment));
        }

        attachment.path = CourseTaskAttachmentModel.path(attachment);

        // Strip all unwanted props (file, id, etc)
        return CourseTaskAttachmentModel.trim(attachment);
      });

      // Wait for all uploads
      yield Promise.all(uploadPromises);
    }

    let cS = {
      attachments: attachments || [],
      dueDateTime: action.payload.courseSpec.dueDateTime || null,
      notes: action.payload.courseSpec.notes || "",
      owner: action.payload.courseSpec.owner
        ? User.model().ref(action.payload.courseSpec.owner)
        : "",
      status: action.payload.courseSpec.status
        ? action.payload.courseSpec.status
        : ""
    };

    yield CourseTask.model({ courseId }).update(courseTaskId, cS);
    yield put(actions.saveCourseSpecSuccess());
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.saveCourseSpecFail(error));
  }
}

export function* sendQueue(action) {
  // yield put(actions.sendQueueStart());
  let queue = action.payload.queue;
  let courseId = queue.courseId;
  let courseTaskId = queue.task_id;

  try {
    let envelope = {
      ...queue,
      course: Course.model().ref(courseId),
      task: CourseTask.model({ courseId }).ref(courseTaskId)
    };

    let QueueModel;

    switch (queue.type) {
      case QueueSms.QUEUE_TYPE:
        QueueModel = new QueueSms();
        break;

      case QueueEmail.QUEUE_TYPE:
        QueueModel = new QueueEmail();
        break;

      default:
        errorHandler.report(`Invalid queue type: ${queue.type}`);
        throw ValidateException(`Invalid queue type: ${queue.type}`);
    }

    // Put the message in the queue
    yield QueueModel.create(envelope);

    // Update the status in the task to the finalStatus
    yield CourseTask.model({ courseId }).update(courseTaskId, {
      status: queue.finalStatus
    });

    yield put(actions.sendQueueSuccess(queue));
    yield put(actions.getCourseSpec(courseId));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.sendQueueFail(error));
  }
}

export function* saveCourseTaskUpdate(action) {
  try{
    yield CourseTask.model({ courseId: action.payload.courseId }).update(action.payload.taskId, action.payload.data);
    yield put(actions.courseTaskUpdateSuccess(action.payload.taskId, action.payload.data));
  } catch (error) {
    errorHandler.report(error);
  }
}

export function* saveCourse(action) {
  yield put(actions.saveCourseStart());

  let courseId = action.payload.course.id;

  // Cast all referenced docs from docIds to docRefs
  let course = {
    ...action.payload.course,
    center: Center.model().ref(action.payload.course.center),
    customer: Customer.model().ref(action.payload.course.customer),
    manager: User.model().ref(action.payload.course.manager),
    template: CourseTemplate.model().ref(action.payload.course.template)
  };

  try {
    yield Course.model().write(course, courseId);
    yield put(actions.saveCourseSuccess());
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.saveCourseFail(error));
  }
}

export function* deleteCourse(action) {
  yield put(actions.deleteCourseStart());

  let courseId = action.payload.id;

  try {
    let course = yield Course.model().get(courseId);
    
    if ( course.status === Course.STATUS_DELETED ){
      yield Course.model().destroy(courseId);
    } else {
      yield Course.model().delete(courseId);
    }

    yield put(actions.deleteCourseSuccess(courseId));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.deleteCourseFail(error));
  }
}

//forgive me Lord because I have sinned
export function* getCoursesRecap() {
  yield put(actions.getCoursesRecapStart());
  try {
    const courses = yield Course.model().list({
      where: [["status", "==", Course.STATUS_ONGOING]]
    });

    yield put(actions.getCoursesRecapSuccess(courses));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.getCoursesRecapFail(error));
  }
}
