import FirestoreModel from '../firestore';
import * as yup from 'yup';

export default class AuthRole extends FirestoreModel {

    /**
     * The collection name in the database
     */
    get collectionName() {
        return 'roles';
    };

    /**
     * The collection name in the database
     */
    get validationSchema() {
        return yup.object().shape({
            name: yup.string().required().label('Nome'),
            permissions: yup.array().of(yup.string()).required().label('Permessi'),
        });
    };

}
