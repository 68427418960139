import React, { useState } from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // ES6

//react-material import
import {
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormLabel
} from "@material-ui/core";

import Select, { makeOptions } from '../select';
import Task from "../../../models/task";

const messagePlaceholders = [ 
  ['customer.name', 'Nome Corsista'],
  ['customer.contactPerson.name', 'Nome Familiare/Referente'],
  ['customer.contactPerson.phone', 'Cellulare Familiare/Referente'],  
  ['course.name', 'Nome Corso'],  
  ['course.welcomeDate', 'Data Primo incontro'],
  ['course.welcomeTime', 'Orario Primo incontro'],
  ['center.teamviewerId', 'Codice Teamviewer Centro'],
  ['center.address', 'Indirizzo Centro'],
  ['center.city', 'Citta Centro'],
  ['center.state', 'Provincia Centro'],
  ['course.onboardingDate', 'Data Spiegazione AR'],
  ['course.onboardingTime', 'Orario Spiegazione AR'],
  ['center.phone', 'Telefono centro'],
  ['center.coachingEmail', 'Email Corso Centro'],
  ['course.startDate', 'Data Inizio Corso'],
  ['course.startTime', 'Orario Inizio Corso'],
  ['course.endDate', 'Data Fine Corso'],
  ['manager.name', 'Responsabile corso'],
  ['manager.email', 'Email Responsabile Corso'],
  ['course.folderURL', 'Link Cartella Controllo (Drive)'],
  ['course.startDays', 'Giorni Mancanti al Corso'],
  ['course.coachingDate', 'Giorno Coaching Difficoltà'],
  ['course.coachingTime', 'Orario Coaching Difficoltà'],
  ['dayPeriod', 'serata/giornata'],
  ['dayPeriodShort', 'sera/giorno'],
  ['dayGreeting', 'Buonasera/Buogiorno'],
 ];


const Activity = props => {
  const [placeholderHelper, setPlaceholderHelper] = useState(0);

  const renderForm = type => {
    if (type === Task.TYPE_MEETING) {
      return (
        <FormControl component="fieldset" fullWidth className="fields-group" >
          <legend>Dettagli Appuntamento</legend>
          <Grid container className="form-maxWidth">
            <Grid item xs={12} sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.inCalendar}
                    name="inCalendar"
                    onChange={props.handleCheck}
                    value={props.inCalendar.toString()}
                    color="primary"
                  />
                }
                label="Mostra in Calendario"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
              <TextField
                variant="outlined"
                autoComplete="off"
                label="Durata (min)"
                type="text"
                name="duration"
                onChange={props.handleChange}
                value={props.duration}
              />
            </Grid>
          </Grid>
        </FormControl>
      );
    } else if (type === Task.TYPE_EMAIL) {
      return (
        <FormControl component="fieldset" fullWidth className="fields-group" >
          <legend>Dettagli Email</legend>  
          <Grid container className="form-maxWidth">
            <Grid container >
              <Grid item xs={12} sm={12} md={6} >
                <FormControl fullWidth variant="outlined">
                  <Select
                    onChange={props.handleChange}
                    value={props.templateEmail}
                    label="Template Email"
                    name="templateEmail"
                    id="templateEmail"                
                    options={props.email_template.map( template => ({ label: template.name, value: template.id }) )}
                  />
                </FormControl>
                <p>E' possibile modificare o aggiungere i template tramite i <a href="https://app-smtp.sendinblue.com/templates" target="_blank" rel="noopener noreferrer">Modelli di Sendinblue</a></p>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="placeholder-helper">
                  <Typography component="h4">
                  Glossario sostituzioni automatiche
                  </Typography>
                  <FormControl fullWidth variant="outlined">
                    <Select
                      className="react-select placeholder-helper__select react-select"
                      onChange={ ({target}) => { setPlaceholderHelper(target.value) }}
                      value={placeholderHelper[0]}
                      name="placeholderHelper"
                      id="placeholderHelper"
                      options={messagePlaceholders.map( ([placeholder, name], i) => ({ label: name, value:i}) )}
                    />
                </FormControl>   
              {
                placeholderHelper !== null && (
                  <pre className="placeholder-helper__result">{ `{{ params.${messagePlaceholders[placeholderHelper][0]} }}` }</pre>              
                ) 
              }
              </Grid>            
            </Grid>            
          </Grid>
        </FormControl>
      );
    } else if (type === Task.TYPE_MANUAL) {
      return (
        <FormControl component="fieldset" fullWidth className="fields-group" >
          <legend>Dettagli Azione manuale</legend>  
          <Grid container className="form-maxWidth">
          <Grid item xs={12} sm={12} md={12} className="quill-container">
            <Typography component="span" className="quill-container__note">
              Descrizione dell'attività
            </Typography>
            <ReactQuill
              modules={Activity.modules}
              name="description"
              placeholder="inserisci note o descrizione dell'attività..."
              value={props.description || ""}
              onChange={props.handleQuill}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              autoComplete="off"
              label="Durata (min)"
              type="text"
              name="duration"
              onChange={props.handleChange}
              value={props.duration}
            />
          </Grid>
        </Grid>
        </FormControl>
      );
    } else if (type === Task.TYPE_MESSAGE) {
      return (
        <FormControl component="fieldset" fullWidth className="fields-group" >
        <legend>Dettagli messaggio</legend>  
        <Grid container className="form-maxWidth">
          <Grid container >
            <Grid item xs={12} sm={12} md={6} className="sms-field-wrapper">
              <Typography component="h4">
                  Template messaggio
              </Typography>
              <TextField
                variant="outlined"
                autoComplete="off"
                multiline
                rows={4}
                rowsMax={8}
                placeholder="Inserisci testo"
                name="templateSms"
                onChange={props.handleChange}
                value={props.templateSms}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="placeholder-helper">
                <Typography component="h4">
                Glossario sostituzioni automatiche
                </Typography>
                <FormControl fullWidth variant="outlined">
                  <Select
                    className="react-select placeholder-helper__select react-select"
                    onChange={ ({target}) => { setPlaceholderHelper(target.value) }}
                    value={placeholderHelper[0]}
                    name="placeholderHelper"
                    id="placeholderHelper"
                    options={messagePlaceholders.map( ([placeholder, name], i) => ({ label: name, value:i}) )}
                  />
              </FormControl>   
            {
              placeholderHelper !== null && (
                <pre className="placeholder-helper__result">{ '${' + messagePlaceholders[placeholderHelper][0] + '}' }</pre>              
              ) 
            }
            </Grid>
          </Grid>
        </Grid>  
        </FormControl>
      );
    }
  };

  return (
    <>
    <FormControl component="fieldset" fullWidth className="fields-group" >
    <Grid container spacing={3} className="form-maxWidth">
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          variant="outlined"
          autoComplete="off"
          label="Nome"
          type="text"
          name="name"
          onChange={props.handleChange}
          value={props.name}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <FormControl fullWidth variant="outlined" required>
          <Select
            label="Tipologia"
            name="type"
            id="type"
            onChange={props.handleChange}
            value={props.type}
            isSearchable={false}
            placeholder="Seleziona la tipologia di attività"
            options={ makeOptions(Task.TASK_TYPES) }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
      <FormControl component="fieldset" fullWidth className="fields-group"> 
        <FormLabel component="legend" style={{marginBottom: "10px"}} className="subtitle1">Accessibile a:</FormLabel>
          <FormGroup row>
            {props.canView &&
              props.roles
                .map(el => (
                  <FormControlLabel
                    key={el.id}
                    control={
                      <Checkbox
                        checked={el.superAdmin ? true : props.canView[el.id]}
                        color="primary"
                        onClick={props.handleCanView}
                        value="1"
                        name={el.id}
                        disabled={el.superAdmin}
                      />
                    }
                    label={el.name}
                  />
                  )
                )
            }
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
    </FormControl>
    {renderForm(props.type)}
    </>
  );
};

Activity.modules = {
  toolbar: [
    // [{ 'header': '1' }, { 'header': '2' }],
    [{ align: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"]
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
    matchers: [
      [
        Node.ELEMENT_NODE,
        (node, delta) => {
          let ops = [];
          delta.ops.forEach(op => {
            if (op.insert && typeof op.insert === "string") {
              ops.push({
                insert: op.insert
              });
            }
          });
          delta.ops = ops;
          return delta;
        }
      ]
    ]
  }
};

const mapStateToProps = state => {
  return {
    roles: state.roles.roles
  };
};

export default connect(
  mapStateToProps,
  undefined
)(Activity);
