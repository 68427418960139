import React from "react";
import Link from "@material-ui/core/Link";
import { SortableHandle } from "react-sortable-hoc";
import DragIndicator from "@material-ui/icons/DragIndicator";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const DragHandle = SortableHandle(() => (
  <DragIndicator className="label__drag" />
));

const Labels = props => {
  return (
    <div className="label">
      <DragHandle />
      <span
        className="label__swatch"
        style={{ backgroundColor: props.label.color }}
      />
      <span className="label__name">{props.label.name}</span>
      <div className="actions-group actions-group--edit label__actions-group">
      <Link
          onClick={() => props.modifyId(props.label.id)}
          className="action action--edit"
        >
          <EditIcon />
          Modifica
        </Link>
        <Link
          onClick={() => props.removeStatus(props.index, props.label.id)}
          className="action action--delete"
        >
          <DeleteIcon />
          Elimina
        </Link>
      </div>
    </div>
  );
};

export default Labels;
