import FirestoreModel from '../firestore';
import * as yup from 'yup';
import { firebase } from "../../firebase/firebase";
import { userCan } from '../../shared/utility';
import ModelCriteria from '../criteria';
import CurrentUser from './current';

export default class User extends FirestoreModel {

    static get defaults(){
        return {
            name: "",
            surname: "",
            email: "",
            phone: "",
            role: "",
            active: false,
            googleCalendarId: ""
          };
    }

    /**
     * The collection name in the database
     */
    get collectionName() {
        return 'users';
    };

    /**
     * The collection name in the database
     */
    get validationSchema() {
        return yup.object().shape({
            name: yup.string().required("Nome obbligatorio"),
            surname: yup.string().required("Cognome obbligatorio"),
            email: yup
                .string()
                .email("Email non valida")
                .required("Email obbligatoria"),
            phone: yup.string().required("Telefono obbligatorio"),
            role: yup.string().required("Ruolo obbligatorio"),
            active: yup.boolean().required("Stato obbligatorio"),
            googleCalendarId: yup.string().default(''),
        });
    };

    /**
     * Get the current logged in user 
     * 
     * @returns {Promise} A promise returning an object containing the user data
     */
    async me() {
        if (!firebase.auth().currentUser) {
            return Promise.resolve(false);
        }

        const loggedUserId = firebase.auth().currentUser.uid;

        return this.get(loggedUserId);
    }

    /**
     * List documents
     * 
     * @return {void} 
     */
    async list(criteria = {}) {
        
        if ( userCan( 'users__list' ) ){
            return super.list(criteria);
        }

        if ( !(criteria instanceof ModelCriteria) ) {
            criteria = new ModelCriteria(criteria);
        }
   
        // @TODO find another way to prevent recursion
        if ( !this.disableRbac && userCan('users__list--own-center')) {
            
            // Make the query filterd for each center assigned to the current user
            return CurrentUser.model()
                .centers()
                .then( myCenters => {
                    this.disableRbac = true;
                    return myCenters.map(
                        (center) => 
                            criteria.clone().where('_currentCenters', 'array-contains', center)
                    )
                })                    
                .then(this.listTogheter.bind(this))
                .then((results) => {
                    this.disableRbac = false;
                    return results;
                })

        }

        return super.list(criteria);
    }    

    invite() {
        // @TODO
        return true;
    }

}
