import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";

//table
import ReactTable from "react-table";
import "react-table/react-table.css";

import matchSorter from "match-sorter";
import { PaginateButtonPrev, PaginateButtonNext } from "../../../../shared/table/pagination";
import { userCan } from "../../../../../shared/utility";

export default props => {

  return (
    <div className="react-table">
      <ReactTable
        data={props.course_templates}
        columns={[
          {
            Header: "Nome",
            id: "name",
            width: 300,
            accessor: 'name',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["name"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra per..."
                className="table-filter"
              />
            ),

          },
          {
            Header: "Prezzo",
            id: "price",
            accessor: "price",
            width: 120,
            filterable: false,
            Cell: row => `${row.value} €`
          },
          {
            Header: "Durata",
            id: "duration",
            accessor: 'duration',
            width: 120,
            filterable: false,
            Cell: row => `${row.value} gg`
          },
          {
            Header: "Disponibile",
            id: "available",
            accessor: "available",
            width: 180,
            Cell: row => row.original.available ? 'Si' : 'No',
            filterMethod: (filter, row) => {
              if (filter.value === "all") {
                return true;
              }
              if (filter.value === "true") {
                return row[filter.id] === true;
              }
              return row[filter.id] === false;
            },
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
                className="table-filter"
              >
                <option value="all">Mostra Tutti</option>
                <option value={true}>Disponibile</option>
                <option value={false}>Non Disponibile</option>
              </select>
            )
          },
          {
            Header: "Azioni",
            filterable: false,
            accessor: "actions",
            width: 200,
            Cell: row => (
              <div className="actionTableContainer">
                { userCan('course_template__update') && (
                <Tooltip
                  title="Modifica"
                  enterDelay={100}
                  position="bottom-end"
                >
                  <IconButton
                    className="iconButton colorGreen colorWhite"
                    onClick={() => props.goToEdit(row.original.id)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                )}
                { userCan('course_template__get') && userCan('course_template__create') && (
                <Tooltip title="Clona" enterDelay={100}>
                  <IconButton
                    className="iconButton colorGreen colorWhite"
                    onClick={() => props.openDialog(row.original.id, true)}
                  >
                    <FileCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                )}
                { userCan('course_template__delete') && (
                <Tooltip title="Elimina" enterDelay={100}>
                  <IconButton
                    className="iconButton colorRed colorWhite"
                    onClick={() => props.openDialog(row.original.id, false)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                )}
              </div>
            )
          }
        ]}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        defaultPageSize={8}
        previousText={<PaginateButtonPrev />}
        nextText={<PaginateButtonNext />}
        pageText="Pagina"
        ofText="di"
        rowsText="per pagina"
        noDataText="Nessun Modello di Corso Trovato"
      />
      <br />
    </div>
  );
};
