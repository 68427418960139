import React, { useState, useEffect } from "react";
import Popover, { ArrowContainer } from "react-tiny-popover";
import moment from "moment";
import CourseTask from "../../../../../models/course/task";

const Ball = (props) => {

  const { task, courseId } = props;
  const [isOpen, setOpen] = React.useState(false);

  function closeTooltip() {
    setOpen(false);
  }

  function openTooltip() {
    setOpen(true);
  }

  return (
    <div key={task.id}>
      <Popover
        isOpen={isOpen}
        onClickOutside={closeTooltip}
        containerStyle={{ borderRadius: "6px", top: "215px" }}
        content={({ position, targetRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            targetRect={targetRect}
            popoverRect={popoverRect}
            arrowColor={"white"}
            arrowSize={20}
            arrowStyle={{
              bottom: "1px",
              filter: "drop-shadow(0px 0px 0px #141414)"
            }}
          >
            <div className="tooltip">
              <label>Attività</label>
              <a href={`dettaglio-corso/${courseId}/task/${task.id}?d`}>
                {task.name}
              </a>
              <div className="popoverStatusAndDueDate">
                <div className="popoverStatusAndDueDate popoverStatusAndDueDate__subcontainer">
                  <label>Status</label>
                  <span className="tooltip__row">
                    <span
                      key={task.id}
                      className="circle"
                      style={{
                        background: task.status_color
                          ? task.status_color
                          : "#B5B5B5"
                      }}
                    />
                    {task.status_name}
                  </span>
                </div>
                <div className="popoverStatusAndDueDate popoverStatusAndDueDate__subcontainer">
                  <label>Scadenza</label>
                  {task.dueDateTime ? moment(task.dueDateTime).format("DD-MM-YYYY HH:mm") : "N/D"}
                </div>
              </div>
            </div>
          </ArrowContainer>
        )}
      >
        <span
          key={task.id}
          className="circle"
          style={{
            background: task.status_color ? task.status_color : "#B5B5B5"
          }}
          onClick={openTooltip}
        />
      </Popover>
    </div>
  );
};

const BallsContainer = (props) => {

  const {
    groups,
    courseId
  } = props;

  const [tasks, setTasks] = useState([]);

  useEffect(() => {

    CourseTask.model({ courseId }).list( { orderBy: [[ 'position', 'asc' ]] } )
      .then((taskList) => {

        taskList.forEach(task => {
          // Set the status_name and status_color props
          if (task.statuses) {
            let statusObj = task.statuses.find(status => status.id === task.status);
            task.status_name = statusObj ? statusObj.name : "N/A";
            task.status_color = statusObj ? statusObj.color : "";
          }
        });

        setTasks(taskList);
      })

  }, [courseId])

  return (
    <div className="balls-cell">
      {groups && groups.map(group => (
        <div className="balls-container" key={group.id}>
          <label className="balls-container__title">{group.name}</label>
          <div className="balls balls-container__balls">
            {
              tasks
                .filter(task => task.group === group.id)
                .map(task => <Ball key={task.id} task={task} courseId={courseId} />)
            }
          </div>
        </div>
      ))}
    </div>
  );

};

export default BallsContainer;