import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions/index";

import Layout from "../../../../hoc/Layout";
import CourseTaskPreview from './_list/preview';

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogContent";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconBack from "@material-ui/icons/ChevronLeft";
import { Paper } from "@material-ui/core";
import IconSchool from "@material-ui/icons/School";
import Customer from "../../../../../models/customer";
import Course from "../../../../../models/course";
import QueueEmail from "../../../../../models/queue/email";
import { ProfileIcon } from "../../../../shared/icons";

const initialState = {
  attachments: [],
  dueDateTime: "",
  group: "",
  notes: "",
  owner: "",
  position: "",
  status: "",
  task: ""
};

const disorderNames = new Map(Customer.DISORDERS);
const customerSchoolClassesMap = new Map(Customer.SCHOOL_CLASSES);
const customerGenderMap = new Map(Customer.GENDERS);
const courseStatusMap = new Map(Course.COURSE_STATUSES);

const courseSpec = props => {
  const [courseSpec, setCourseSpec] = useState(initialState);
  const [groups, setGroups] = useState([]);
  const [dialog, setDialog] = useState({
    open: false,
    id: null
  });

  const courseId = props.match.params.id;

  useEffect(() => {
    props.onGetUsers();
    return function cleanup() {
      props.onResetCourseSpec();
      setCourseSpec(initialState);
    };
  }, []);

  useEffect(() => {
    if (props.location.data) props.onGetCourseSpec(courseId);
  }, [props.location.data]);

  useEffect(() => {
    if (courseId && courseSpec === initialState) {
      props.onGetCourseSpec(courseId);
    }
    if (props.courseSpec) {
      setCourseSpec({ ...props.courseSpec });
      setGroups(props.courseSpec.groups);
    }
  }, [props.courseSpec]);

  const goToDetails = async id => {
    let info_task = props.courseSpec.tasks.find(el => el.id === id);
    props.history.push({
      pathname: `/dettaglio-corso/${courseId}/task/${id}`,
      data: { info_task }
    });
  };

  const sendQueue = () => {
    let info_task = props.courseSpec.tasks.find(el => el.id === dialog.id);
    let data = {
      ...info_task,
      courseId: props.courseSpec.id,
      task_id: dialog.id,
      type: QueueEmail.QUEUE_TYPE
    };

    props.onSendQueue(data);
    closeDialog();
  };

  const openDialog = id => {
    setDialog({
      ...dialog,
      open: true,
      id
    });
  };

  const closeDialog = () => {
    setDialog({
      ...dialog,
      open: false,
      id: null
    });
  };

  const onUpdateTask = (taskId, data) => { 
    props.onUpdateCourseTask(courseId, taskId, data);
  }

  return (
    <Layout>
      <div className="box-layout">
        <div className="topSection">
          <h1>Dettaglio Corso</h1>
          <div className="topButtons">
            <Button
              variant="contained"
              onClick={() => props.history.goBack()}
              className="button button--outlined"
            >
              <IconBack/> Indietro
            </Button>
          </div>
        </div>
        {!props.loading && props.courseSpec ? (
          <>
            <div className="info-container">
              <Paper className="info-container__left-box">
                <div className="info-container__head">
                  <span className="info-container__title">
                    {props.courseSpec && props.courseSpec._customerName}
                  </span>
                  <span className="info-container__description">
                    <ProfileIcon />
                    Info corsista
                  </span>
                </div>
                <div className="info-container__hr" />
                <div className="mt15 info-container__content">
                  <div className="info-container__sub">
                    <span>
                      <strong>Sesso: </strong>
                      {props.courseSpec && customerGenderMap.get(props.courseSpec.customer.gender)}
                    </span>
                    <span>
                      <strong>Nato il: </strong>
                      {props.courseSpec &&
                        moment(props.courseSpec.customer.birthDate).format("DD/MM/YYYY")}{" "}
                      ({moment().diff(props.courseSpec.customer.birthDate,"years")}{" "}anni)
                    </span>
                    <span>
                      <strong>Classe Frequentata: </strong>
                      {props.courseSpec && customerSchoolClassesMap.get(props.courseSpec.customer.schoolClass)}
                    </span>
                    <span>
                      <strong>Località: </strong>
                      {props.courseSpec &&
                        props.courseSpec.customer.contactPerson.city}
                    </span>
                  </div>
                  <div className="info-container__sub">
                    <span>
                      <strong>Certificato il: </strong>
                      {props.courseSpec &&
                      props.courseSpec.customer.dsaDate ? moment(props.courseSpec.customer.dsaDate).format("DD/MM/YYYY"): "N/D"}
                    </span>
                    <span>
                      <strong>Difficoltà: </strong>
                      {props.courseSpec &&
                        props.courseSpec.customer.disorders
                          .map(disorderId => disorderNames.get(disorderId) || disorderId )
                          .join(", ")}
                    </span>

                    <span>
                      <strong>Genitore referente: </strong>
                      {props.courseSpec && props.courseSpec.customer && props.courseSpec.customer.contactPerson ?  
                        `${props.courseSpec.customer.contactPerson.name} ${props.courseSpec.customer.contactPerson.surname}` : 'N/A' }
                    </span>

                    <span>
                      <strong>Email contatto: </strong>
                      {props.courseSpec && props.courseSpec.customer && props.courseSpec.customer.contactPerson ?  
                        props.courseSpec.customer.contactPerson.email : 'N/A' }
                    </span>
                  </div>
                </div>
              </Paper>
              <Paper className="info-container__right-box">
                <div className="info-container__head">
                  <span className="info-container__title">
                    {props.courseSpec && props.courseSpec.name}
                  </span>
                  <span className="info-container__description">
                    <IconSchool />
                    Dettagli corso
                  </span>
                </div>
                <div className="info-container__hr" />
                <div className="mt15 info-container__content">
                  <div className="info-container__sub">
                    <span>
                      <strong>Centro Frequentato: </strong>
                      {props.courseSpec ? props.courseSpec._centerName : 'N/D' }
                    </span>
                    <span>
                      <strong>Responsabile corso: </strong>
                      {props.courseSpec ? props.courseSpec._managerName : 'N/D' }
                    </span>
                    <span>
                      <strong>Cartella Controllo: </strong>
                      {props.courseSpec ? (<a href={props.courseSpec.folderURL} target="_blank" rel="noopener noreferrer" >APRI</a> ) : 'N/D' }
                    </span>                    
                  </div>
                  <div className="info-container__sub">
                    <span>
                      <strong>Stato: </strong>
                      {props.courseSpec ? courseStatusMap.get(props.courseSpec.status) : 'N/D'}
                    </span>
                    <span>
                      <strong>Data di inizio: </strong>
                      {props.courseSpec ? moment(props.courseSpec.startDateTime).format("DD-MM-YYYY") : 'N/D' }
                    </span>
                    <span>
                      <strong>Data di fine: </strong>
                      {props.courseSpec ? moment(props.courseSpec.endDate).format("DD-MM-YYYY") : 'N/D' }
                    </span>
                  </div>
                </div>
              </Paper>
            </div>
            {groups.map(group => {
              return (
                <ExpansionPanel key={group.id} className="accordion-panel" TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={'group-' + group.id}
                    id={'group-' + group.id}
                  >
                    <Typography className="accordion-panel__title">
                      {group.name}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="tasks__heading">
                      <div>Azioni</div>                      
                      <div>Nome</div>
                      <div>Tipo</div>
                      <div>Scadenza</div>
                      <div>Stato</div>
                      <div>Responsabile</div>
                      <div>Note</div>
                    </div>
                    <div className="tasks__list">
                      {props.courseSpec.tasks.filter(task => task.group === group.id).map((task) => (
                        <CourseTaskPreview
                          key={task.id}
                          task={task}
                          users={props.users}
                          course={props.courseSpec}
                          goToDetails={goToDetails}
                          openDialog={openDialog}
                          onUpdateTask={onUpdateTask}
                        />
                    ))}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
          </>
        ) : (
          <div className="box-layout box-layout__progress">
            <CircularProgress
              size={80}
              thickness={4}
              className="main-progress"
            />
          </div>
        )}
      </div>
      <Dialog
        open={dialog.open}
        
        keepMounted
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title-delete"
        aria-describedby="alert-dialog-slide-description-delete"
      >
        <DialogTitle
          className="dialogTitle"
          id="alert-dialog-slide-title-delete"
        >
          {"Conferma Azione di invio"}
        </DialogTitle>
        <DialogContent className="dialogContent">
          <DialogContentText id="alert-dialog-slide-description-delete">
            Vuoi davvero inviare la email {props.courseSpec && props.courseSpec.customer && props.courseSpec.customer.contactPerson ?  
                        `all'indirizzo ${props.courseSpec.customer.contactPerson.email}` : '' } ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
            variant="contained"
            className="button button--cancel"
          >
            <IconBack /> Indietro
          </Button>
          <Button
            onClick={sendQueue}
            variant="contained"
            className="button"
          >
            Invia
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};
const mapStateToProps = state => {
  return {
    loading: state.course.loading,
    courseSpec: state.course.courseSpec,
    courseMessage: state.course.sendMessage,
    users: state.user.users,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetUsers: () => dispatch(actions.getUsers()),
    onGetCourseSpec: id => dispatch(actions.getCourseSpec(id)),
    onResetCourseSpec: () => dispatch(actions.resetCourseSpec()),
    onSendQueue: queue => dispatch(actions.sendQueue(queue)),
    onUpdateCourseTask: (courseId, taskId, data) => dispatch(actions.courseTaskUpdate(courseId, taskId, data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(courseSpec));
