import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
//table
import ReactTable from "react-table";
import "react-table/react-table.css";

import matchSorter from "match-sorter";

import moment from "moment";
import Course from "../../../../../models/course";
import { PaginateButtonPrev, PaginateButtonNext } from "../../../../shared/table/pagination";
import { userCan } from "../../../../../shared/utility";

const courseStatusMap = new Map(Course.COURSE_STATUSES);

export default props => {
  return (
    <div className="react-table">
      <ReactTable
        data={props.courses}
        columns={[
          {
            Header: "Azioni",
            filterable: false,
            accessor: "actions",
            width: 145,
            Cell: row => (
              <div className="actionTableContainer">
                { ( userCan('course__get--own-center') ) && (
                <Tooltip title="Apri" enterDelay={300} position="bottom-end">
                  <IconButton
                    className="iconButton colorGreen colorWhite"
                    onClick={() => props.goToEdit(row.original.id)}
                    size="small"
                  >
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                )}
                { ( userCan('course__update--own-center') )  && (
                <Tooltip title="Modifica" enterDelay={300} position="bottom-end">
                  <IconButton
                    className="iconButton colorGreen colorWhite"
                    onClick={() => props.goToModify(row.original.id)}
                    size="small"
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                )}
                { (userCan('course__delete--own-center') || userCan('course__archive--own-center')) && (
                <Tooltip title="Elimina" enterDelay={300}>
                  <IconButton
                    className="iconButton colorRed colorWhite"
                    onClick={() => props.openDialog(row.original.id)}
                    size="small"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                )}
              </div>
            )
          },          
          {
            Header: "Nome Corsista",
            id: "customerName",
            width: 200,
            accessor: '_customerName',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["customerName"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra per..."
                className="table-filter"
              />
            ),

          },
          {
            Header: "Tipo di Corso",
            id: "courseName",
            width: 180,
            accessor: '_courseName',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: ["courseName"]
              }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra per..."
                className="table-filter"
              />
            ),

          },
          /*           {
            Header: "Familiare Referente",
            id: "relatives",
            maxWidth: 150,
            accessor: d =>
              `${d.customer.relatives.name} ${d.customer.relatives.surname}`,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["relatives"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra per..."
              />
            ),
          }, */
          {
            Header: "Responsabile corso",
            id: "managerName",
            width: 200,
            accessor: '_managerName',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["managerName"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra per..."
                className="table-filter"
              />
            ),

          },
          {
            Header: "Inizio",
            id: "startDateTime",
            width: 120,
            accessor: 'startDateTime',
            filterable: false,
            Cell: row => row.value ? moment(row.value).format("DD-MM-YYYY") : '',
          },
          {
            Header: "Fine",
            id: "endDate",
            width: 120,
            accessor: 'endDate',
            filterable: false,
            Cell: row => row.value ? moment(row.value).format("DD-MM-YYYY") : '',
          },
          {
            Header: "Centro",
            id: "centerName",
            accessor: "_centerName",
            width: 150,
            filterMethod: (filter, row) => {
              if (filter.value === "all") {
                return true;
              }
              return row._original.center.id === filter.value;
            },
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
                className="table-filter"
              >
                <option value="all">Mostra Tutti</option>
                {props.centers.map(el => (
                  <option key={el.id} value={el.id}>{el.name}</option>
                ))}
              </select>
            ),
            show: userCan('course__list'),
          },
          {
            Header: "Assegnate",
            id: "assignedTasks",
            accessor: "_assignedTasks",
            width: 110,
            Cell: row => `${row.original._assignedTasks}/${row.original._totalTasks}`,
            filterable: false
          },
          {
            Header: "Programmate",
            id: "scheduledTasks",
            width: 130,
            accessor: '_scheduledTasks',
            filterable: false,
          },
          {
            Header: "Completate",
            id: "completedTasks",
            width: 120,
            accessor: '_completedTasks',
            filterable: false,
            Cell: row => `${row.original._completedTasks}/${row.original._totalTasks}`
          },
          {
            Header: "Stato",
            id: "status",
            accessor: "status",
            width: 130,
            Cell: row => courseStatusMap.get(row.value),
            filterMethod: (filter, row) => {
              return (filter.value === "all" && row[filter.id] !== Course.STATUS_DELETED) || row[filter.id] === filter.value;
            },
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
                className="table-filter"
              >
                <option value="all">Mostra Tutti (attivi)</option>
                {Course.COURSE_STATUSES.map(
                  ([statusValue, statusLabel]) => (<option key={statusValue} value={statusValue}>{statusLabel}</option>))
                }
              </select>
            )
          }
        ]}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
				defaultFiltered={[
					{
						id: 'status',
						value: 'all'
					}
				]}        
        defaultPageSize={8}
        // className="-striped -highlight"
        previousText={<PaginateButtonPrev />}
        nextText={<PaginateButtonNext />}
        pageText="Pagina"
        ofText="di"
        rowsText="per pagina"
        noDataText="Nessun Corso Trovato"
      />
      <br />
    </div>
  );
};
