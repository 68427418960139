export default class ModelCriteria {

    _where = new Set();
    _orderBy = new Set();
    _limit = null;

    /**
     * Setup the model
     * 
     * @param {object?} criteriaSpec 
     */
    constructor(criteria = null) {
        if ( criteria ) this.set(criteria);
    }

    /**
     * Reset the model and sets the passed conditions
     * 
     * @param {object} criteria
     * 
     * @return {self}
     */
    set( criteria ){
        const self = this;

        if ( criteria.where ) {
            criteria.where.forEach( ([ field, operator, value ]) => self.where( field, operator, value ) ) ;
        }
        
        if ( criteria.orderBy ) {
            // Allow users to specify orderBy with only the field name: {orderBy: 'firstName'};
            if ( typeof criteria.orderBy === 'string' ){
                criteria.orderBy = new Set([[criteria.orderBy]]);
            }
            
            criteria.orderBy.forEach( (orderBy) => 
                // Allow users to specify both plain arrays [ 'firstName' ] and directional [ [ 'firstName', 'asc' ] ]
                Array.isArray(orderBy) ? self.orderBy( ...orderBy ) : self.orderBy( orderBy )  
            );
        }

        criteria.limit && this.limit(criteria.limit);

        return this;
    }

    where( field, operator, value ){

        // only way to check if conditions are unique with DocumentReferences
        if ( Array.from(this._where).find( 
            ([ field1, operator1, value1 ]) => ( field1 === field ) && ( operator === operator1 ) && ( value1 === value || value1.id === value.id ) ) 
        ) return this

        this._where.add([ field, operator, value ]);
        return this;
    }

    orderBy( field, direction = 'asc' ){
        this._orderBy.add([ field, direction ]);
        return this;
    }    

    limit( limit ) {
        this._limit = limit;
        return this;
    }    

    getLimit(){
        return this._limit;
    }

    getWhere(){
        return Array.from(this._where);
    }

    getOrderBy(){
        return Array.from(this._orderBy);
    }    

    /**
     * Merge actual conditions with the passed ones
     * 
     * @param {ModelCriteria} criteria 
     */
     mergeWith(criteria) {

        criteria._where.forEach( this._where.add.bind(this._where) ) ;
        criteria._orderBy.forEach( this._orderBy.add.bind(this._orderBy) );
        
        if (criteria._limit) {
            this.limit(criteria._limit);
        }
    
        return this;
    }

    serialize(){
        return {
            where: this.getWhere(),
            orderBy: this.getOrderBy(),
            limit: this.getLimit(),
        };
    }

    clone(){
        const newInstance = new ModelCriteria();

        newInstance.mergeWith(this);

        return newInstance;
    }

}