import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  FormControl,
  Button,
} from "@material-ui/core";

import provinces from "../../../shared/json/province";
import Select from "../select";
import { Form, Field, Formik, useFormikContext } from "formik";
import classnames from 'classnames';

const RelativeForm = props => {

  const MuiTextField = (fieldProps) => {
    const { errors } = useFormikContext();
    const { name } = fieldProps;
    const errorText = errors[name];

    return <TextField
      variant="outlined"
      autoComplete="off"
      type="text"
      fullWidth
      error={Boolean(errorText)}
      helperText={errorText}
      {...fieldProps}
    />
  };

  return (
    <Formik
    enableReinitialize
    {...props}
    >
    {(formProps) => { 
      
      const { errors, dirty, isValid, validateForm} = formProps;

      useEffect(() =>{
          validateForm();
      }, [props.isPrimary])

      return (        
        <Form className={ classnames('relative', { 'is-dirty': dirty }) } >
            <Grid container spacing={3} className="form-maxWidth" style={{marginBottom: "15px"}}>
              <Grid item xs={12} sm={12} md={6}>
                <Field name="type" as={MuiTextField} required label="Ruolo (padre, madre etc.." />
              </Grid>
            </Grid>
            <Grid container spacing={3} className="form-maxWidth">
              <Grid item xs={12} sm={6} md={6}>
                <Field name="name" as={MuiTextField} required label="Nome" />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Field name="surname" as={MuiTextField} required label="Cognome" />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Field name="email" as={MuiTextField} required={props.isPrimary} label="Email" />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Field name="phone" as={MuiTextField} required={props.isPrimary} label="Telefono" />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Field name="qualification" as={MuiTextField} label="Titolo di studio" />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Field name="job" as={MuiTextField} label="Professione" />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Field name="address" as={MuiTextField} label="Indirizzo" />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Field name="city" as={MuiTextField} label="Città" />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth className="select">
                  <Field
                    name="province"
                  >{({ field, form }) => {
                      return (
                        <Select
                          {...field}
                          label="Province"
                          error={Boolean(errors.province)}
                          helperText={
                            errors.province &&
                            `${errors.province}`
                          }
                          options={provinces}
                        />
                      );
                    }}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
              { dirty && (<Button type="submit" variant="outlined" disabled={!isValid} className="button button--outlined">Aggiorna</Button>) }
              <Button color="secondary" variant="outlined" disabled={props.isPrimary} onClick={ props.onSetPrimary } className="button button--outlined">Imposta come principale</Button>
              </Grid>              
            </Grid>
        </Form>
    )}}
  </Formik>
  );
};

export default  RelativeForm;
