import { updateObject } from "../../../shared/utility";
import * as actionTypes from "../../types/types";

const initialState = {
  activity_group: [],
  activity: null,
  save_activity_success: false,
  loading: false,
  message: null,
  error: null
};

export const getActivityGroupStart = (state, action) => {
  return updateObject(state, { loading: true });
};
export const getActivityGroupSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    activity_group: action.payload.activity_group
  });
};
export const getActivityGroupFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};
export const getActivityStart = (state, action) => {
  return updateObject(state, { loading: false });
};
export const getActivitySuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    activity: action.payload.activity
  });
};
export const getActivityFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};
export const saveActivityStart = (state, action) => {
  return updateObject(state, { loading: false });
};
export const saveActivitySuccess = (state, action) => {
  let modify_activity_group = { ...state }.activity_group;
  if (action.payload.activity.id) {
    let modiyId = modify_activity_group
      .map(el => el.id)
      .indexOf(action.payload.activity.id);
    modify_activity_group[modiyId] = action.payload.activity;
  } else {
    modify_activity_group.push(action.payload.activity);
  }
  return updateObject(state, {
    loading: false,
    activity: null,
    message: "Attività salvata con successo",
    save_activity_success: true,
    activity_group: modify_activity_group
  });
};
export const saveActivityFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore nel salvataggio. Riprovare.",
    error: action.payload.error
  });
};
export const deleteActivityStart = (state, action) => {
  return updateObject(state, { loading: false });
};
export const deleteActivitySuccess = (state, action) => {
  let remove_activity_group = { ...state }.activity_group;
  let removeId = remove_activity_group
    .map(el => el.id)
    .indexOf(action.payload.id);
  remove_activity_group.splice(removeId, 1);
  return updateObject(state, {
    loading: false,
    activity_group: remove_activity_group,
    message: "Attività eliminata con successo"
  });
};
export const deleteActivityFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};
export const resetActivity = (state, action) => {
  return updateObject(state, { activity: null, save_activity_success: false });
};
export const resetActivityMessage = (state, action) => {
  return updateObject(state, { message: null });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACTIVITY_GROUP_START:
      return getActivityGroupStart(state, action);
    case actionTypes.GET_ACTIVITY_GROUP_SUCCESS:
      return getActivityGroupSuccess(state, action);
    case actionTypes.GET_ACTIVITY_GROUP_FAIL:
      return getActivityGroupFail(state, action);
    case actionTypes.GET_ACTIVITY_START:
      return getActivityStart(state, action);
    case actionTypes.GET_ACTIVITY_SUCCESS:
      return getActivitySuccess(state, action);
    case actionTypes.GET_ACTIVITY_FAIL:
      return getActivityFail(state, action);
    case actionTypes.SAVE_ACTIVITY_START:
      return saveActivityStart(state, action);
    case actionTypes.SAVE_ACTIVITY_SUCCESS:
      return saveActivitySuccess(state, action);
    case actionTypes.SAVE_ACTIVITY_FAIL:
      return saveActivityFail(state, action);
    case actionTypes.DELETE_ACTIVITY_START:
      return deleteActivityStart(state, action);
    case actionTypes.DELETE_ACTIVITY_SUCCESS:
      return deleteActivitySuccess(state, action);
    case actionTypes.DELETE_ACTIVITY_FAIL:
      return deleteActivityFail(state, action);
    case actionTypes.RESET_ACTIVITY:
      return resetActivity(state, action);
    case actionTypes.RESET_ACTIVITY_MESSAGE:
      return resetActivityMessage(state, action);
    default:
      return state;
  }
};

export default reducer;
