import React, { useState, useEffect } from "react";
import arrayMove from "array-move";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions/index";
import { PlusIcon } from "../../../../shared/icons";
import IconSave from "@material-ui/icons/Save";
import IconBack from "@material-ui/icons/ChevronLeft";
import Layout from "../../../../hoc/Layout";
import { lastId } from '../../../../../shared/utility';
import { useDialog } from '../../../../shared/dialogs';
import IconSchool from "@material-ui/icons/School";

import {
  Button,
} from "@material-ui/core";

import SortableGroupTasks from "../../../../shared/Sortable/SortableTemplates";
import CourseTemplateForm from "./form";

import {
  addGroupDialog,
  addTaskDialog,
  unsavedFormDialog,
  deleteGroupDialog,  
  deleteTaskDialog
} from './dialogs';
import CourseTemplateTask from "../../../../../models/course/template/task";
import Task from "../../../../../models/task";
import errorHandler from "../../../../../shared/errorReporting";
import CourseTemplate from "../../../../../models/course/template";

const initialState = {
  available: false,
  duration: "",
  name: "",
  price: ""
};


const CourseTemplatePage = props => {
  const [course_template, setCourseTemplate] = useState(initialState);
  const [courseTasks, setCourseTasks] = useState([]);
  const [courseGroups, setCourseGroups] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [DeleteGroupDialog, openDialogRemoveGroup] = useDialog(deleteGroupDialog, { id: null })
  const [AddGroupDialog, openDialogAddGroup] = useDialog(addGroupDialog, { id: null })
  const [AddTaskDialog, openDialogTask] = useDialog(addTaskDialog, { id: null, group: null })
  const [UnsavedFormDialog, openDialogBack] = useDialog(unsavedFormDialog, { id: null })
  const [DeleteTaskDialog, openDialogRemoveTask] = useDialog(deleteTaskDialog, { id: null })

  const courseTemplateId = props.match.params.id;

  useEffect(() => {
    if ( !courseTemplateId ) return;
    
    CourseTemplate.model().get(courseTemplateId)
      // Set already saved course groups
      .then( course_templates => {
        course_templates.groups && setCourseGroups(course_templates.groups);
        return course_templates;
      })
      .then(setCourseTemplate)
      .catch(errorHandler.report);

    CourseTemplateTask.model({ courseTemplateId })
      .list( { orderBy: [ 'position' ] } )
      .then(courseTemplateTasks => 
        // cycle all tasks
        Promise.all( courseTemplateTasks.map( 
          (courseTemplateTask) =>
            // for each one get task data and store it in the spec prop
            Task.model().getByRef(courseTemplateTask.task)
              .then( task => Object.assign(courseTemplateTask, { spec: task} ) )
          )
        )
      )
      // When all task merges are completed set courseTasks data
      .then((courseTasks) => {
        setCourseTasks(courseTasks);
      })
      .catch(errorHandler.report);      
        
  }, [courseTemplateId]);


  useEffect(() => {
    props.onGetActivityGroup();

    return function cleanup() {
      props.onResetCourseTemplate();
      setCourseTemplate(initialState);
    };
  }, []);

  useEffect(() => {
    if (props.saveTemplateSuccess) props.history.push("/modelli-corso");
  }, [props.saveTemplateSuccess]);

  // useEffect(() => {
  //   if (props.match.params.id){
  //     props.onGetCourseTemplate(props.match.params.id);
  //   }
  // }, [props.match.params.id]);

  const handleChange = ({ target }) => {
    setCourseTemplate({
      ...course_template,
      [target.name]: target.value
    });
    setHasUnsavedChanges(true);
  };

  const handleCheck = ({ target }) => {
    setCourseTemplate({
      ...course_template,
      [target.name]: target.checked
    });
    setHasUnsavedChanges(true);
  };
  const onSortEnd = ({ oldIndex, newIndex, collection }) => {

    // Regenerate the group-only array of tasks 
    const groupTasks = courseTasks.filter( (task) => task.group === collection );

    //Calculate the index of the tasks in the global task array
    const globalOldIndex = courseTasks.findIndex( (task) => task.id === groupTasks[oldIndex].id );
    const globalNewIndex = courseTasks.findIndex( (task) => task.id === groupTasks[newIndex].id );

    // Move the tasks in the global array
    setCourseTasks(() => arrayMove(courseTasks, globalOldIndex, globalNewIndex));

    setHasUnsavedChanges(true);
  };

  const addGroup = group => {
    setCourseGroups(courseGroups.concat( { ...group, isNew: true, id: lastId(courseGroups)+1 } ));
    setHasUnsavedChanges(true);
  };

  const removeGroup = (groupId) => {

    let courseTemplateTask = CourseTemplateTask.model({courseTemplateId});

    const taskDeleteBatch = courseTemplateTask.createBatch();
    const groupIndex = courseGroups.findIndex( (group) => group.id === groupId );

    // Delete group's tasks
    let newCourseTasks = courseTasks.reduce( (tasks, task) => {
      
      if ( task.group !== groupId ) {
        tasks.push( task );
      } else if (courseTemplateId) { 
        taskDeleteBatch.delete( courseTemplateTask.ref(task.id));
      }

      return tasks;

    }, []);

    taskDeleteBatch.commit().catch(errorHandler.report);

    // Delete group
    courseGroups.splice(groupIndex, 1);

    setCourseTasks(newCourseTasks);
    setCourseGroups(courseGroups);
    setHasUnsavedChanges(true);
  };

  const addTask = (task, group) => {    
    setCourseTasks( courseTasks.concat({ spec: task, group, isNew: true, id: courseTasks.length }) );
    setHasUnsavedChanges(true);
  };

  const removeTask = (courseTaskId) => {    
    let courseTask = courseTasks.find((courseTask) => courseTask.id === courseTaskId );
    let courseTaskIndex = courseTasks.findIndex( (courseTask) => courseTask.id === courseTaskId );
    
    courseTasks.splice(courseTaskIndex, 1);

    if (courseTemplateId && !courseTask.isNew) {
      CourseTemplateTask.model({ courseTemplateId })
        .delete(courseTaskId)
          .catch(errorHandler.report);
    }

    setCourseTasks(courseTasks.slice(0));
    setHasUnsavedChanges(true);
  };

  const saveCourseTemplate = async () => {
      
    await props.onSaveCourseTemplate({ 
      ...course_template,
      tasks: courseTasks,
      groups: courseGroups
    });

    //Reset Course Template
    setCourseTemplate( initialState );
    setCourseTasks([]);
    setCourseGroups([]);
    setHasUnsavedChanges(false);
  };

  const goBack = (confirm = true) => {
    if ( confirm && hasUnsavedChanges ) {
      openDialogBack();
    } else {
      props.history.push("/modelli-corso");
    }
  };  

  return (
    <Layout>
      <div className="box-layout">
        <div className="topSection">
          <h1>
            <IconSchool />
            {courseTemplateId ? "Modifica" : "Aggiungi"} Modello Corso
            </h1>
          <div className="topButtons">
            <Button
              className="button button--outlined"
              onClick={goBack}
            >
              <IconBack/> Indietro
            </Button>
            <Button
              className="button button--confirm"
              onClick={saveCourseTemplate}
              disabled={!hasUnsavedChanges}>
                <IconSave />
                Salva
              </Button>
          </div>
        </div>
        <div className="box-layout__box">
          <CourseTemplateForm 
            handleChange={handleChange}
            handleCheck={handleCheck}
            template={course_template}
          />
          <div className="actions-group actions-group--new" style={{marginTop: "20px"}}>
            <Button className="button button--outlined" onClick={openDialogAddGroup}>
              Aggiungi Gruppo
              <PlusIcon />
            </Button>
          </div>
          <SortableGroupTasks 
            removeTask={ (taskId) => openDialogRemoveTask({ id: taskId }) }
            addTask={ (groupId) => openDialogTask({ group: groupId }) }
            removeGroup={(groupId) => openDialogRemoveGroup({ id: groupId })}
            courseTasks={courseTasks}
            courseGroups={courseGroups}
            onSortEnd={onSortEnd}
            useDragHandle
          />
        </div>
      </div>
      <AddGroupDialog
        onAdd={(newGroup) => addGroup(newGroup)}
      />
      <AddTaskDialog
        onAdd={(newTask, dialogState) => addTask(newTask, dialogState.group) }
        tasks={props.activity_group}
      />
      <UnsavedFormDialog
        onConfirm={() => goBack(false)}
      />
      <DeleteGroupDialog
        onConfirm={(dialogState) => removeGroup(dialogState.id)}
      />
      <DeleteTaskDialog
        onConfirm={(dialogState) => removeTask(dialogState.id)}
      />      
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    course_template: state.course_template.course_template,
    activity_group: state.activity.activity_group,
    saveTemplateSuccess: state.course_template.save_course_template_success
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetActivityGroup: () => dispatch(actions.getActivityGroup()),
    onGetCourseTemplate: id => dispatch(actions.getCourseTemplate(id)),
    onSaveCourseTemplate: course_template =>
      dispatch(actions.saveCourseTemplate(course_template)),
    onResetCourseTemplate: () => dispatch(actions.resetCourseTemplate()),
    onResetCourseTemplateMessage: () =>
      dispatch(actions.resetCourseTemplateMessage())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseTemplatePage));
