import * as actionTypes from "../../types/types";

export const getCenters = () => {
  return {
    type: actionTypes.GET_CENTERS
  };
};
export const getCentersStart = () => {
  return {
    type: actionTypes.GET_CENTERS_START
  };
};
export const getCentersSuccess = centers => {
  return {
    type: actionTypes.GET_CENTERS_SUCCESS,
    payload: { centers }
  };
};
export const getCentersFail = error => {
  return {
    type: actionTypes.GET_CENTERS_FAIL,
    payload: { error }
  };
};

export const getCenter = id => {
  return {
    type: actionTypes.GET_CENTER,
    payload: { id }
  };
};
export const getCenterStart = () => {
  return {
    type: actionTypes.GET_CENTER_START
  };
};
export const getCenterSuccess = center => {
  return {
    type: actionTypes.GET_CENTER_SUCCESS,
    payload: { center }
  };
};
export const getCenterFail = error => {
  return {
    type: actionTypes.GET_CENTER_FAIL,
    payload: { error }
  };
};
export const saveCenter = center => {
  return {
    type: actionTypes.SAVE_CENTER,
    payload: { center }
  };
};
export const saveCenterStart = () => {
  return {
    type: actionTypes.SAVE_CENTER_START
  };
};
export const saveCenterSuccess = center => {
  return {
    type: actionTypes.SAVE_CENTER_SUCCESS,
    payload: { center }
  };
};
export const saveCenterFail = error => {
  return {
    type: actionTypes.SAVE_CENTER_FAIL,
    payload: { error }
  };
};
export const deleteCenter = id => {
  return {
    type: actionTypes.DELETE_CENTER,
    payload: { id }
  };
};
export const deleteCenterStart = () => {
  return {
    type: actionTypes.DELETE_CENTER_START
  };
};
export const deleteCenterSuccess = id => {
  return {
    type: actionTypes.DELETE_CENTER_SUCCESS,
    payload: { id }
  };
};
export const deleteCenterFail = error => {
  return {
    type: actionTypes.DELETE_CENTER_FAIL,
    payload: { error }
  };
};
export const resetCenter = () => {
  return {
    type: actionTypes.RESET_CENTER
  };
};

export const resetCenterMessage = () => {
  return {
    type: actionTypes.RESET_CENTER_MESSAGE
  };
};
