
import * as yup from 'yup';
import User from '.';

export default class UserInvite extends User {

    static get defaults(){
        return {
            name: "",
            surname: "",
            email: "",
            phone: "",
            role: "",
            active: false,
            status: 'new'
          };
    }

    /**
     * The collection name in the database
     */
    get validationSchema() {
        return yup.object().shape({
            name: yup.string().required("Nome obbligatorio"),
            surname: yup.string().required("Cognome obbligatorio"),
            email: yup
                .string()
                .email("Email non valida")
                .required("Email obbligatoria"),
            phone: yup.string().required("Telefono obbligatorio"),
            role: yup.string().required("Ruolo obbligatorio"),
            active: yup.boolean().required("Stato obbligatorio"),
            status: yup.string().required()
        });
    };

    /**
     * Create a new object in database
     * 
     * @param {object}  props   The properties to save
     * @param {string?} docId   The id of the created document
     * 
     * @return {Promise} The created document id
     */
    async create( props, docId = null ){
        props.status = 'new';
        return super.create(props, docId);
    }

}
