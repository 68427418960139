import React from "react";
import { Form, Field } from "formik";

import { Grid, TextField, FormControl } from "@material-ui/core";

import Select, { makeOptions } from "../select";

import provinces from "../../../shared/json/province";
import Center from "../../../models/center";

const center = props => {
  const { values, touched, errors, users } = props;

  return (
    <Form>
      <FormControl component="fieldset" fullWidth className="fields-group">
      <legend className="form-maxWidth">Referente</legend> 
        <Grid container spacing={3} className="form-maxWidth">
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="name"
              value={values.name}
              children={({ field, form }) => {
                return (
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    label="Nome"
                    type="text"
                    name="name"
                    onChange={e => {
                      form.setFieldValue(field.name, e.target.value);
                    }}
                    value={field.value}
                    fullWidth
                    required
                    error={touched.name && errors.name}
                    helperText={touched.name && errors.name && `${errors.name}`}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="type"
              value={values.type}
              children={({ field, form }) => {
                return (
                  <Select
                    value={field.value}
                    onChange={e => {
                      form.setFieldValue(field.name, e.target.value);
                    }}
                    isSearchable={false}
                    label="Relazione"
                    name="type"
                    id="type"
                    placeholder="Scegli relazione.."
                    options={ makeOptions( Center.RELATIONSHIPS ) }
                    error={touched.type && errors.type}
                    helperText={touched.type && errors.type && `${errors.type}`}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="manager"
              value={values.manager}
              children={({ field, form }) => {
                return (
                  <Select
                    value={field.value}
                    onChange={e => {
                      form.setFieldValue(field.name, e.target.value);
                    }}
                    name="manager"
                    id="manager"
                    label="Responsabile"
                    options={users.map(user => ({
                      label: `${user.name} ${user.surname}`,
                      value: user.id
                    }))}
                    error={touched.manager && errors.manager}
                    helperText={
                      touched.manager && errors.manager && `${errors.manager}`
                    }
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </FormControl>
      <FormControl component="fieldset" fullWidth className="fields-group">
      <legend className="form-maxWidth">Localizzazione</legend> 
        <Grid container spacing={3} className="form-maxWidth">
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="state"
              value={values.state}
              children={({ field, form }) => {
                return (
                  <Select
                    value={field.value}
                    onChange={e => {
                      form.setFieldValue(field.name, e.target.value);
                    }}
                    name="state"
                    id="state"
                    label="Provincia"
                    options={provinces}
                    error={touched.state && errors.state}
                    helperText={
                      touched.state && errors.state && `${errors.state}`
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="city"
              value={values.city}
              children={({ field, form }) => {
                return (
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    label="Città"
                    type="text"
                    name="city"
                    onChange={e => {
                      form.setFieldValue(field.name, e.target.value);
                    }}
                    value={field.value}
                    fullWidth
                    required
                    error={touched.city && errors.city}
                    helperText={touched.city && errors.city && `${errors.city}`}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="country"
              value={values.country}
              children={({ field, form }) => {
                return (
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    label="Nazione"
                    type="text"
                    name="country"
                    onChange={e => {
                      form.setFieldValue(field.name, e.target.value);
                    }}
                    value={field.value}
                    fullWidth
                    required
                    error={touched.country && errors.country}
                    helperText={
                      touched.country && errors.country && `${errors.country}`
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="address"
              value={values.address}
              children={({ field, form }) => {
                return (
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    label="Indirizzo"
                    type="text"
                    name="address"
                    onChange={e => {
                      form.setFieldValue(field.name, e.target.value);
                    }}
                    value={field.value}
                    fullWidth
                    required
                    error={touched.address && errors.address}
                    helperText={
                      touched.address && errors.address && `${errors.address}`
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="email"
              value={values.email}
              children={({ field, form }) => {
                return (
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    label="Email Corso"
                    type="text"
                    name="email"
                    onChange={e => {
                      form.setFieldValue(field.name, e.target.value);
                    }}
                    value={field.value}
                    fullWidth
                    required
                    error={touched.email && errors.email}
                    helperText={
                      touched.email && errors.email && `${errors.email}`
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="phone"
              value={values.phone}
              children={({ field, form }) => {
                return (
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    label="Telefono Corso"
                    type="text"
                    name="phone"
                    onChange={e => {
                      form.setFieldValue(field.name, e.target.value);
                    }}
                    value={field.value}
                    fullWidth
                    required
                    error={touched.phone && errors.phone}
                    helperText={
                      touched.phone && errors.phone && `${errors.phone}`
                    }
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </FormControl>
      <FormControl component="fieldset" fullWidth className="fields-group">
        <legend className="form-maxWidth">Amministrazione</legend> 
        <Grid container spacing={3} className="form-maxWidth">
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="commission"
              value={values.commission}
              children={({ field, form }) => {
                return (
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    label="Commissione %"
                    type="text"
                    name="commission"
                    onChange={e => {
                      form.setFieldValue(field.name, e.target.value);
                    }}
                    value={field.value}
                    fullWidth
                    error={touched.commission && errors.commission}
                    helperText={
                      touched.commission &&
                      errors.commission &&
                      `${errors.commission}`
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="iban"
              value={values.iban}
              children={({ field, form }) => {
                return (
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    label="IBAN"
                    type="text"
                    name="iban"
                    onChange={e => {
                      form.setFieldValue(field.name, e.target.value);
                    }}
                    value={field.value}
                    fullWidth
                    error={touched.iban && errors.iban}
                    helperText={touched.iban && errors.iban && `${errors.iban}`}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="googleCalendarUrl"
              value={values.googleCalendarUrl}
              children={({ field, form }) => {
                return (
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    label="Google Calendar URL"
                    type="text"
                    name="googleCalendarUrl"
                    onChange={e => {
                      form.setFieldValue(field.name, e.target.value);
                    }}
                    value={field.value}
                    fullWidth
                    error={
                      touched.googleCalendarUrl && errors.googleCalendarUrl
                    }
                    helperText={
                      touched.googleCalendarUrl &&
                      errors.googleCalendarUrl &&
                      `${errors.googleCalendarUrl}`
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="teamviewerId"
              value={values.teamviewerId}
              children={({ field, form }) => {
                return (
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    label="ID Teamviewer"
                    type="text"
                    name="teamviewerId"
                    onChange={e => {
                      form.setFieldValue(field.name, e.target.value);
                    }}
                    value={field.value}
                    fullWidth
                    error={touched.teamviewerId && errors.teamviewerId}
                    helperText={
                      touched.teamviewerId &&
                      errors.teamviewerId &&
                      `${errors.teamviewerId}`
                    }
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </FormControl>
    </Form>
  );
};

export default center;
