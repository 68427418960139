import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions/index";
import Layout from "../../../../hoc/Layout";
import { Button, CircularProgress } from "@material-ui/core";
import CourseForm from "../../../../shared/forms/course";
import IconSave from "@material-ui/icons/Save";
import IconBack from "@material-ui/icons/ChevronLeft";
import { Formik } from "formik";
import CourseModel from '../../../../../models/course';
import IconSchool from "@material-ui/icons/School";

import _ from "lodash";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import CurrentUser from "../../../../../models/user/current";
import { userCan } from "../../../../../shared/utility";


const initialState = CourseModel.defaults;

const Course = props => {
  const [course, setCourse] = useState(initialState);
  const [loader, setLoader] = useState(false);

  const [dialog, setDialog] = useState({
    open: false
  });

  const openDialog = () => {
    setDialog({
      ...dialog,
      open: true
    });
  };

  const closeDialog = () => {
    setDialog({
      ...dialog,
      open: false
    });
  };

  useEffect(() => {
    //props.onGetUsers();
    //props.onGetCourseTemplates();
    //props.onGetCenters();
    //props.onGetCustomers();
    setCourse({
      ...initialState
    });
    setLoader(false);
  }, []);

  useEffect(() => {
    return function cleanup() {
      props.onResetCourse();
      setCourse(initialState);
    };
  }, []);

  useEffect(() => {
    if (props.match.params.id && !props.course)
      props.onGetCourse(props.match.params.id);
    if (props.course) {
      setCourse({ ...props.course });
    }
  }, [props.course]);

  useEffect(() =>{
    if ( !props.match.params.id && !userCan('course__create') && userCan('course__create--own-center') ) {
      CurrentUser.model().centers().then( centers => {
        let center = centers.pop();
        if( center ) {
        setCourse( Object.assign( {}, course, {center: center.id } ) )
        }
      });
    }    
  }, [])

  const saveCourse = async valuesForm => {
    let c = {
      ...valuesForm
    };
    await props.onSaveCourse(c);
    setCourse(initialState);
    setLoader(true);
    setTimeout(() => {
      props.history.push("/corsi");
    }, 100);
  };

  const canGoBack = values => {
    if (!_.isEqual(course, values)) openDialog();
    else props.history.push("/corsi");
  };

  return (
    <Layout>
      {loader === false ? (
        <Formik
          initialValues={course}
          enableReinitialize
          onSubmit={values => {
            saveCourse(values);
          }}
          validationSchema={CourseModel.model().validationSchema}
          children={({ values, errors, touched, handleSubmit }) => (
            <div className="box-layout">
              <div className="topSection">
                <h1><IconSchool />
                  {props.match.params.id
                    ? `Modifica Corso di ${course._customerName}`
                    : "Aggiungi Corso"}
                </h1>
                <div className="topButtons">
                  <Button
                    className="button button--outlined"
                    onClick={() => canGoBack(values)}
                  >
                    <IconBack/>Indietro
                  </Button>
                  <Button
                    className="button button--confirm"
                    onClick={handleSubmit}
                  >
                    <IconSave />
                    Salva
                  </Button>
                </div>
              </div>
              <div className="box-layout__box">
                <CourseForm {...values} errors={errors} touched={touched} />
              </div>
            </div>
          )}
        />
      ) : (
        <div className="box-layout box-layout__progress">
          <CircularProgress size={80} thickness={4} className="main-progress" />
        </div>
      )}
      <Dialog
        open={dialog.open}
        
        keepMounted
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title-delete"
        aria-describedby="alert-dialog-slide-description-delete"
      >
        <DialogTitle
          className="dialogTitle"
          id="alert-dialog-slide-title-delete"
        >
          {"Sei sicuro di voler uscire?"}
        </DialogTitle>
        <DialogContent className="dialogContent">
          <DialogContentText id="alert-dialog-slide-description-delete">
            Attenzione! Stai lasciando il form con alcuni elementi non ancora
            salvati! Desideri davvero uscire?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
            onClick={() => props.history.push("/corsi")}
            variant="contained"
            className="button button--cancel"
          >
            Esci
          </Button>
          <Button
            onClick={closeDialog}
            variant="contained"
            className="button"
          >
            Rimani
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};
const mapStateToProps = state => {
  return {
    course: state.course.course,
    users: state.user.users,
    course_templates: state.course_template.course_templates,
    centers: state.center.centers,
    customers: state.customer.customers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetCourse: id => dispatch(actions.getCourse(id)),
    // onGetCourseTemplates: () => dispatch(actions.getCourseTemplates()),
    // onGetUsers: () => dispatch(actions.getUsers()),
    // onGetCenters: () => dispatch(actions.getCenters()),
    // onGetCustomers: () => dispatch(actions.getCustomers()),
    onSaveCourse: course => dispatch(actions.saveCourse(course)),
    onResetCourse: () => dispatch(actions.resetCourse())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Course));
