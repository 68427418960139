import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { MuiPickersUtilsProvider, DatePicker, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/it";
import { Form, Field } from "formik";

import Select, { makeOptions } from "../select";
import SelectAsync from "../select/async";

import { Grid, FormControl, TextField, Typography } from "@material-ui/core";
import CustomerModel from "../../../models/customer";
import Center from "../../../models/center";
import User from "../../../models/user";
import CourseTemplate from "../../../models/course/template";
import errorHandler from "../../../shared/errorReporting";
import Course from "../../../models/course";
import ModelCriteria from "../../../models/criteria";
import { userCan } from "../../../shared/utility";

const course = props => {
  const [currentCustomer, setCurrentCustomer] = useState();
  const [latestCustomers, setLatestCustomers] = useState([]);
  const [centers, setCenters] = useState([]);
  const [users, setUsers] = useState([]);
  const [courseTemplates, setCourseTemplates] = useState([]);

  const {
    touched,
    errors,
    customer,
    center,
    manager,
    template,
    folderURL,
    welcomeDateTime,
    onboardingDateTime,
    purchaseDate,
    statusPayment,
    startDateTime,
    endDate,
    coachingDateTime,
  } = props;

  // Load selected customer
  useEffect(() => {
    if (props.customer) {
      CustomerModel.model().get(props.customer)
        .then(setCurrentCustomer)
        .catch(errorHandler.report);
    }
  }, [props.customer]);

  //Load values for some selects
  useEffect(() => {
    Center.model().list().then(setCenters).catch(errorHandler.report);
    User.model().list().then(setUsers).catch(errorHandler.report);
    CourseTemplate.model().list().then(setCourseTemplates).catch(errorHandler.report);
  }, []);

  //Load values for some selects
  useEffect(() => {
    const latestCustomersCriteria = new ModelCriteria();
    latestCustomersCriteria.limit(10);
    latestCustomersCriteria.orderBy('createdAt', 'desc');
    if (center) {
      latestCustomersCriteria.where('center', '==', Center.model().ref(center));
    }
    CustomerModel.model().list(latestCustomersCriteria).then(setLatestCustomers);
  }, [center]);

  const searchCustomers = (search) => {
    return CustomerModel.model()
      .search(search, ['name', 'surname'])
      .then(customers => makeOptions(customers, '{name} {surname}', 'id'))
      .catch(errorHandler.report);
  }

  return (
    <Form>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="it">
        <FormControl component="fieldset" fullWidth className="fields-group">
          <Typography component="legend" className="form-maxWidth">Riferimenti Corso</Typography>
          <Grid container spacing={3} className="form-maxWidth">
            <Grid item xs={12} sm={6} md={6}>
              <Field
                name="center"
                value={center}
                children={({ field, form }) => {
                  return (
                    <FormControl variant="outlined" fullWidth required>
                      <Select
                        label="Centro *"
                        name="center"
                        id="center"
                        value={field.value}
                        onChange={e => {
                          form.setFieldValue(field.name, e.target.value);
                        }}
                        options={centers.map(doc => ({
                          label: doc.name,
                          value: doc.id
                        }))}
                        error={Boolean(touched.center && errors.center)}
                        helperText={
                          touched.center && errors.center && `${errors.center}`
                        }
                        isDisabled={!userCan('course__update')}
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Field
                name="customer"
                value={customer}
                children={({ field, form }) => {
                  return (
                    <FormControl variant="outlined" fullWidth required>
                      <SelectAsync
                        label="Corsista *"
                        name="customer"
                        id="customer"
                        value={
                          currentCustomer
                            ? {
                              value: props.customer,
                              label: `${currentCustomer.name} ${currentCustomer.surname}`
                            }
                            : undefined
                        }
                        onChange={e => {
                          form.setFieldValue(field.name, e.target.value);
                        }}
                        placeholder="Scrivi il cognome per cercare.."
                        loadOptions={searchCustomers}
                        cacheOptions
                        defaultOptions={makeOptions(latestCustomers, '{name} {surname}', 'id')}
                        error={Boolean(touched.customer && errors.customer)}
                        helperText={
                          touched.customer &&
                          errors.customer &&
                          `${errors.customer}`
                        }
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Field
                name="template"
                value={template}
                children={({ field, form }) => {
                  return (
                    <FormControl variant="outlined" fullWidth required>
                      <Select
                        label="Tipologia *"
                        name="template"
                        id="template"
                        value={field.value}
                        onChange={e => {
                          form.setFieldValue(field.name, e.target.value);
                        }}
                        options={courseTemplates.map(doc => ({
                          label: doc.name,
                          value: doc.id
                        }))}
                        error={Boolean(touched.template && errors.template)}
                        helperText={
                          touched.template &&
                          errors.template &&
                          `${errors.template}`
                        }
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Field
                name="manager"
                value={manager}
                children={({ field, form }) => {
                  return (
                    <FormControl variant="outlined" fullWidth required>
                      <Select
                        label="Responsabile *"
                        name="manager"
                        id="manager"
                        value={field.value}
                        onChange={e => {
                          form.setFieldValue(field.name, e.target.value);
                        }}
                        options={users.map(doc => ({
                          label: `${doc.name} ${doc.surname}`,
                          value: doc.id
                        }))}
                        error={Boolean(touched.manager && errors.manager)}
                        helperText={
                          touched.manager &&
                          errors.manager &&
                          `${errors.manager}`
                        }
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Field
                name="folderURL"
                value={folderURL}
                children={({ field, form }) => {
                  return (
                    <TextField
                      variant="outlined"
                      autoComplete="off"
                      label="URL Cartella Google Drive"
                      type="text"
                      name="folderURL"
                      onChange={e => {
                        form.setFieldValue(field.name, e.target.value);
                      }}
                      value={field.value}
                      error={touched.folderURL && errors.folderURL}
                      helperText={
                        touched.folderURL &&
                        errors.folderURL &&
                        `${errors.folderURL}`
                      }
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className="form-maxWidth">
            <Grid className="datePicker-container" item xs={12} sm={6} md={6}>
              <Typography component="h3" className="form-maxWidth" variant="subtitle1">
                Primo Incontro
              </Typography>
              <Field
                name="welcomeDateTime"
                value={welcomeDateTime}
                children={({ field, form }) => {
                  return (
                    <FormControl variant="outlined" fullWidth required>
                      <DateTimePicker
                        name={field.name}
                        value={field.value ? field.value : null}
                        label="Data/Ora"
                        onChange={date => form.setFieldValue(field.name, date ? date.toDate() : null)}
                        ampm={false}
                        format="DD/MM/YYYY - HH:mm"
                        emptyLabel="Clicca per inserire una data.."
                        id={field.name}
                        inputVariant="outlined"
                        clearable
                        error={touched.welcomeDateTime && errors.welcomeDateTime}
                        helperText={
                          touched.welcomeDateTime &&
                          errors.welcomeDateTime &&
                          `${errors.welcomeDateTime}`
                        }
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid className="datePicker-container" item xs={12} sm={6} md={6}>

              <Typography component="h3" className="form-maxWidth" variant="subtitle1">
                Spiegazione AR
              </Typography>
              <Field
                name="onboardingDateTime"
                value={onboardingDateTime}
                children={({ field, form }) => {
                  return (
                    <FormControl variant="outlined" fullWidth>
                      <DateTimePicker
                        name={field.name}
                        value={field.value ? field.value : null}
                        label="Data/Ora"
                        onChange={date => form.setFieldValue(field.name, date ? date.toDate() : null)}
                        format="DD/MM/YYYY - HH:mm"
                        emptyLabel="Clicca per inserire una data.."
                        ampm={false}
                        id={field.name}
                        inputVariant="outlined"
                        clearable
                        error={touched.onboardingDateTime && errors.onboardingDateTime}
                        helperText={
                          touched.onboardingDateTime &&
                          errors.onboardingDateTime &&
                          `${errors.onboardingDateTime}`
                        }
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
          </Grid>
        </FormControl>

        <FormControl component="fieldset" fullWidth className="fields-group">
          <legend className="form-maxWidth">Dettagli Corso</legend>
          <Grid container spacing={3} className="form-maxWidth">
            <Grid className="datePicker-container" item xs={12} sm={6} md={6}>
              <Field
                name="startDateTime"
                value={startDateTime}
                children={({ field, form }) => {
                  return (
                    <FormControl variant="outlined" fullWidth>
                      <DateTimePicker
                        placeholder="gg/mm/aaaa"
                        name={field.name}
                        value={field.value ? field.value : null}
                        label="Data/Ora inizio corso"
                        onChange={date => form.setFieldValue(field.name, date ? date.toDate() : null)}
                        format="DD/MM/YYYY - HH:mm"
                        emptyLabel="Clicca per inserire una data.."
                        ampm={false}
                        id={field.name}
                        inputVariant="outlined"
                        required
                        clearable
                        error={touched.startDateTime && errors.startDateTime}
                        helperText={
                          touched.startDateTime &&
                          errors.startDateTime &&
                          `${errors.startDateTime}`
                        }
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid className="datePicker-container" item xs={12} sm={6} md={6}>
              <Field
                name="endDate"
                value={endDate}
                children={({ field, form }) => {
                  return (
                    <FormControl variant="outlined" fullWidth required>
                      <DatePicker
                        placeholder="gg/mm/aaaa"
                        name="endDate"
                        value={field.value ? field.value : null}
                        label="Data fine corso"
                        onChange={date => form.setFieldValue(field.name, date.toDate())}
                        format="DD/MM/YYYY"
                        emptyLabel="Clicca per inserire una data.."
                        id="endDate"
                        inputVariant="outlined"
                        required
                        clearable
                        error={touched.endDate && errors.endDate}
                        helperText={
                          touched.endDate &&
                          errors.endDate &&
                          `${errors.endDate}`
                        }
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Field
                name="status"
                children={({ field, form }) => {
                  return (
                    <Select
                      {...field}
                      id={field.name}
                      label="Stato del corso"
                      options={makeOptions(Course.COURSE_STATUSES)}
                      error={Boolean(errors.status)}
                      isClearable={false}
                      helperText={
                        errors.status && `${errors.status}`
                      }
                    />
                  );
                }}
              />
            </Grid>
            <Grid className="datePicker-container" item xs={12} sm={6} md={6}>
              <Field
                name="purchaseDate"
                value={purchaseDate}
                children={({ field, form }) => {
                  return (
                    <FormControl variant="outlined" fullWidth required>
                      <DatePicker
                        placeholder="gg/mm/aaaa"
                        name="purchaseDate"
                        value={field.value ? field.value : null}
                        onChange={date => form.setFieldValue(field.name, date.toDate())}
                        onAccept={mDate => {
                          form.setFieldValue(field.name, mDate.toDate());
                        }}
                        id="purchaseDate"
                        format="DD/MM/YYYY"
                        label="Data Acquisto Corso"
                        emptyLabel="Clicca per inserire una data.."
                        inputVariant="outlined"
                        disablePast={false}
                        disableFuture={true}
                        required
                        clearable
                        error={touched.purchaseDate && errors.purchaseDate}
                        helperText={
                          touched.purchaseDate &&
                          errors.purchaseDate &&
                          `${errors.purchaseDate}`
                        }
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Field
                name="statusPayment"
                value={statusPayment}
                children={({ field, form }) => {
                  return (
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        label="Stato Pagamento"
                        name="statusPayment"
                        id="statusPayment"
                        value={props.statusPayment}
                        onChange={e => {
                          form.setFieldValue(field.name, e.target.value);
                        }}
                        options={makeOptions(Course.PAYMENT_STATUSES)}
                        error={touched.statusPayment && errors.statusPayment}
                        helperText={
                          touched.statusPayment &&
                          errors.statusPayment &&
                          `${errors.statusPayment}`
                        }
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl component="fieldset" fullWidth className="fields-group">
          <legend className="form-maxWidth">Coaching Post Corso</legend>
          <Grid container spacing={3} className="form-maxWidth">
            <Grid className="datePicker-container" item xs={12} sm={6} md={6}>
              <Field
                name="coachingDateTime"
                value={coachingDateTime}
                children={({ field, form }) => {
                  return (
                    <FormControl variant="outlined" fullWidth>
                      <DateTimePicker
                        placeholder="gg/mm/aaaa"
                        name={field.name}
                        value={field.value ? field.value : null}
                        label="Data/Ora"
                        onChange={date => form.setFieldValue(field.name, date ? date.toDate() : null)}
                        format="DD/MM/YYYY - HH:mm"
                        emptyLabel="Clicca per inserire una data.."
                        ampm={false}
                        id={field.name}
                        inputVariant="outlined"
                        clearable
                        error={touched.coachingDateTime && errors.coachingDateTime}
                        helperText={
                          touched.coachingDateTime &&
                          errors.coachingDateTime &&
                          `${errors.coachingDateTime}`
                        }
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
          </Grid>
        </FormControl>
      </MuiPickersUtilsProvider>
    </Form>
  );
};

export default withRouter(course);
