import FirestoreModel from '../firestore';
import * as yup from 'yup';
import { MissingParentException } from '../exceptions';

export default class CustomerRelative extends FirestoreModel {

    /**
     * Setup the model
     * 
     * @param {object} props 
     */
    constructor(props) {
        super(props);

        const { customerId } = this.props;

        if ( !customerId ) {
            throw new MissingParentException(customerId);
        }

    }

    /**
     * The collection name in the database
     */
    get collectionName() {
        return `customer/${this.props.customerId}/relatives/`;
    };

    /**
     * The collection name in the database
     */
    get validationSchema() {

        let shape = {
            name: yup.string().required("Nome obbligatorio").label('Nome'),
            surname: yup.string().required("Cognome obbligatorio").label('Cognome'),
            type: yup.string().required().label("Rapporto di parentela"),
            qualification: yup.string().label("Grado di istruzione"),
            job: yup.string().label("Lavoro"),
            email: yup.string().email().label('E-Mail'),
            province: yup.string().label('Provincia'),
            address: yup.string().label('Indirizzo'),
            city: yup.string().label('Città'),
            phone: yup.string().label('Telefono')
        };

        // @TODO: find a way to interpolate
        if ( this.getContext() === 'primary' ) {
            shape = {
                name: yup.string().required("Nome obbligatorio").label('Nome'),
                surname: yup.string().required("Cognome obbligatorio").label('Cognome'),
                type: yup.string().required().label("Rapporto di parentela"),
                qualification: yup.string().label("Grado di istruzione"),
                job: yup.string().label("Lavoro"),
                email: yup.string().email().required().label('E-Mail'),
                province: yup.string().label('Provincia'),
                address: yup.string().label('Indirizzo'),
                city: yup.string().label('Città'),
                phone: yup.string().required().label('Telefono')            
            }
        }

        return yup.object().shape(shape);
    };

    static get defaults(){
        return {
            name: "",
            surname: "",
            qualification: "",
            job: "",
            email: "",
            phone: "",
            type: "",
            address: "",
            city: "",
            province: ""
          }
    }

}
