export const GET_ACTIVITY_GROUP = "GET_ACTIVITY_GROUP";
export const GET_ACTIVITY_GROUP_START = "GET_ACTIVITY_GROUP_START";
export const GET_ACTIVITY_GROUP_SUCCESS = "GET_ACTIVITY_GROUP_SUCCESS";
export const GET_ACTIVITY_GROUP_FAIL = "GET_ACTIVITY_GROUP_FAIL";

export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_ACTIVITY_START = "GET_ACTIVITY_START";
export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";
export const GET_ACTIVITY_FAIL = "GET_ACTIVITY_FAIL";

export const SAVE_ACTIVITY = "SAVE_ACTIVITY";
export const SAVE_ACTIVITY_START = "SAVE_ACTIVITY_START";
export const SAVE_ACTIVITY_SUCCESS = "SAVE_ACTIVITY_SUCCESS";
export const SAVE_ACTIVITY_FAIL = "SAVE_ACTIVITY_FAIL";

export const RESET_ACTIVITY = "RESET_ACTIVITY";
export const RESET_ACTIVITY_MESSAGE = "RESET_ACTIVITY_MESSAGE";

export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const DELETE_ACTIVITY_START = "DELETE_ACTIVITY_START";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_FAIL = "DELETE_ACTIVITY_FAIL";
