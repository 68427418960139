export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERS_START = "GET_CUSTOMERS_START";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL";

export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_START = "GET_CUSTOMER_START";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAIL = "GET_CUSTOMER_FAIL";

export const SAVE_CUSTOMER = "SAVE_CUSTOMER";
export const SAVE_CUSTOMER_START = "SAVE_CUSTOMER_START";
export const SAVE_CUSTOMER_SUCCESS = "SAVE_CUSTOMER_SUCCESS";
export const SAVE_CUSTOMER_FAIL = "SAVE_CUSTOMER_FAIL";

export const RESET_CUSTOMER = "RESET_CUSTOMER";
export const RESET_CUSTOMER_MESSAGE = "RESET_CUSTOMER_MESSAGE";

export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_START = "DELETE_CUSTOMER_START";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL";
