export default [
  {
    component: require("../components/pages/_private/_courses/_course-spec/_task")
      .default,
    routePath: "/dettaglio-corso/:id/task/:task_id?",
    name: "course-spec-task",
    visibility: "course__task__update--own-center",
    exact: true
  },
  {
    component: require("../components/pages/_private/_courses/_course-spec/")
      .default,
    routePath: "/dettaglio-corso/:id?",
    name: "course-spec",
    visibility: "course__task__list--own-center",
    exact: true
  },
  {
    component: require("../components/pages/_private/_courses/_course/")
      .default,
    routePath: "/corso/:id?",
    name: "add-courses",
    visibility: "course__update--own-center",
    exact: true
  },
  {
    component: require("../components/pages/_private/_courses/").default,
    routePath: "/corsi",
    name: "list-courses",
    visibility: "course__list--own-center",
    exact: true
  },
  {
    component: require("../components/pages/_private/_course_templates/course_template")
      .default,
    routePath: "/modello-corso/:id?",
    name: "add-course-templates",
    visibility: "course_template__update",
    exact: true
  },
  {
    component: require("../components/pages/_private/_course_templates/")
      .default,
    routePath: "/modelli-corso",
    name: "list-course-templates",
    visibility: "course_template__list",
    exact: true
  },
  {
    component: require("../components/pages/_private/_customers/_customer")
      .default,
    routePath: "/cliente/:id?",
    name: "add-customer-private",
    visibility: "customer__update--own-center",
    exact: true
  },
  {
    component: require("../components/pages/_private/_customers").default,
    routePath: "/clienti",
    name: "customers-private",
    visibility: "customer__list--own-center",
    exact: true
  },
  {
    component: require("../components/pages/_private/_centers/_center").default,
    routePath: "/centro/:id?",
    name: "add-center-private",
    visibility: "center__update",
    exact: true
  },
  {
    component: require("../components/pages/_private/_centers").default,
    routePath: "/centri",
    name: "centers-private",
    visibility: "center__list",
    exact: true
  },
  {
    component: require("../components/pages/_private/_activity/_activity")
      .default,
    routePath: "/task/:id?",
    name: "task-private",
    visibility: "task__update",
    exact: true
  },
  {
    component: require("../components/pages/_private/_activity").default,
    routePath: "/tasks",
    name: "tasks-private",
    visibility: "task__list",
    exact: true
  },
  {
    component: require("../components/pages/_private/_users").default,
    routePath: "/utenti",
    name: "users-private",
    visibility: "users__list",
    exact: true
  },
  {
    component: require("../components/pages/_private/_users/_user").default,
    routePath: "/utente/:id",
    name: "add-user-private",
    visibility: "users__update--self",
    exact: true
  },
  {
    component: require("../components/pages/_private/_users/_user").default,
    routePath: "/utente/",
    name: "add-user-private",
    visibility: "users__create",
    exact: true
  },
  {
    component: require("../components/pages/_private/_dashboard").default,
    routePath: "/",
    name: "dashboard-private",
    visibility: "course__list--own-center",
    exact: true
  },
  //Public Routes
  {
    component: require("../components/pages/_public/_auth/verifyAccount").default,
    routePath: "/verifica-account/:token?",
    name: "verify-account",
    visibility: true,
    exact: true
  },
  {
    component: require("../components/pages/_public/_auth/signIn").default,
    routePath: "/",
    name: "public-login",
    visibility: true,
    exact: true
  }
];
