import { updateObject } from "../../../shared/utility";
import * as actionTypes from "../../types/types";

const initialState = {
  roles: [],
  loading: false,
  message: null,
  error: null
};

export const getRolesStart = (state, action) => {
  return updateObject(state, { loading: true });
};
export const getRolesSuccess = (state, action) => {
  return updateObject(state, { loading: false, roles: action.payload.roles });
};
export const getRolesFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.payload.error });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ROLES_START:
      return getRolesStart(state, action);
    case actionTypes.GET_ROLES_SUCCESS:
      return getRolesSuccess(state, action);
    case actionTypes.GET_ROLES_FAIL:
      return getRolesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
