
import FirestoreModel from '../firestore';
import * as yup from 'yup';

class Task extends FirestoreModel {
    /**
     * The collection name in the database
     */
    get collectionName() {
        return 'task';
    };

    /**
     * The collection name in the database
     */
    get validationSchema() {
        return yup.object().shape({
            name: yup.string().required(),
            description: yup.string().label('Descrizione'),
            duration: yup.string().label('Durata'),
            inCalendar: yup.boolean().label('In Calendario'),
            status: yup.string().label('Stato'),
            statuses: yup.array().label('Stati'),
            canView: yup.object().label('Visibile da'),
            documents: yup.array().label('Allegati'),
            templateEmail: yup.string().label('Template Email'),
            templateSms: yup.string().label('Template SMS'),
            type: yup.string().label('Tipo'),
        });
    };
}

Task.TYPE_MEETING = 'meeting';
Task.TYPE_MANUAL = 'manual';
Task.TYPE_EMAIL = 'email';
Task.TYPE_MESSAGE = 'message';

Task.TASK_TYPES = [
    [ Task.TYPE_MEETING, 'Appuntamento' ],
    [ Task.TYPE_MANUAL, 'Azione Manuale' ],
    [ Task.TYPE_EMAIL, 'Email' ],
    [ Task.TYPE_MESSAGE, 'Messaggio' ]
];

export default Task;