export const GET_USERS = "GET_USERS";
export const GET_USERS_START = "GET_USERS_START";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

export const GET_USER = "GET_USER";
export const GET_USER_START = "GET_USER_START";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const SAVE_USER = "SAVE_USER";
export const SAVE_USER_START = "SAVE_USER_START";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_FAIL = "SAVE_USER_FAIL";

export const RESET_USER = "RESET_USER";
export const RESET_USER_MESSAGE = "RESET_USER_MESSAGE";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
