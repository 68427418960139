import { updateObject } from "../../../shared/utility";
import * as actionTypes from "../../types/types";

const initialState = {
  customers: [],
  customer: null,
  loading: false,
  message: null,
  error: null
};

export const getCustomersStart = (state, action) => {
  return updateObject(state, { loading: true });
};
export const getCustomersSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    customers: action.payload.customers
  });
};
export const getCustomersFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare",
    error: action.payload.error
  });
};
export const getCustomerStart = (state, action) => {
  return updateObject(state, { loading: true });
};
export const getCustomerSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    customer: action.payload.customer
  });
};
export const getCustomerFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare",
    error: action.payload.error
  });
};
export const saveCustomerStart = (state, action) => {
  return updateObject(state, { loading: true, message: null });
};
export const saveCustomerSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Corsista salvato con successo",
    customer: null
  });
};
export const saveCustomerFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore nel salvataggio. Riprovare.",
    error: action.payload.error
  });
};
export const deleteCustomerStart = (state, action) => {
  return updateObject(state, { loading: false, message: null });
};
export const deleteCustomerSuccess = (state, action) => {
  let remove_customers = { ...state }.customers;
  let removeId = remove_customers.map(el => el.id).indexOf(action.payload.id);
  remove_customers.splice(removeId, 1);
  return updateObject(state, {
    loading: false,
    message: "Corsista eliminato con successo",
    customers: remove_customers
  });
};
export const deleteCustomerFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare",
    error: action.payload.error
  });
};
export const resetCustomer = (state, action) => {
  return updateObject(state, { customer: null });
};
export const resetCustomerMessage = (state, action) => {
  return updateObject(state, { message: null });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CUSTOMERS_START:
      return getCustomersStart(state, action);
    case actionTypes.GET_CUSTOMERS_SUCCESS:
      return getCustomersSuccess(state, action);
    case actionTypes.GET_CUSTOMERS_FAIL:
      return getCustomersFail(state, action);
    case actionTypes.GET_CUSTOMER_START:
      return getCustomerStart(state, action);
    case actionTypes.GET_CUSTOMER_SUCCESS:
      return getCustomerSuccess(state, action);
    case actionTypes.GET_CUSTOMER_FAIL:
      return getCustomerFail(state, action);
    case actionTypes.SAVE_CUSTOMER_START:
      return saveCustomerStart(state, action);
    case actionTypes.SAVE_CUSTOMER_SUCCESS:
      return saveCustomerSuccess(state, action);
    case actionTypes.SAVE_CUSTOMER_FAIL:
      return saveCustomerFail(state, action);
    case actionTypes.DELETE_CUSTOMER_START:
      return deleteCustomerStart(state, action);
    case actionTypes.DELETE_CUSTOMER_SUCCESS:
      return deleteCustomerSuccess(state, action);
    case actionTypes.DELETE_CUSTOMER_FAIL:
      return deleteCustomerFail(state, action);
    case actionTypes.RESET_CUSTOMER:
      return resetCustomer(state, action);
    case actionTypes.RESET_CUSTOMER_MESSAGE:
      return resetCustomerMessage(state, action);
    default:
      return state;
  }
};

export default reducer;
