import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

//table
import ReactTable from "react-table";
import "react-table/react-table.css";

import matchSorter from "match-sorter";
import { PaginateButtonPrev, PaginateButtonNext } from "../../../../shared/table/pagination";
import { userCan } from "../../../../../shared/utility";

export default props => {
  return (
    <div className="react-table">
      <ReactTable
        data={props.users}
        columns={[
          {
            Header: "Nome",
            id: "name",
            width: 180,
            accessor: 'name',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["name"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra..."
                className="table-filter"
              />
            ),

          },
          {
            Header: "Cognome",
            id: "surname",
            width: 180,
            accessor: 'surname',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["surname"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra..."
                className="table-filter"
              />
            ),

          },
          {
            Header: "Email",
            id: "email",
            width: 240,
            accessor: 'email',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["email"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra..."
                className="table-filter"
              />
            ),

          },
          {
            Header: "Telefono",
            id: "phone",
            width: 130,
            accessor: 'phone',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["phone"] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <input
                onChange={event => onChange(event.target.value)}
                value={filter && filter.value}
                placeholder="Filtra..."
                className="table-filter"
              />
            ),

          },
          {
            Header: "Attivo",
            id: "active",
            accessor: "active",
            width: 130,
            Cell: row => row.original.active ? 'Attivo' : 'Disattivo',
            filterMethod: (filter, row) => {
              if (filter.value === "all") {
                return true;
              }
              if (filter.value === "true") {
                return row[filter.id] === true;
              }
              return row[filter.id] === false;
            },
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
                className="table-filter"
              >
                <option value="all">Mostra Tutti</option>
                <option value="true">Attivo</option>
                <option value="false">Disattivato</option>
              </select>
            )
          },
          {
            Header: "Permessi",
            id: "role",
            accessor: "role",
            width: 180,
            Cell: row => String(props.roles.find(el => el.id === row.original.role) && props.roles.find(el => el.id === row.original.role).name),
            filterMethod: (filter, row) => {
              if (filter.value === "all") {
                return true;
              }
              if (row[filter.id]) {
                return row[filter.id].startsWith(filter.value);
              }

              return row[filter.id] === false;
            },
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
                className="table-filter"
              >
                <option value="all">Mostra Tutti</option>
                {props.roles.map(el => (
                  <option key={el.id} value={el.id}>{el.name}</option>
                ))}
              </select>
            )
          },
          {
            Header: "Azioni",
            filterable: false,
            accessor: "actions",
            width: 100,
            Cell: row => (
              <div className="actionTableContainer">
                { userCan('users__update') && (
                <Tooltip
                  title="Modifica"
                  enterDelay={100}
                  position="bottom-end"
                >
                  <IconButton
                    className="iconButton colorGreen colorWhite"
                    onClick={() => props.goToEdit(row.original.id)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                )}
                { userCan('users__delete') && (
                <Tooltip title="Elimina" enterDelay={100}>
                  <IconButton
                    className="iconButton colorRed colorWhite"
                    onClick={() => props.openDialog(row.original.id)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                )}
              </div>
            )
          }
        ]}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        defaultPageSize={8}
        previousText={<PaginateButtonPrev />}
        nextText={<PaginateButtonNext />}
        pageText="Pagina"
        ofText="di"
        rowsText="per pagina"
        noDataText="Nessun Utente Trovato"
      />
      <br />
    </div>
  );
};
