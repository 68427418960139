import * as types from "../types/types";
import { updateObject } from "../../shared/utility";

const initState = {
  user: null,
  loading: false,
  error: null,
  verifyToken: false,
  errorExist: null,
  message: null,
  errorCode: null,
  errorUsername: null
};

const authUserStart = state =>
  updateObject(state, {
    loading: true,
    error: null,
    errorExist: null,
    message: null,
    errorCode: null,
    errorUsername: null
  });
const authUserSuccess = (state, payload) =>
  updateObject(state, { loading: false, user: payload.user, error: null });
const authUserFail = (state, payload) =>
  updateObject(state, {
    loading: false,
    error: payload.error,
    errorExist: true,
    errorCode: payload.errorCode,
    errorUsername: payload.username
  });
const authUserCheckStateSuccess = (state, payload) =>
  updateObject(state, { loading: false, user: payload.user });
const authUserLogout = (state, payload) =>
  updateObject(state, { token: null, user: null });

export default (state = initState, { type, payload }) => {
  switch (type) {
    case types.AUTH_USER_START:
      return authUserStart(state);
    case types.AUTH_USER_SUCCESS:
      return authUserSuccess(state, payload);
    case types.AUTH_USER_FAIL:
      return authUserFail(state, payload);
    case types.AUTH_USER_CHECK_STATE_SUCCESS:
      return authUserCheckStateSuccess(state, payload);
    case types.AUTH_USER_LOGOUT:
      return authUserLogout(state, payload);
    default:
      return state;
  }
};
