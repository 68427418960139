import * as actionTypes from "../../types/types";

export const getUsers = () => {
  return {
    type: actionTypes.GET_USERS
  };
};
export const getUsersStart = () => {
  return {
    type: actionTypes.GET_USERS_START
  };
};
export const getUsersSuccess = users => {
  return {
    type: actionTypes.GET_USERS_SUCCESS,
    payload: { users }
  };
};
export const getUsersFail = error => {
  return {
    type: actionTypes.GET_USERS_FAIL,
    payload: { error }
  };
};

export const getUser = id => {
  return {
    type: actionTypes.GET_USER,
    payload: { id }
  };
};
export const getUserStart = () => {
  return {
    type: actionTypes.GET_USER_START
  };
};
export const getUserSuccess = user => {
  return {
    type: actionTypes.GET_USER_SUCCESS,
    payload: { user }
  };
};
export const getUserFail = error => {
  return {
    type: actionTypes.GET_USER_FAIL,
    payload: { error }
  };
};
export const saveUser = user => {
  return {
    type: actionTypes.SAVE_USER,
    payload: { user }
  };
};
export const saveUserStart = () => {
  return {
    type: actionTypes.SAVE_USER_START
  };
};
export const saveUserSuccess = user => {
  return {
    type: actionTypes.SAVE_USER_SUCCESS,
    payload: { user }
  };
};
export const saveUserFail = error => {
  return {
    type: actionTypes.SAVE_USER_FAIL,
    payload: { error }
  };
};
export const deleteUser = id => {
  return {
    type: actionTypes.DELETE_USER,
    payload: { id }
  };
};
export const deleteUserStart = () => {
  return {
    type: actionTypes.DELETE_USER_START
  };
};
export const deleteUserSuccess = id => {
  return {
    type: actionTypes.DELETE_USER_SUCCESS,
    payload: { id }
  };
};
export const deleteUserFail = error => {
  return {
    type: actionTypes.DELETE_USER_FAIL,
    payload: { error }
  };
};
export const resetUser = () => {
  return {
    type: actionTypes.RESET_USER
  };
};
export const resetUserMessage = () => {
  return {
    type: actionTypes.RESET_USER_MESSAGE
  };
};
