import React from "react";
import TextField from "@material-ui/core/TextField";

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

const Control = React.forwardRef( function (props, ref) {
  const { children, innerProps, innerRef, selectProps } = props;

  const {
    classes,
    TextFieldProps = { InputLabelProps: {} },
    error,
    helperText
  } = selectProps;

  if (selectProps.label) {
    TextFieldProps.label = selectProps.label;
  }

  if (selectProps.id) {
    TextFieldProps.InputLabelProps.htmlFor = selectProps.id;
  }

  if (selectProps.name) {
    TextFieldProps.name = selectProps.name;
  }

  if (selectProps.placeholder && selectProps.label) {
    TextFieldProps.InputLabelProps.shrink = true;
  }

  return (
    <TextField
      ref={ref}
      fullWidth
      variant="outlined"
      error={error}
      helperText={helperText}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps
        }
      }}
      className="react-select__control"
      {...TextFieldProps}
    />
  );
});

const components = {
  Control
};

export default components;
