import React from "react";

import {
  Grid,
  FormControl,
} from "@material-ui/core";

import Select from "../select";

import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import "moment/locale/it";


const CourseTaskForm = props => {

  return (
    <FormControl component="fieldset" fullWidth className="fields-group" >
      <Grid container spacing={3} className="form-maxWidth">
        <Grid item xs={12} sm={12} md={12}>
          <Select
            value={props.owner}
            onChange={props.handleChange}
            name="owner" 
            id="owner" 
            label="Assegnato A"
            options={ props.users.map( user => ({ label: `${user.name} ${user.surname}`, value: user.id }) )}
          />
        </Grid>
        <Grid className="datePicker-container" item xs={12} sm={6} md={6}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="it">
            <FormControl variant="outlined" fullWidth>
              <DateTimePicker 
                name="dueDateTime"
                value={props.dueDateTime ? props.dueDateTime : null} 
                onChange={date => props.setDate(date ? date.toDate() :  null)} 
                id="dueDateTime"
                format="DD/MM/YYYY HH:mm"
                emptyLabel="Clicca per inserire una data.."
                ampm={false}
                label="Scadenza"
                inputVariant="outlined"          
                clearable              
            />
            </FormControl>
          </MuiPickersUtilsProvider>      
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Select
            value={props.status}
            onChange={props.handleChange}
            name="status"
            id="status"
            label="Stato"
            options={ props.statuses ? props.statuses.map( (status) => ({ label: status.name, value: status.id }) ) : [] }
            />
        </Grid>
      </Grid>
  </FormControl>
  );
};

export default CourseTaskForm;
