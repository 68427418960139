import { updateObject } from "../../../shared/utility";
import * as actionTypes from "../../types/types";

const initialState = {
  email_template: [],
  loading: false,
  message: null,
  error: null
};

export const getEmailTemplateStart = (state, action) => {
  return updateObject(state, { loading: true });
};
export const getEmailTemplateSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    email_template: action.payload.email_template
  });
};
export const getEmailTemplateFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.payload.error });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EMAIL_TEMPLATE_START:
      return getEmailTemplateStart(state, action);
    case actionTypes.GET_EMAIL_TEMPLATE_SUCCESS:
      return getEmailTemplateSuccess(state, action);
    case actionTypes.GET_EMAIL_TEMPLATE_FAIL:
      return getEmailTemplateFail(state, action);
    default:
      return state;
  }
};

export default reducer;
