import React from "react";
import { Link } from "react-router-dom";

import Backdrop from "../backdrop/backdrop";
import { userCan } from "../../../shared/utility";

const sideDrawer = props => {
  let attachedClasses = ["sidedrawer", "sidedrawer__Close"];
  if (props.open) {
    attachedClasses = ["sidedrawer", "sidedrawer__Open"];
  }
  return (
    <>
      <Backdrop show={props.open} clicked={props.closed} />
      <div className={attachedClasses.join(" ")}>
        <ul>
        { userCan( 'course__list--own-center') && (
          <li className="sidedrawer__main__item">
            <Link to="/" className="sidedrawer__main__link">
              Home
            </Link>
          </li>
        )}
        { userCan( 'course__list--own-center' ) && (      
          <li className="sidedrawer__main__item">
            <Link to="/corsi" className="sidedrawer__main__link">
              Corsisti
            </Link>
          </li>
        )}
        { userCan( 'customer__list--own-center') && (
          <li className="sidedrawer__main__item">
            <Link to="/clienti" className="sidedrawer__main__link">
              Clienti
            </Link>
          </li>
        )}         
        { userCan( 'task__update') && (    
          <li className="sidedrawer__main__item">
            <Link to="/tasks" className="sidedrawer__main__link">
              Attività
            </Link>
          </li>
        )}     
        { userCan( 'course_template__update' ) && (         
          <li className="sidedrawer__main__item">
            <Link to="/modelli-corso" className="sidedrawer__main__link">
              Modelli Corso
            </Link>
          </li>
        )}
        { userCan( 'center__update') && (
          <li className="sidedrawer__main__item">
            <Link to="/centri" className="sidedrawer__main__link">
              Centri
            </Link>
          </li>
        )}
        { userCan( 'users__update') && (          
          <li className="sidedrawer__main__item">
            <Link to="/utenti" className="sidedrawer__main__link">
              Utenti
            </Link>
          </li>
        )}
          <li className="sidedrawer__main__item" onClick={props.logout}>
            <span className="sidedrawer__logout">
              <i className="fas fa-sign-out-alt" /> Logout
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default sideDrawer;
