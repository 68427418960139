import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions/index";

import Layout from "../../../../hoc/Layout";

import { CircularProgress } from "@material-ui/core";
import CenterTabs from "../../../../shared/tabs/centerTabs/centerTabs";
import IconSave from "@material-ui/icons/Save";
import { BuildingIcon } from "../../../../shared/icons";
import _ from "lodash";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";

import CenterModel from '../../../../../models/center';

const initialState = {
  accountingUsers: [],
  address: "",
  city: "",
  coachUsers: [],
  commission: "",
  country: "",
  email: "",
  iban: "",
  location: "",
  manager: "",
  name: "",
  phone: "",
  state: "",
  type: "",
  googleCalendarUrl: "",
  teamviewerId: ""
};



const Center = props => {
  const [center, setCenter] = useState(initialState);
  const [dialog, setDialog] = useState({
    open: false
  });
  useEffect(() => {
    props.onGetUsers();
  }, []);

  useEffect(() => {
    return function cleanup() {
      props.onResetCenter();
      setCenter(initialState);
    };
  }, []);

  useEffect(() => {
    if (props.match.params.id && !props.center)
      props.onGetCenter(props.match.params.id);
    if (props.center) {
      setCenter({ ...props.center });
    }
  }, [props.center]);

  const addAccountingUsers = userId => {
    let newCenter = Object.assign({}, center);
    newCenter.accountingUsers.push(userId);

    setCenter(newCenter);
  };

  const removeAccountingUsers = id => {
    let accountingUsers = center.accountingUsers.concat();
    accountingUsers.splice( accountingUsers.indexOf(id) , 1);
    setCenter({
      ...center,
      accountingUsers
    });
  };

  const addCoachUsers = user => {
    let coachUsers = center.coachUsers;
    coachUsers.push(user);
    setCenter({ ...center, coachUsers });
  };

  const removeCoachUsers = id => {
    let coachUsers = center.coachUsers.concat();
    coachUsers.splice(coachUsers.indexOf(id), 1);
    setCenter({ ...center, coachUsers });
  };

  const saveCenter = async center => {
    await props.onSaveCenter(center);
    setCenter({
      ...initialState,
      coachUsers: [],
      accountingUsers: []
    });
    props.history.push("/centri");
  };

  const canGoBack = values => {
    if (!_.isEqual(center, values)) openDialog();
    else props.history.push("/centri");
  };

  const openDialog = () => {
    setDialog({
      ...dialog,
      open: true
    });
  };

  const closeDialog = () => {
    setDialog({
      ...dialog,
      open: false
    });
  };

  return (
    <Layout>
      <div className="box-layout">
        <Formik
          initialValues={center}
          enableReinitialize
          onSubmit={values => {
            saveCenter(values);
          }}
          validationSchema={CenterModel.model().validationSchema}
          children={({ values, errors, touched, handleSubmit }) => (
            <>
              <div className="topSection">
                <h1 className="box-layout__title">
                  <BuildingIcon />
                  {props.match.params.id
                    ? `Modifica Centro ${props.center && props.center.name}`
                    : "Crea Centro"}
                </h1>
                <div className="topButtons">
                  <button
                    className="button button--outlined"
                    onClick={() => canGoBack(values)}
                  >
                    Annulla Creazione
                  </button>
                  <button
                    className="button button--confirm"
                    onClick={handleSubmit}
                  >
                    <IconSave />
                    Salva
                  </button>
                </div>
              </div>
              {!props.loading ? (
                <div className="box-layout__box">
                  <CenterTabs
                    users={props.users}
                    values={values}
                    errors={errors}
                    touched={touched}
                    addAccountingUsers={addAccountingUsers}
                    removeAccountingUsers={removeAccountingUsers}
                    addCoachUsers={addCoachUsers}
                    removeCoachUsers={removeCoachUsers}
                  />
                </div>
              ) : (
                <div className="box-layout box-layout__progress">
                  <CircularProgress
                    size={80}
                    thickness={4}
                    className="main-progress"
                  />
                </div>
              )}
            </>
          )}
        />
      </div>
      <Dialog
        open={dialog.open}
        
        keepMounted
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title-delete"
        aria-describedby="alert-dialog-slide-description-delete"
      >
        <DialogTitle
          className="dialogTitle"
          id="alert-dialog-slide-title-delete"
        >
          {"Sei sicuro di voler uscire?"}
        </DialogTitle>
        <DialogContent className="dialogContent">
          <DialogContentText id="alert-dialog-slide-description-delete">
            Attenzione! Stai lasciando il form con alcuni elementi non ancora
            salvati! Desideri davvero uscire?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="button button--cancel"
            onClick={() => props.history.push("/centri")}
            variant="contained"
          >
            Esci
          </Button>
          <Button
            className="button"
            onClick={closeDialog}
            variant="contained"
          >
            Rimani
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    users: state.user.users,
    center: state.center.center,
    loading: state.center.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetUsers: () => dispatch(actions.getUsers()),
    onGetCenter: id => dispatch(actions.getCenter(id)),
    onSaveCenter: center => dispatch(actions.saveCenter(center)),
    onResetCenter: () => dispatch(actions.resetCenter()),
    onResetCenterMessage: () => dispatch(actions.resetCenterMessage())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Center));
