import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as actions from "../../../../../store/actions/index";

import Layout from "../../../../hoc/Layout";
import UserForm from "../../../../shared/forms/user";
import Button from "@material-ui/core/Button";
import IconPerson from "@material-ui/icons/Person";

import CircularProgress from "@material-ui/core/CircularProgress";
import IconSave from "@material-ui/icons/Save";
import _ from "lodash";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";

import UserModel from '../../../../../models/user';

const initialState = UserModel.defaults;

const User = props => {
  const [user, setUser] = useState(initialState);
  const [dialog, setDialog] = useState({
    open: false
  });


  const userId = props.match.params.id;
  const isNew = !Boolean(props.match.params.id);
  const isSelf = userId === props.authUser.id;

  const saveUser = values => {
    props.onSaveUser(values);
    props.history.push("/utenti");
  };

  useEffect(() => {
    props.onGetRoles();

    return function cleanup() {
      props.onResetUser();
      setUser(initialState);
    };
  }, []);

  useEffect(() => {
    if (!isNew && !props.user) {
      props.onGetUser(userId);
    }
    if (props.user) {
      setUser({ ...props.user });
    }
  }, [props.user]);

  const canGoBack = valuesForm => {
    if (!_.isEqual(valuesForm, user)) openDialog();
    else props.history.push("/utenti");
  };

  const openDialog = () => {
    setDialog({
      ...dialog,
      open: true
    });
  };

  const closeDialog = () => {
    setDialog({
      ...dialog,
      open: false
    });
  };

  return (
    <Layout>
      <div className="box-layout">
        {!props.loading ? (
          <Formik
            initialValues={user}
            enableReinitialize
            onSubmit={values => {
              saveUser(values);
            }}
            validationSchema={UserModel.model().validationSchema}
            children={({ values, errors, touched, handleSubmit }) => (
              <div className="box-layout__table">
                <div className="topSection">
                  <h1 className="box-layout__title">
                    <IconPerson />
                    {isNew 
                      ? "Aggiungi Utente"
                      : `Modifica Utente ${props.user && props.user.name} ${props.user && props.user.surname}`
                      }
                  </h1>
                  <div className="topButtons">
                    <button
                      className="button button--outlined"
                      type="button"
                      onClick={() => canGoBack(values)}
                    >
                      Annulla {isNew ? "Creazione" : "Modifica"}
                    </button>
                    <button
                      className="button button--confirm"
                      type="submit"
                      onClick={handleSubmit}>
                      <IconSave />
                      {isNew ? "Invita" : "Salva"}
                    </button>
                  </div>
                </div>
                <UserForm
                  values={values}
                  roles={props.roles}
                  errors={errors}
                  touched={touched}
                  isNew={isNew}
                  isSelf={isSelf}
                />
                {isNew && !isSelf && (
                  <div className="user-notes">
                    <p><strong>NOTA:</strong> Una volta aggiunto l'utente, questo riceverà una email con l'invito e dovrà autenticarsi con il proprio account google, corrispondente alla email specificata.<br />
                  Solo in quel momento risulterà visibile fra gli utenti</p>
                  </div>
                )}
              </div>
            )}
          />
        ) : (
          <div className="box-layout box-layout__progress">
            <CircularProgress
              size={80}
              thickness={4}
              className="main-progress"
            />
          </div>
        )}
      </div>
      <Dialog
        open={dialog.open}
        
        keepMounted
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title-delete"
        aria-describedby="alert-dialog-slide-description-delete"
      >
        <DialogTitle
          className="dialogTitle"
          id="alert-dialog-slide-title-delete"
        >
          {"Sei sicuro di voler uscire?"}
        </DialogTitle>
        <DialogContent className="dialogContent">
          <DialogContentText id="alert-dialog-slide-description-delete">
            Attenzione! Stai lasciando il form con alcuni elementi non ancora
            salvati! Desideri davvero uscire?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
            onClick={() => props.history.push("/utenti")}
            variant="contained"
            className="button button--cancel"
          >
            Esci
          </Button>
          <Button
            onClick={closeDialog}
            variant="contained"
            className="button"
          >
            Rimani
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    loading: state.user.loading,
    roles: state.roles.roles,
    authUser: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetUser: id => dispatch(actions.getUser(id)),
    onGetRoles: () => dispatch(actions.getRoles()),
    onSaveUser: user => dispatch(actions.saveUser(user)),
    onResetUser: () => dispatch(actions.resetUser())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(User));
