import { updateObject } from "../../../shared/utility";
import * as actionTypes from "../../types/types";

const initialState = {
  users: [],
  user: null,
  loading: false,
  message: null,
  error: null
};

export const getUsersStart = (state, action) => {
  return updateObject(state, { loading: true });
};
export const getUsersSuccess = (state, action) => {
  return updateObject(state, { loading: false, users: action.payload.users });
};
export const getUsersFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};
export const getUserStart = (state, action) => {
  return updateObject(state, { loading: true });
};
export const getUserSuccess = (state, action) => {
  return updateObject(state, { loading: false, user: action.payload.user });
};
export const getUserFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore. Riprovare.",
    error: action.payload.error
  });
};
export const saveUserStart = (state, action) => {
  return updateObject(state, { loading: true, message: null });
};
export const saveUserSuccess = (state, action) => {
  let modify_users = { ...state }.users;
  let modiyId = modify_users.map(el => el.id).indexOf(action.payload.user.id);
  modify_users[modiyId] = action.payload.user;
  return updateObject(state, {
    loading: false,
    message: "Utente salvato con successo",
    users: modify_users,
    user: null
  });
};
export const saveUserFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore nel salvataggio. Riprovare.",
    error: action.payload.error
  });
};
export const deleteUserStart = (state, action) => {
  return updateObject(state, { loading: false, message: null });
};
export const deleteUserSuccess = (state, action) => {
  let remove_users = { ...state }.users;
  let removeId = remove_users.map(el => el.id).indexOf(action.payload.id);
  remove_users.splice(removeId, 1);
  return updateObject(state, {
    loading: false,
    message: "Utente eliminato con successo",
    users: remove_users
  });
};
export const deleteUserFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Errore nella cancellazione. Riprovare.",
    error: action.payload.error
  });
};
export const resetUser = (state, action) => {
  return updateObject(state, { user: null });
};
export const resetUserMessage = (state, action) => {
  return updateObject(state, { message: null });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_START:
      return getUsersStart(state, action);
    case actionTypes.GET_USERS_SUCCESS:
      return getUsersSuccess(state, action);
    case actionTypes.GET_USERS_FAIL:
      return getUsersFail(state, action);
    case actionTypes.GET_USER_START:
      return getUserStart(state, action);
    case actionTypes.GET_USER_SUCCESS:
      return getUserSuccess(state, action);
    case actionTypes.GET_USER_FAIL:
      return getUserFail(state, action);
    case actionTypes.SAVE_USER_START:
      return saveUserStart(state, action);
    case actionTypes.SAVE_USER_SUCCESS:
      return saveUserSuccess(state, action);
    case actionTypes.SAVE_USER_FAIL:
      return saveUserFail(state, action);
    case actionTypes.DELETE_USER_START:
      return deleteUserStart(state, action);
    case actionTypes.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state, action);
    case actionTypes.DELETE_USER_FAIL:
      return deleteUserFail(state, action);
    case actionTypes.RESET_USER:
      return resetUser(state, action);
    case actionTypes.RESET_USER_MESSAGE:
      return resetUserMessage(state, action);
    default:
      return state;
  }
};

export default reducer;
