
export class ValidateException {

   message = 'The document don\'t validate';

   constructor (errors) {
      this.errors = errors;
   }

   toString() {
      return this.message;
   };
}

export class PermissionException {

   message = 'You are not allowed to perform this action';

   constructor (action) {
      this.action = action;
   }   

   toString() {
      return this.message + `(${this.action})`;
   };
}

export class InvalidCollectionException{

   message = 'Invalid collection name';

   constructor (collection) {
      this.collection = collection;
   }
   
   toString() {
      return this.message;
   };
}

export class DocumentNotFoundException{

   constructor (id, collection) {
      this.id = id;
      this.collection = collection;
      this.message = 'Document not found';
   }

   toString() {
      return `${this.message} ${this.collection}/${this.id}`;
   };
}

export class MissingParentException{

   message = 'Parent document not specified';

   constructor (parent) {
      this.parent = parent;
   }

   toString() {
      return `${this.message} ${this.parent}`;
   };
}

