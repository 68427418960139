export const GET_CENTERS = "GET_CENTERS";
export const GET_CENTERS_START = "GET_CENTERS_START";
export const GET_CENTERS_SUCCESS = "GET_CENTERS_SUCCESS";
export const GET_CENTERS_FAIL = "GET_CENTERS_FAIL";

export const GET_CENTER = "GET_CENTER";
export const GET_CENTER_START = "GET_CENTER_START";
export const GET_CENTER_SUCCESS = "GET_CENTER_SUCCESS";
export const GET_CENTER_FAIL = "GET_CENTER_FAIL";

export const SAVE_CENTER = "SAVE_CENTER";
export const SAVE_CENTER_START = "SAVE_CENTER_START";
export const SAVE_CENTER_SUCCESS = "SAVE_CENTER_SUCCESS";
export const SAVE_CENTER_FAIL = "SAVE_CENTER_FAIL";

export const RESET_CENTER = "RESET_CENTER";
export const RESET_CENTER_MESSAGE = "RESET_CENTER_MESSAGE";

export const DELETE_CENTER = "DELETE_CENTER";
export const DELETE_CENTER_START = "DELETE_CENTER_START";
export const DELETE_CENTER_SUCCESS = "DELETE_CENTER_SUCCESS";
export const DELETE_CENTER_FAIL = "DELETE_CENTER_FAIL";
