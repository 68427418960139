import React from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { Tooltip, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import IconDelete from "@material-ui/icons/HighlightOff";
import { Button } from "@material-ui/core";
import { PlusIcon } from "../icons";
import Task from "../../../models/task";


const taskTypesMap = new Map(Task.TASK_TYPES);

const DragHandle = SortableHandle(() => <div className="task__handle">::</div>);

const SortableItem = SortableElement(({ value, index, removeTask }) => (
  <li className="task task--reference" key={index}>
    <DragHandle />
    <div className="task__name">{value.spec.name}</div>
    <div className="task__type">
      <span className="task__label">Tipo:</span>
      <span className="task__value">{taskTypesMap.get(value.spec.type)}</span>
    </div>
    <div className="task__calendar">
      <span className="task__label">Calendario:</span>
      <span className="task__value">
        {value.inCalendar ? `Si (${value.specg.duration})` : "No"}
      </span>
    </div>
    <div className="task__actions">
      <Tooltip className="task__remove" title="Elimina" enterDelay={100}>
        <IconButton
          className="iconButton button--cancel colorRed colorWhite"
          onClick={() => removeTask(value.id)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  </li>
));

const SortableGroupTasks = SortableContainer((props) => {
  
  const { 
    courseTasks, 
    courseGroups, 
    addTask,
    removeTask,
    removeGroup,
  } = props;
  
  return (
    <div className="tasks-sortable">
    {courseGroups.map( (courseGroup, groupIndex) => (
      <div className="course-template__group" key={groupIndex} >
        <h2>{courseGroup.name}</h2>
        <Button
        className="button button--delete-element"
        onClick={() => removeGroup(courseGroup.id)}
      >
        <IconDelete />Elimina
      </Button>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%"
        }} >
          <div className="tasks-sortable">
            <div className="tasks__heading task-references__heading">
              <div>Ordina</div>
              <div>Nome</div>
              <div>Tipo</div>
              <div>Calendario</div>
              <div>Azioni</div>
            </div>
            <ul className="tasks__list">
              {courseTasks
                .filter( (task) => task.group === courseGroup.id )
                .map(
                  (courseTask, taskIndex) => (
                    <SortableItem
                      key={taskIndex}
                      index={taskIndex}
                      collection={courseGroup.id}
                      value={courseTask}
                      removeTask={removeTask}
                    />
                  )
                )
              }
            </ul>
          </div>                    
        </div>
        <div className="actions-group actions-group--new">
          <Button className="button button--outlined" onClick={ () => addTask(courseGroup.id) }>
            Aggiungi Attività
            <PlusIcon />
          </Button>
        </div>
      </div>
    ))}
    </div>
  );
});


export default SortableGroupTasks;
