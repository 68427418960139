
import FirestoreModel from '../firestore';
import * as yup from 'yup';
import User from '../user';

class Center extends FirestoreModel {

    /**
     * The collection name in the database
     */
    get collectionName() {
        return 'center';
    };

    /**
     * The collection name in the database
     */
    get validationSchema() {
        return yup.object().shape({
            name: yup.string().required("Nome obbligatorio"),
            type: yup.string().required("Relazione obbligatoria"),
            manager: yup.string().required("Responsabile obbligatorio"),
            address: yup.string().required("Indirizzo obbligatorio"),
            city: yup.string().required("Città obbligatoria"),
            state: yup.string().required("Provincia obbligatoria"),
            country: yup.string().required("Nazione obbligatoria"),
            phone: yup.string().required("Telefono obbligatorio"),
            email: yup
                .string()
                .email("Email non valida")
                .required("Email obbligatoria"),
            commission: yup.string().label('Commissione'),                
            googleCalendarUrl: yup.string().url('URL non valida').label('URL Google Calendar'),                
            iban: yup.string().min('10').label('IBAN'),
            teamviewerId: yup.string().label('TeamViewer ID'),
            accountingUsers: yup.array().label('Amministratori'),
            coachUsers: yup.array().required().label('Coaches'),
        })
    };

    /**
     * Get a document from database
     * 
     * @param {object} id The properties to save
     * @return {Promise} The created document 
     */
    async get(id) {
        const center = await super.get(id);
                
        //Convert manager from ref to id @TO_FIX
        if ( center.manager ) {
            center.manager = center.manager.id;
        }

        // Retrieve accountingUsers and coachUsers from DB
        let fetchAccountingUsers = center.accountingUsers.map( 
            userRef => User.model().getByRef(userRef) 
        );
        let fetchCoachUsers = center.coachUsers.map( 
            userRef => User.model().getByRef(userRef) 
        );

        center.accountingUsers = await Promise.all(fetchAccountingUsers);
        center.coachUsers = await Promise.all(fetchCoachUsers);
  
        return center;
    }

}

Center.RELATIONSHIP_PROPRIETARY = 'proprietary';
Center.RELATIONSHIP_FRANCHISING = 'franchising';

Center.RELATIONSHIPS =  [
    [ Center.RELATIONSHIP_PROPRIETARY, 'Proprietario' ],
    [ Center.RELATIONSHIP_FRANCHISING, 'Franchising' ]
];

export default Center;
