import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions/index";
import Layout from "../../../../hoc/Layout";
import { CircularProgress } from "@material-ui/core";
import IconSave from "@material-ui/icons/Save";

import Typography from "@material-ui/core/Typography";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CustomerForm from "../../../../shared/forms/customer";
import RelativeForm from "../../../../shared/forms/relative";
import SelectAsync from "../../../../shared/select/async";

import {
  Grid,
  Button,
  FormControl
} from "@material-ui/core";

import IconGroupAdd from "@material-ui/icons/GroupAdd";
import IconDelete from "@material-ui/icons/HighlightOff";
import IconPerson from "@material-ui/icons/Person";

import _ from "lodash";

import { useDialog, unsavedDialog } from "../../../../shared/dialogs";
import CustomerModel from "../../../../../models/customer";
import CustomerRelative from "../../../../../models/customer/relative";

import errorHandler from "../../../../../shared/errorReporting";
import { lastId, debounce, userCan } from "../../../../../shared/utility";
import moment from "moment";
import CurrentUser from "../../../../../models/user/current";

const customerInitialState = CustomerModel.defaults;
const relativeInitialState = CustomerRelative.defaults;

// Init the tempId to 1 to match the contactPersonId default state below.
relativeInitialState.tempId = 1;

const searchHubspot = debounce((searchTerm) => {
  let properties = [ '', 'firstname', 'lastname', 'email'];
  let url = '/hubspot/search?q=' + encodeURIComponent(searchTerm) + '&sort=lastmodifieddate' +  properties.join('&property=');
  
  let getProp = (contact, prop) => contact.properties[prop] ? contact.properties[prop].value : '';
  
  return fetch(url)
    .then( response => response.json() )
    .then( results => {
      return results.contacts.map( contact => ({ 
        value: contact.vid, 
        label: `${getProp(contact, 'firstname')} ${getProp(contact, 'lastname')} - ${getProp(contact, 'email')}` }) 
      )
    });
}, 1000 );

const transformValues = ( values, transforms ) => {
  return transforms.reduce( (props, [source, target, transform ]) => { 
    if ( values[source] ){
      let value = values[source].value;
      value = transform ? transform( value ) : value;
      props[target] = value;
    }
    return props;
  }, {});
}

const getHubspotContactProps = (hubspotUser) => {

  let contactFields = [
    [ 'nome_figlio', 'name' ],
    [ 'certificazione', 'dsaCertificate' ],
    [ 'data_di_certificazione', 'dsaDate', data => moment( data, 'x')  ],
    [ 'data_di_nascita_del_figlio', 'birthDate', data => moment( data, 'x') ],
    [ 'gender_figlio', 'gender', data => data.toLowerCase() ],
    [ 'ordinamento_scolastico', 'schoolClass', data =>  data.replace(' ', '-') ],
    [ 'note', 'notes' ],
    [ 'difficolt_', 'disorders', data => data.split(';') ],
    [ 'specialista', 'pro', data => data.split(';').pop() ],
  ];

  let relativesFields = [
    [ 'firstname', 'name' ],
    [ 'lastname', 'surname' ],
    [ 'email', 'email' ],
    [ 'phone', 'phone' ],    
    [ 'mobilephone', 'phone' ],
    [ 'city', 'city' ],
    [ 'province', 'province' ],
    [ 'tipologia', 'type' ],

  ];

  let hubspotFields = [''].concat(contactFields.map( ([hsf]) => hsf), relativesFields.map( ([hsf]) => hsf ));

  let url = '/hubspot/contact?vid=' + encodeURIComponent(hubspotUser.value) + hubspotFields.join('&property=');

  return fetch(url)
    .then( response => response.json() )
    .then( contact => (
      [ transformValues(contact.properties, contactFields), transformValues(contact.properties, relativesFields)  ]
    ));
};

const CustomerEdit = props => {
  const [customer, setCustomer] = useState(customerInitialState);
  const [relatives, setRelatives] = useState([Object.assign({}, relativeInitialState)]);
  const [contactPersonId, setContactPersonId] = useState(1);
  const [hubspotUser, setHubspotUser] = useState();

  const customerId = props.match.params.id;

  if (customer.contactPerson && customer.contactPerson.id) {
    customer.contactPerson = customer.contactPerson.id;
  }

  if (customer.center && customer.center.id) {
    customer.center = customer.center.id;
  }

  const contactPerson = relatives.find(rel => (rel.id === contactPersonId) || (rel.tempId === contactPersonId));
  const contactPersonValid = CustomerRelative.model({ customerId: customerId || '__draft__', context: 'primary' }).validateSync(contactPerson);

  useEffect(() => {
    if (!customerId) {
      
      if ( !userCan('customer__create') && userCan('customer__create--own-center') ) {
        CurrentUser.model().centers().then( centers => {
          setCustomer( Object.assign( {}, customer, {center: centers.pop()} ) )
        });
      }

      return 
    }

    CustomerModel.model().get(customerId).then(setCustomer).catch(errorHandler.report);
    CustomerRelative.model({ customerId }).list().then(setRelatives).catch(errorHandler.report)

  }, [customerId]);

  useEffect(() => {
    if (customer.contactPerson) {
      setContactPersonId(customer.contactPerson);
    }
  }, [customer.contactPerson]);

  const addRelative = () => {
    const tempId = lastId(relatives, 'tempId') + 1;
    const updatedRelatives = relatives.concat({ ...relativeInitialState, tempId });
    setRelatives(updatedRelatives);
  };

  const saveRelative = async (relative, index) => {
    let updatedRelatives = relatives.concat();
    Object.assign(updatedRelatives[index], relative);
    setRelatives(updatedRelatives);
  };

  const removeRelative = async (index) => {
    let updatedRelatives = relatives.concat();
    delete updatedRelatives[index];
    setRelatives(updatedRelatives);
  };

  const updateCustomer = async (values = {}) => {

    let updatedCustomer = Object.assign(values, { relatives, contactPerson: contactPersonId });

    await props.onSaveCustomer(updatedCustomer);
    setCustomer(customerInitialState);
    props.history.push("/clienti");
  };

  const canGoBack = values => {
    if (!_.isEqual(customer, values))
      openUnsavedDialog();
    else
      goBack();
  };

  const goBack = () => {
    props.history.push("/clienti");
  }

  let submitForm;

  const handleGlobalSubmit = (e) => {
    if (submitForm) {
      submitForm(e);
    }
  }

  const bindSubmit = (formSubmit) => {
    submitForm = formSubmit;
  }

  const importHubspotContactData = () => {
    getHubspotContactProps(hubspotUser).then( 
      ([contactProps, relativeProps ]) => {
        setCustomer( Object.assign( {}, customer, contactProps) );
        let newRelatives = relatives.concat();
        newRelatives[0] = Object.assign( {}, newRelatives[0], relativeProps )
        setRelatives( newRelatives );
      }
    );
  }

  const onSetContactPerson = (relative) => {
    setContactPersonId(relative.id || relative.tempId);
  }

  const [UnsavedDialog, openUnsavedDialog] = useDialog(unsavedDialog, {
    id: null
  });

  // Still Loading
  if (props.loading) {
    return (
      <div className="box-layout box-layout__progress">
        <CircularProgress
          size={80}
          thickness={4}
          className="main-progress"
        />
      </div>
    )
  }

  if (!Array.isArray(customer.disorders)) {
    customer.disorders = Object.keys(customer.disorders).filter(dis => customer.disorders[dis]);
  }

  return (
    <Layout>
      <div className="box-layout">
        <div className="box-layout__table">
          <div className="topSection">
            <h1 className="box-layout__title">
              <IconPerson />
              {props.match.params.id ? `Modifica Corsista - ${customer.name} ${customer.surname}` : "Aggiungi Nuovo Corsista"}
            </h1>
            <div className="topButtons">
              <Button
                className="button button--outlined"
                onClick={canGoBack}
              >
                Annulla {props.match.params.id ? "Modifica" : "Creazione"}
              </Button>
              <Button
                className="button button--confirm"
                onClick={handleGlobalSubmit}
                disabled={!contactPersonValid}
              >
                <IconSave />
                Salva
                </Button>
            </div>
          </div>
          <div className="box-layout__box">          
            {!customerId && (
            <FormControl component="fieldset" fullWidth className="fields-group">
              <Typography component="legend" className="form-maxWidth">Importa da Hubspot</Typography>
                <Grid container spacing={3} className="form-maxWidth">
                  <Grid item xs={12} sm={12} md={12}>
                    <SelectAsync
                      label="Cerca il genitore"
                      name="hubspotSearch"
                      id="hubspotSearch"
                      value={ hubspotUser }
                      onChange={e => {
                        setHubspotUser(e.target);
                      }}
                      placeholder="Scrivi il nome del genitore per cercare.."
                      loadOptions={searchHubspot}
                      cacheOptions
                    />
                    { hubspotUser && (<Button style={{marginTop: "15px", float: "right"}} onClick={ importHubspotContactData } >Importa</Button>)}
                  </Grid>
                </Grid>                
            </FormControl>
            )}           
            <CustomerForm
              initialValues={customer}
              onSubmit={updateCustomer}
              bindSubmit={bindSubmit}
              relatives={relatives}
            />
            <div className="relatives-manager" >
              <Typography component="h2" >Contatti/Familiari</Typography>
              {!contactPersonValid && (<p className="error-message"><strong>ATTENZIONE:</strong> È necessario specificare un contatto principale valido a cui inviare comunicazioni</p>)}
              {relatives.map((relative, relativeIndex) => {

                // If the current relative is the contactPerson change the context to primary
                // This is done to enforce some required
                let isPrimary = contactPersonId === (relative.id || relative.tempId);
                const customerRelative = new CustomerRelative({ customerId: customerId || '__draft__', context: isPrimary ? 'primary' : 'secondary' });

                return (
                  <ExpansionPanel className="single-relative" key={relativeIndex} defaultExpanded={Boolean(relative.tempId)}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{relative.name ? `${relative.name} ${relative.surname} (${relative.type})` : 'Nuovo (Non Salvato)'}{isPrimary && ' - Contatto Principale'}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <FormControl
                        component="fieldset"
                        className="fields-group fields-group--relative"
                      >
                        <RelativeForm
                          initialValues={customerRelative.trim(relative)}
                          validationSchema={customerRelative.validationSchema}
                          onSubmit={(values) => saveRelative(values, relativeIndex)}
                          isPrimary={isPrimary}
                          onSetPrimary={() => onSetContactPerson(relative)}
                        />
                        <Button
                          className="button button--delete-element"
                          onClick={() => removeRelative(relativeIndex)}
                          disabled={isPrimary}
                        >
                          <IconDelete />Elimina
                        </Button>
                      </FormControl>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                );
              })}
              <Grid container className="new-relative-container">
                <Grid item xs={12} sm={12} md={6}>
                  <Typography component="p">Specifica ulteriori parenti o persone collegate al corsista</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Button className="button button--new" onClick={addRelative}>
                    <IconGroupAdd />Aggiungi Contatto/Familiare
                        </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <UnsavedDialog onConfirm={goBack} />
    </Layout>
  );
};
const mapStateToProps = state => {
  return {
    customer: state.customer.customer,
    loading: state.customer.loading,
    loginUser: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetCustomer: id => dispatch(actions.getCustomer(id)),
    onSaveCustomer: customer => dispatch(actions.saveCustomer(customer)),
    onResetCustomer: () => dispatch(actions.resetCustomer())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomerEdit));
