import { put } from "redux-saga/effects";
import * as actions from "../../actions/index";
import CourseTemplate from "../../../models/course/template";
import CourseTemplateTask from "../../../models/course/template/task";
import Task from "../../../models/task";
import errorHandler from "../../../shared/errorReporting";

export function* getCourseTemplates() {
  yield put(actions.getCourseTemplatesStart());

  try {
    // Get all templates
    const courseTemplates = yield CourseTemplate.model().list();

    yield put(actions.getCourseTemplatesSuccess(courseTemplates));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.getCourseTemplatesFail(error));
  }
}

export function* getCourseTemplate(action) {
  yield put(actions.getCourseTemplateStart());

  const courseTemplateId = action.payload.id;

  try {
    // Get template data
    let courseTemplate = yield CourseTemplate.model().get(courseTemplateId);

    // Get template task's objects
    courseTemplate.tasks = yield CourseTemplateTask.model({
      courseTemplateId
    }).list({ orderBy: ["position"] });

    yield put(actions.getCourseTemplateSuccess(courseTemplate));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.getCourseTemplateFail(error));
  }
}

export function* saveCourseTemplate(action) {
  yield put(actions.saveCourseTemplateStart());

  let courseTemplateId = action.payload.course_template.id;
  let courseTasks = action.payload.course_template.tasks;

  try {
    if (courseTemplateId) {
      // Update courseTemplate
      yield CourseTemplate.model().update(
        courseTemplateId,
        action.payload.course_template
      );
    } else {
      // Create courseTemplate
      courseTemplateId = yield CourseTemplate.model().create(
        action.payload.course_template
      );
    }

    // Convert task objects to db format. If task is new: unset the `id` so the model creates a new one
    courseTasks = courseTasks.map((courseTask, index) => ({
      id: courseTask.isNew ? undefined : courseTask.id,
      group: courseTask.group,
      // Convert task id to task ref
      task:
        courseTask.spec && courseTask.spec.id
          ? Task.model().ref(courseTask.spec.id)
          : undefined,
      position: index
    }));

    // Rewrite all tasks to db. @TODO update only changed tasks
    yield CourseTemplateTask.model({ courseTemplateId }).writeBatch(
      courseTasks
    );

    yield put(
      actions.saveCourseTemplateSuccess(action.payload.course_template)
    );
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.saveCourseTemplateFail(error));
  }
}

export function* deleteCourseTemplate(action) {
  yield put(actions.deleteCourseTemplateStart());
  try {
    yield CourseTemplate.model().delete(action.payload.id);
    yield put(actions.deleteCourseTemplateSuccess(action.payload.id));
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.deleteCourseTemplateFail(error));
  }
}

export function* cloneCourse(action) {
  yield put(actions.cloneCourseStart());
  try {
    const srcId = action.payload.id;

    // Get the source task and write a new one (edit the name)
    const courseModel = CourseTemplate.model();
    const courseTemplate = yield courseModel.get(srcId);
    courseTemplate.name += " copy";
    courseTemplate.id = yield courseModel.create(courseTemplate);

    // Clone the course tasks
    yield CourseTemplateTask.model({ courseTemplateId: srcId })
      // Read from the source course
      .list({ orderBy: ["position"] })
      .then(
        // Write in the destination course
        tasks =>
          CourseTemplateTask.model({
            courseTemplateId: courseTemplate.id
          }).writeBatch(tasks)
      );

    yield put(
      actions.cloneCourseSuccess({
        ...courseTemplate,
        newCourseId: courseTemplate.id
      })
    );
  } catch (error) {
    errorHandler.report(error);
    yield put(actions.cloneCourseFail(error));
  }
}
