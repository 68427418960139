import React, { useState } from "react";

import { Tooltip, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Select, { makeOptions } from "../../select";

export function UsersManage(props) {

  return (
    <div className="react-table">
      <ReactTable
        data={props.users}
        columns={[
          {
            Header: "Nome",
            id: "name",
            maxWidth: 250,
            accessor: 'name',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["name"] }),
            filterAll: true,

          },
          {
            Header: "Cognome",
            id: "surname",
            maxWidth: 250,
            accessor: 'surname',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["surname"] }),
            filterAll: true,

          },
          {
            Header: "Azioni",
            filterable: false,
            accessor: "actions",
            Cell: row => (
              <div className="actionTableContainer">
                <Tooltip title="Elimina" enterDelay={300}>
                  <IconButton
                    className="iconButton colorRed colorWhite"
                    onClick={() => props.onRemove(row.original.id)}
                    size="small"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            )
          }
        ]}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        defaultPageSize={5}
        className="-striped -highlight"
        previousText="Precedente"
        nextText="Successivo"
        pageText="Pagina"
        ofText="di"
        rowsText="righe"
        noDataText="Nessun Amministratore Trovato"
      />
    </div>
  );
};

const UserLinker = props => {

  const [newUserId, setUNewUserId] = useState();

  const handleUser = u => {
    setUNewUserId(u.target.value);
  };

  const resetUser = () => {
    setUNewUserId("");
  };
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={7}>
        <UsersManage
          users={ props.availableUsers.filter( user => props.currentUsers.includes( user.id ) )}
          onRemove={props.onRemove}
        />
      </Grid>
      <Grid item xs={12} md={1}></Grid>
      <Grid item xs={12} md={4}>
        <div className="tab-form">
          { props.actionTitle && (<Typography component="h3" variant="subtitle1">{props.actionTitle}</Typography>)}
          <Select
            value={newUserId}
            onChange={handleUser}
            name="user"
            id="user"
            label="Seleziona Utente"
            options={ makeOptions(props.availableUsers, '{name} {surname}', 'id') }
          />
          <div className="buttonsActivityContainer">
            <Grid container spacing={3} className="tab-form__submit">
              <Grid item>
                <Button
                  variant="contained"
                  component="span"
                  onClick={resetUser}
                  className="button button--outlined"
                >
                  Resetta
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  component="span"
                  onClick={() => props.onAdd(newUserId)}
                  className="button"
                >
                  Aggiungi
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default UserLinker;
